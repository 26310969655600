/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: "HelveticaCompressed";
    src: url("../src/assets/fonts/Helvetica-Font/helvetica-compressed.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaBold";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-Bold.ttf") format("opentype");
    font-weight: bolder;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaRounded";
    src: url("../src/assets/fonts/Helvetica-Font/helvetica-rounded-bold.otf") format("opentype");
    font-weight: bolder;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaLite";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-Light.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica 45 Light";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-45-Light.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica 55 Roman";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-55-Roman.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica 65 Medium";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-65-Medium.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaOblique";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-Oblique.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "HelveticaBoldOblique";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica-BoldOblique.ttf");
    font-weight: bolder;
    font-style: normal;
  }
  @font-face {
    font-family: "Helvetica";
    src: url("../src/assets/fonts/Helvetica-Font/Helvetica.ttf");
    font-weight: normal;
    font-style: normal;
  }

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/default.theme.css";