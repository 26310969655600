//fonts

$font_0: Lato;
$font_1: sans-serif;
$font-themify: themify;
$font-Helvetica: "Helvetica","HelvelticaRounded", "Helvetica Neue", Arial, sans-serif;
$font-awesome: FontAwesome;
$assetspath: "/assets/images";

//colors
$white: #ffffff;
$white-dark: #eeeeee;
$black: #000000;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color:#333333;
$grey: #777777;
$grey-light: #a1a1a1;
$border:#dddada;
$round-border: #dddddd;
$grey-lighter: #f9f9f9;
$theme-deafult: #ff4c3b;
$grey-darken: #393230;
$grey-link: #948e8c;
$grey-font: #938d8c;
$dark-footer: #2e2726;
$form-bg: #f5f2f2;
$grey-shade: #7f786d;
$darker-footer: #25221e;
$border-color: #38352f;
$border-grey: #f1f5f4;
$font-grey: #aaaaaa;
$star-yellow: #ffa200;
$border-bottom-g:#525252;
$top-header: #f8f8f8;
$header-font: #999999;
$shadow: #ededed;
$box-border: rgba(236, 236, 236, 0.6588235294117647);
$grey-about: #f7f7f7;
$grey2:#555555;
$grey3: #efefef;
$grey4: #444444;
$grey5: #888888;
$grey6:#6f6f6f;
$sidebar-border: #f5f2f2;
$sidebar-color:#938d8c;
$modal: #ffba00;
$bg-color: #d0edff;
$bg-color1: #f1e7e6;
$bg-color2: #bfbfbf;
$light-grey: #d0edff;
$light-grey: #d0edff;
$pink: #ffc0cb;
$red: #ff647f;
$green: #6fb866;
$yellow: #f2f896;
$skyblue: #7ee7ec;
$blue: #63b4f2;
$orange: #ffb17a;
$purple: #eeb1d7;
$violet: #a06da0;
$brown: #a57372;
$lightgreen: #90ee90;
$olive: #808000;
$navy: #505c80;
$maroon: #804f56;
$icon: #6f6f6f;
$grey-about: #f7f7f7;
$grey01:#111111;
$grey2:#555555;
$grey3: #efefef;
$grey4: #444444;
$grey5: #888888;
$grey6:#6f6f6f;
$grey7:#40494f;
$grey8:#c0c0c0;
$left-sidebar: #2b2b2b;
$dark-body:#2b2b2b;
$dark-top:#1f1f1f;
$color-red:#ff4c3b;
$gradient1: #01effc;
$gradient2: #485ff2;

// dark layput variable //
$dark-body:#2b2b2b;
$dark-top:#232323;
$dark-border:#404040;
$dark-link:#cbcbcb;
$dark-span:#929292;
$dark-footer-bg:#383838;

$white-1:#f1f3f5;
$white-2:#eaedef;
$white-3:#e7eaec;
$white-4:#dee2e6;
$white-5:#cfd4da;

    $wpPresetColorBlack: #000000;
    $wpPresetColorCyan-bluish-gray: #abb8c3;
    $wpPresetColorwhite: #ffffff;
    $wpPresetColorPale-pink: #f78da7;
    $wpPresetColorVivid-red: #cf2e2e;
    $wpPresetColorLuminous-vivid-orange: #ff6900;
    $wpPresetColorLuminous-vivid-amber: #fcb900;
    $wpPresetColorLight-green-cyan: #7bdcb5;
    $wpPresetColorVivid-green-cyan: #00d084;
    $wpPresetColorPale-cyan-blue: #8ed1fc;
    $wpPresetColorVivid-cyan-blue: #0693e3;
    $wpPresetColorVivid-purple: #9b51e0;
    $wpPresetGradientVivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    $wpPresetGradientLight-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    $wpPresetGradientLuminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    $wpPresetGradientLuminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    $wpPresetGradientVery-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    $wpPresetGradientCool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    $wpPresetGradientBlush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    $wpPresetGradientBlush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    $wpPresetGradientLuminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    $wpPresetGradientPale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    $wpPresetGradientElectric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    $wpPresetGradientMidnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    $wpPresetDuotoneDark-grayscale: url('#wp-duotone-dark-grayscale');
    $wpPresetDuotoneGrayscale: url('#wp-duotone-grayscale');
    $wpPresetDuotonePurple-yellow: url('#wp-duotone-purple-yellow');
    $wpPresetDuotoneBlue-red: url('#wp-duotone-blue-red');
    $wpPresetDuotoneMidnight: url('#wp-duotone-midnight');
    $wpPresetDuotoneMagenta-yellow: url('#wp-duotone-magenta-yellow');
    $wpPresetDuotonePurple-green: url('#wp-duotone-purple-green');
    $wpPresetDuotoneBlue-orange: url('#wp-duotone-blue-orange');
    $wpPresetFont-sizeSmall: 13px;
    $wpPresetFont-sizeMedium: 20px;
    $wpPresetFont-sizeLarge: 36px;
    $wpPresetFont-sizeX-large: 42px;
    $wpPresetSpacing--20: 0.44rem;
    $wpPresetSpacing--30: 0.67rem;
    $wpPresetSpacing--40: 1rem;
    $wpPresetSpacing--50: 1.5rem;
    $wpPresetSpacing--60: 2.25rem;
    $wpPresetSpacing--70: 3.38rem;
    $wpPresetSpacing--80: 5.06rem;
    $wpPresetShadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    $wpPresetShadowDeep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    $wpPresetShadowSharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    $wpPresetShadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    $wpPresetShadowCrisp: 6px 6px 0px rgba(0, 0, 0, 1);

    $wpStyleBlock-gap: 10px;
    $wpPresetColorBackground : #666;
    $wpCustom--typography--text-stroke-width :2px;
    $wpPresetColorPrimary:  #333;
    $wpPresetColorSecondary: #444;
    $wpPresetColorForeground: #000;
    $wpPresetFont-sizeExt-small: 6px;
    $wpPresetFont-sizeExt-medium : 10px;
    $wpPresetFont-sizeExt-large: 32px;
    $wpPresetFont-sizeExt-x-large: 36px;
    $wpPresetFont-sizeExt-xx-large: 38px;
    $wpCustomSpacingLarge: 100vw;