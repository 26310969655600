@media only screen and (max-width: 767px) {
    header .logo-wrapper {
        padding: 0
    }

    .mobile-remove-br br {
        display: none
    }

    .mobile-remove-bg,
    .mobile-remove-bg>.elementor-column-wrap {
        background-image: none !important
    }

    .elementor-column.mobile-order-top {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .search-table .search-button input[type^="submit"],
    .woocommerce-product-search button[type="submit"] {
        min-width: 0
    }

    .ts-mask {
        -webkit-clip-path: ellipse(200% 100% at 50% 0);
        clip-path: ellipse(200% 100% at 50% 0)
    }

    .header-v3 header .logo-wrapper {
        padding: 0
    }

    .mobile-app-style #ts-filter-widget-area .ts-sidebar-content,
    .mobile-app-style .style-filter-top .before-loop-wrapper #ts-filter-widget-area .ts-sidebar-content {
        padding-bottom: 70px
    }

    article.single-post .entry-format .thumbnail img {
        margin-bottom: 22px
    }

    article.single-post.no-featured-image .entry-meta-top {
        margin-top: 13px
    }

    .woocommerce .woocommerce-ordering .orderby ul,
    .product-per-page-form ul.perpage ul {
        width: 140px
    }

    #ts-filter-widget-area .before-loop-wrapper>.widget.product-filter-by-brand {
        position: relative;
        z-index: 1000
    }

    body.mobile-app-style:not(.ts_desktop):not(.page-template-blank-page-template) {
        padding-bottom: 46px
    }

    .elementor-section.elementor-section-stretched {
        width: 100vw !important;
        left: calc(-50vw + 50%) !important
    }

    .visible-phone {
        display: block !important
    }

    .hidden-phone,
    .sticky-wrapper.is-sticky .icon-menu-sticky-header.hidden-phone {
        display: none !important
    }

    .mobile-ts-aligncenter {
        text-align: center !important
    }

    .ts-header .container,
    footer .container,
    .layout-fullwidth .footer-container .container,
    .breadcrumb-title-wrapper .breadcrumb-content {
        padding-left: 15px;
        padding-right: 15px
    }

    .single-post>.single-related-wrapper:last-child:after {
        left: 15px;
        right: 15px
    }

    .footer-container .ts-list-of-product-categories-wrapper.columns-2 .list-categories ul,
    .footer-container .ts-list-of-product-categories-wrapper.columns-3 .list-categories ul,
    .footer-container .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0
    }

    #main>.page-container {
        padding-left: 15px;
        padding-right: 15px
    }

    #left-sidebar,
    #right-sidebar {
        margin-bottom: 50px
    }

    #main-content,
    #left-sidebar,
    #right-sidebar {
        width: 100%;
        padding-left: 0;
        padding-right: 0
    }

    #left-sidebar.ts-col-6 {
        padding-right: 0
    }

    #right-sidebar.ts-col-6 {
        padding-left: 0
    }

    #left-sidebar .widget-container .widget-title-wrapper .widget-title:not(:empty),
    #left-sidebar .widget-title-wrapper .widget-title:not(:empty),
    #right-sidebar .widget-container .widget-title-wrapper .widget-title:not(:empty),
    #right-sidebar .widget-title-wrapper .widget-title:not(:empty) {
        margin: 0
    }

    #left-sidebar .widget-container.mailchimp-subscription .widget-title-wrapper .widget-title:not(:empty),
    #right-sidebar .widget-container.mailchimp-subscription .widget-title-wrapper .widget-title:not(:empty),
    #left-sidebar .widget-container .widget-title-wrapper .block-control.active~.widget-title:not(:empty),
    #left-sidebar .widget-title-wrapper .block-control.active~.widget-title:not(:empty),
    #right-sidebar .widget-container .widget-title-wrapper .block-control.active~.widget-title:not(:empty),
    #right-sidebar .widget-title-wrapper .block-control.active~.widget-title:not(:empty) {
        margin-bottom: 10px
    }

    body.page article.page+.comments-area {
        min-height: 240px
    }

    body.page article.page .comments-area .commentlist {
        margin-bottom: 100px
    }

    body .elementor-section.elementor-section-boxed.elementor-section-stretched>.elementor-container {
        max-width: calc(100% - 30px)
    }

    body .mobile-menu-wrapper .ts-megamenu .elementor-section,
    body .mobile-menu-wrapper .ts-megamenu .elementor-section.elementor-section-stretched {
        width: 100% !important;
        max-width: calc(100vw - 40px) !important;
        left: 0 !important
    }

    body .mobile-menu-wrapper .ts-megamenu .elementor-section.elementor-section-boxed>.elementor-container,
    body .mobile-menu-wrapper .ts-megamenu .elementor-section.elementor-section-boxed.elementor-section-stretched>.elementor-container {
        max-width: 100%;
        margin: 0
    }

    .widget-title-wrapper a.block-control {
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: right;
        z-index: 2
    }

    #right-sidebar {
        margin-top: 64px;
        margin-bottom: 0
    }

    .single-post #right-sidebar {
        margin-top: 45px
    }

    #left-sidebar .widget-container,
    #right-sidebar .widget-container {
        border-width: 0;
        padding: 15px;
        margin-top: 8px;
        margin-bottom: 20px;
        border-width: 1px;
        border-style: solid
    }

    #left-sidebar .widget-container.widget_media_image,
    #right-sidebar .widget-container.widget_media_image {
        padding: 0;
        border-width: 0
    }

    #left-sidebar .widget-container .widget-title-wrapper .widget-title:empty,
    #left-sidebar .widget-title-wrapper .widget-title:empty,
    #right-sidebar .widget-container .widget-title-wrapper .widget-title:empty,
    #right-sidebar .widget-title-wrapper .widget-title:empty {
        min-height: 18px
    }

    #left-sidebar .widget-container.mailchimp-subscription .block-control,
    #right-sidebar .widget-container.mailchimp-subscription .block-control {
        display: none !important
    }

    #left-sidebar .widget-container.mailchimp-subscription .subscribe-widget,
    #right-sidebar .widget-container.mailchimp-subscription .subscribe-widget {
        display: block !important
    }

    #left-sidebar .product-filter-by-color ul,
    #right-sidebar .product-filter-by-color ul,
    #left-sidebar .product-filter-by-brand .product-filter-by-brand-wrapper>ul,
    #right-sidebar .product-filter-by-brand .product-filter-by-brand-wrapper>ul {
        margin-bottom: 5px
    }

    #left-sidebar .widget_text .textwidget>p,
    #right-sidebar .widget_text .textwidget>p {
        margin-bottom: 10px
    }

    #left-sidebar .woocommerce ul.product_list_widget,
    #right-sidebar .woocommerce ul.product_list_widget {
        padding-bottom: 5px
    }

    #left-sidebar .product-filter-by-brand>.product-filter-by-brand-wrapper,
    #right-sidebar .product-filter-by-brand>.product-filter-by-brand-wrapper,
    #left-sidebar .widget_rating_filter>ul,
    #right-sidebar .widget_rating_filter>ul,
    #left-sidebar .product-filter-by-color>ul,
    #right-sidebar .product-filter-by-color>ul {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .footer-container .ts-mailchimp-subscription-shortcode .widget-title-wrapper .widget-title:not(:empty) {
        margin-bottom: 20px
    }

    .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
        margin: 5px 1px 0
    }

    #right-sidebar .widget_text .textwidget>p[style="margin-bottom: 30px;"] {
        margin-bottom: 10px !important
    }

    #left-sidebar .widget_text .textwidget>p:first-child>img:first-child,
    #right-sidebar .widget_text .textwidget>p:first-child>img:first-child {
        margin-top: 5px
    }

    #left-sidebar .widget_text .textwidget>*:last-child,
    #right-sidebar .widget_text .textwidget>*:last-child {
        margin-bottom: 0
    }

    #left-sidebar .mc4wp-response,
    #right-sidebar .mc4wp-response {
        margin-top: 5px
    }

    .woocommerce .widget_price_filter form {
        padding-top: 11px
    }

    .woocommerce .widget_price_filter .price_slider_amount .price_label {
        margin-bottom: 10px
    }

    ul.blog-filter-bar {
        margin-bottom: 15px
    }

    .ts-product.ts-slider.partial-view.partial-left .owl-nav,
    .ts-product.ts-slider.partial-view.partial-right .owl-nav {
        left: 5px
    }

    .ts-product.ts-slider.partial-view.partial-left .owl-nav {
        right: 5px
    }

    .ts-portfolio-wrapper .filter-bar li,
    ul.blog-filter-bar li {
        padding: 0;
        margin-bottom: 5px
    }

    body .select2-container--default .select2-selection--single .select2-selection__arrow {
        margin-top: -14px
    }

    .mobile-order-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .mobile-2-columns ul {
        margin-left: -5px;
        margin-right: -5px
    }

    .mobile-2-columns ul li {
        width: 50%;
        float: left;
        padding-left: 5px;
        padding-right: 5px
    }

    .footer-container .ts-language-switcher ul.wpml-ls-sub-menu li,
    .footer-container .ts-currency-switcher ul li {
        line-height: 20px;
        padding: 4px 0
    }

    .admin-bar .elementor-section.elementor-section-height-full {
        height: calc(100vh - 46px)
    }

    .single #right-sidebar {
        margin-bottom: 50px
    }

    body .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
        width: 100%;
        max-width: 100%
    }

    body .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
        display: none
    }

    body .elementor-widget-image-box.ts-feature .elementor-image-box-wrapper,
    .footer-container .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
        text-align: left;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    body .elementor-widget-image-box.ts-feature .elementor-image-box-wrapper,
    .footer-container .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    body .elementor-element.ts-feature.elementor-position-left .elementor-image-box-img {
        margin-right: 14px !important
    }

    .logo-wrapper .normal-logo {
        display: none
    }

    .logo-wrapper .mobile-logo {
        display: block
    }

    .menu-center .logo-wrapper {
        width: auto
    }

    .shopping-cart-wrapper .dropdown-container,
    .my-account-wrapper .dropdown-container {
        display: none !important
    }

    .header-middle>.container {
        padding-top: 12px;
        padding-bottom: 12px
    }

    div.header-right>*:not(:last-child) {
        margin-right: 0
    }

    html .header-v2 #page .vertical-categories-wrapper {
        padding-bottom: 50px
    }

    .header-v4 .header-middle .header-left>.ts-mobile-icon-toggle {
        padding: 8px 13px;
        border-width: 0
    }

    .header-v4 .header-middle .shopping-cart-wrapper {
        padding: 0px 5px 0px 0
    }

    .header-v4 .ts-header .header-middle div.header-right {
        padding-left: 0px
    }

    .header-v4 .header-middle .search-button.search-icon {
        min-width: 0;
        background: transparent !important
    }

    .header-v4 .header-middle .header-right .search-button.search-icon {
        border-width: 0
    }

    .header-v4 .header-middle .logo-wrapper {
        padding: 0 20px 0 0;
        text-align: left
    }

    .ts-header div.header-right .search-button.search-icon .icon {
        padding: 10px 0 10px 10px
    }

    .ts-header div.header-right .search-button.search-icon .icon:before {
        font-size: 18px
    }

    #group-icon-header .header-social-icon {
        text-align: center
    }

    .vertical-categories-wrapper {
        display: none
    }

    .header-v2 .ts-header .header-middle .header-right {
        padding-left: 0;
        margin-left: auto
    }

    .header-v2 .header-middle .header-right>.search-button.search-icon {
        padding-left: 15px
    }

    .vertical-categories-wrapper~* {
        transform: translateX(0);
        max-width: 100%
    }

    body:not(.mobile-app-style) .ts-header .my-wishlist-wrapper,.my-notificarion-wrapper,
    body:not(.mobile-app-style) .ts-header .shopping-cart-wrapper {
        display: block !important
    }

    body:not(.mobile-app-style) .ts-header .ts-mobile-icon-toggle {
        padding: 13px 0 13px 13px
    }

    body:not(.mobile-app-style):not(.header-v2) .ts-header .ts-mobile-icon-toggle {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    body:not(.mobile-app-style) .ts-header .ts-mobile-icon-toggle .icon {
        width: 20px;
        height: 20px
    }

    body:not(.mobile-app-style) .ts-header .ts-mobile-icon-toggle .icon:before {
        font-size: 18px;
        line-height: 22px
    }

    body:not(.mobile-app-style) .ts-header .search-button.search-icon {
        display: none !important
    }

    .header-v2:not(.mobile-app-style) header .logo-wrapper {
        padding: 0 10px 0 0
    }

    .header-v2:not(.mobile-app-style) header .logo-wrapper {
        padding: 0 10px 0 0
    }

    .header-v2:not(.mobile-app-style) .ts-header .header-middle .header-right {
        padding-left: 0;
        margin-left: auto
    }

    .header-v2:not(.mobile-app-style) .header-middle .header-right>* {
        padding: 0
    }

    .header-v2:not(.mobile-app-style) .header-middle .header-right>.shopping-cart-wrapper {
        padding-left: 10px
    }

    .header-v2 .header-middle .shopping-cart-wrapper .cart-control .cart-total {
        display: none
    }

    .header-v2 .ts-header .shopping-cart-wrapper:last-child .cart-control {
        padding-right: 10px
    }

    .mobile-menu-wrapper .mobile-menu>ul>li.empty-label.ts-megamenu:only-child .sub-menu {
        transform: translateX(0);
        padding: 20px
    }

    .footer-container .elementor-widget-wp-widget-nav_menu.menu-horizontal li:not(:last-child) {
        margin-right: 20px
    }

    #ts-mobile-button-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 46px;
        border-width: 1px 0 0 0;
        border-style: solid;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 99999
    }

    #ts-mobile-button-bottom>.shopping-cart-wrapper:last-child,
    #ts-mobile-button-bottom>.my-wishlist-wrapper:last-child {
        padding-right: 10px
    }

    #ts-mobile-button-bottom>div {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center
    }

    #ts-mobile-button-bottom>div .icon,
    #ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist,
    #ts-mobile-button-bottom .my-account-wrapper .account-control>a,
    #ts-mobile-button-bottom .shopping-cart-wrapper .cart-control {
        width: 46px;
        height: 46px;
        display: block;
        line-height: 46px;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        color: inherit
    }

    #ts-mobile-button-bottom>div .icon:before,
    #ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist:before,
    #ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .ic-cart:before,
    #ts-mobile-button-bottom .ts-tiny-account-wrapper .account-control>a:before {
        font-size: 22px;
        line-height: inherit
    }

    #ts-mobile-button-bottom .my-wishlist-wrapper>a .count-number,
    #ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .cart-number {
        right: 0;
        top: 3px
    }

    #ts-mobile-button-bottom a {
        text-decoration: none !important;
        color: inherit
    }

    .ts-mobile-icon-toggle {
        position: relative
    }

    .ts-mobile-icon-toggle .icon {
        display: inline-block;
        vertical-align: top;
        line-height: 0;
        transition: 350ms ease
    }

    .mobile-app-style #to-top {
        bottom: 47px
    }

    #group-icon-header .tab-mobile-menu li {
        background: #252B33
    }

    #group-icon-header .tab-mobile-menu li,
    #group-icon-header .close:after {
        color: #fff
    }

    #group-icon-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    body.menu-mobile-active #group-icon-header {
        height: 100%
    }

    #group-icon-header .ts-sidebar-content {
        width: 100%;
        left: 0;
        right: auto;
        padding: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow-y: auto;
        transform: translateX(-100%)
    }

    #group-icon-header.active .ts-sidebar-content {
        transform: translateX(0)
    }

    .admin-bar .ts-floating-sidebar .ts-sidebar-content {
        top: 46px
    }

    #group-icon-header .sidebar-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: hidden;
        height: calc(100% - 30px)
    }

    body.mobile-app-style #group-icon-header .sidebar-content {
        height: calc(100% - 65px)
    }

    #group-icon-header .tab-mobile-menu {
        margin: 0;
        display: table;
        width: 100%
    }

    #group-icon-header .tab-mobile-menu:after {
        display: table;
        clear: both;
        content: ""
    }

    #group-icon-header .tab-mobile-menu li {
        list-style: none;
        text-transform: uppercase;
        padding: 5px;
        text-align: center;
        position: relative;
        white-space: nowrap;
        display: table-cell;
        line-height: 36px;
        min-width: 120px
    }

    .mobile-menu-wrapper nav ul li>a {
        color: inherit
    }

    #group-icon-header .tab-mobile-menu li:first-child {
        padding-left: 30px
    }

    #group-icon-header .tab-mobile-menu li:last-child {
        padding-right: 30px
    }

    #group-icon-header .tab-mobile-menu li:first-child:last-child {
        width: 100%
    }

    #group-icon-header .mobile-menu-wrapper {
        position: relative;
        margin-top: -50px;
        margin-bottom: 0 !important;
        padding-top: 58px;
        height: 100%
    }

    #group-icon-header .tab-vertical-menu {
        display: none
    }

    #group-icon-header .mobile-menu-wrapper .menu-main-mobile,
    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper,
    #group-icon-header .tab-vertical-menu .content-wrapper {
        height: 100%;
        overflow-y: auto
    }

    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper .vertical-menu {
        position: static;
        display: block;
        width: 100% !important;
        height: 100%;
        padding: 0 20px
    }

    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper .vertical-menu,
    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper:hover .vertical-menu {
        -webkit-animation-name: none;
        -moz-animation-name: none;
        animation-name: none
    }

    #group-icon-header .tab-vertical-menu .vertical-menu>ul>li {
        width: 100%
    }

    .vertical-menu>ul>li:first-child {
        border-top-width: 0;
        padding-top: 0
    }

    .vertical-menu ul.menu li .menu-icon {
        width: 50px
    }

    .mobile-menu-wrapper .vertical-menu .has-icon+span.ts-menu-drop-icon:not(.active) {
        top: 7px;
        width: 50px;
        height: 50px;
        line-height: 50px
    }

    .mobile-menu-wrapper .vertical-menu ul.menu li .menu-icon {
        margin-right: 10px
    }

    .mobile-menu-wrapper .vertical-menu-wrapper {
        position: static
    }

    .mobile-menu-wrapper nav.vertical-menu>ul>li {
        padding-left: 0;
        padding-right: 0
    }

    .ts-search-by-category .search-button:before {
        right: -10px
    }

    body.menu-mobile-active .ts-header .container,
    body.menu-mobile-active #main,
    body.menu-mobile-active #colophon {
        transform: translateX(0)
    }

    header .ts-mobile-icon-toggle .icon:before,
    header .shopping-cart-wrapper a>.ic-cart:before {
        font-size: 20px;
        line-height: 44px;
        display: inline-block
    }

    header .ts-mobile-icon-toggle .icon:before {
        font-size: 15px
    }

    body .mobile-menu-wrapper.ts-menu nav.mobile-menu {
        width: 100%;
        display: block;
        float: none;
        margin: 0;
        padding: 0
    }

    .mobile-menu-wrapper ul.menu li .menu-icon {
        margin-right: 5px
    }

    .mobile-menu-wrapper span.ts-menu-drop-icon {
        position: relative;
        top: 12px;
        left: auto;
        bottom: auto;
        text-align: center;
        right: -10px;
        z-index: 3;
        cursor: pointer;
        padding: 0;
        transform: none;
        width: 30px;
        float: right;
        transition: color 150ms ease 0s
    }

    body #group-icon-header .menu-title {
        margin: 0;
        padding: 15px 0;
        text-align: center;
        white-space: nowrap;
        line-height: 20px;
        position: relative
    }

    body #group-icon-header .menu-title:after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 20px;
        right: 20px;
        border-width: 1px 0 0;
        border-style: solid;
        z-index: 7
    }

    #group-icon-header .menu-title span {
        position: relative;
        z-index: 7;
        display: inline-block;
        padding: 0 40px
    }

    .mobile-menu-wrapper ul {
        margin: 0
    }

    .mobile-menu-wrapper ul.sub-menu {
        position: absolute;
        padding-top: 58px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        height: 100%;
        transform: translateX(-100%);
        display: block !important;
        transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        overflow: scroll
    }

    .mobile-menu-wrapper nav>ul>li>ul.sub-menu {
        top: 0
    }

    .mobile-menu-wrapper li.active .ts-menu-drop-icon.active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        z-index: 5;
        text-align: start;
        padding: 18px
    }

    .mobile-menu-wrapper nav>ul>li.active>.ts-menu-drop-icon.active {
        top: 46px
    }

    .mobile-menu-wrapper li.active>ul.sub-menu {
        transform: translateX(0)
    }

    .mobile-menu-wrapper nav>ul {
        margin: 0;
        padding: 0
    }

    .mobile-menu-wrapper nav>ul ul {
        margin: 0;
        padding: 0
    }

    .mobile-menu-wrapper nav>ul ul.sub-menu li:last-child>ul.sub-menu {
        margin-bottom: 0
    }

    .mobile-menu-wrapper nav ul>li {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        float: none
    }

    .mobile-menu-wrapper nav>ul>li,
    #group-icon-header .tab-vertical-menu>.content-wrapper,
    .mobile-menu-wrapper li.active>ul.sub-menu {
        padding-left: 20px;
        padding-right: 20px
    }

    .mobile-menu-wrapper .menu-sub-label {
        top: -5px;
        right: 0;
        transform: translateX(80%)
    }

    .mobile-menu-wrapper nav ul li>a {
        padding: 8px 0;
        display: inline-block;
        color: inherit;
        z-index: 1;
        position: relative
    }

    .mobile-menu-wrapper nav ul li>.shop-more {
        padding: 8px 0
    }

    .mobile-menu-wrapper nav ul li.has-line {
        padding-top: 16px
    }

    .mobile-menu-wrapper nav ul li.has-line:before {
        content: '';
        width: 23px;
        height: 1px;
        border-top: 1px solid #d9d9d9;
        display: block;
        margin-bottom: 8px
    }

    .mobile-menu-wrapper nav li a:hover {
        text-decoration: none
    }

    .mobile-menu-wrapper .vertical-menu>ul.menu li.ts-megamenu-fullwidth ul.sub-menu .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-4 .items .item,
    .mobile-menu-wrapper .vertical-menu>ul.menu li.ts-megamenu-fullwidth ul.sub-menu .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-3 .items .item {
        width: 50%
    }

    .mobile-menu-wrapper .ts-list-of-product-categories-wrapper.columns-2 .list-categories ul,
    .mobile-menu-wrapper .ts-list-of-product-categories-wrapper.columns-3 .list-categories ul,
    .mobile-menu-wrapper .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1
    }

    #group-icon-header .group-button-header:not(:empty) {
        padding: 20px 20px 0;
        margin-top: auto;
        position: relative;
        line-height: 18px
    }

    #group-icon-header .group-button-header .ts-tiny-account-wrapper.ts-style-text {
        text-align: center
    }

    #group-icon-header .group-button-header .ts-tiny-account-wrapper.ts-style-text .account-control {
        display: inline-block
    }

    #group-icon-header .group-button-header:not(:empty):before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        border-width: 1px 0 0;
        border-style: solid
    }

    .group-button-header>*:not(:last-child) {
        margin-bottom: 15px
    }

    .group-button-header>.language-currency {
        margin-top: -5px;
        text-align: center
    }

    .group-button-header .header-language,
    .group-button-header .header-currency {
        display: inline-block;
        vertical-align: middle;
        float: none
    }

    #group-icon-header .group-button-header a {
        color: inherit
    }

    .mobile-menu-wrapper *[class^="elementor-"] {
        position: static
    }

    body #group-icon-header .header-language a:hover,
    body #group-icon-header .header-language a:active,
    body #group-icon-header .header-language a:focus,
    body #group-icon-header .header-currency a:active,
    body #group-icon-header .header-currency a:focus,
    body #group-icon-header .header-currency a:hover {
        color: inherit
    }

    .ts-megamenu-container .elementor-widget-container>h5,
    .ts-megamenu-container .ts-list-of-product-categories-wrapper h3.heading-title {
        margin-bottom: 15px
    }

    .elementor-widget-wp-widget-nav_menu.columns-4 ul.menu,
    .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }

    body #group-icon-header .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
    body #group-icon-header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
    #group-icon-header .header-currency ul {
        padding: 20px 30px 26px 30px;
        top: auto;
        bottom: 100%;
        margin-bottom: 0;
        color: #000
    }

    #group-icon-header .wcml_currency_switcher>ul:before,
    #group-icon-header .wpml-ls-legacy-dropdown ul.wpml-ls-sub-menu:before,
    #group-icon-header .wpml-ls-item-legacy-dropdown-click ul.wpml-ls-sub-menu:before {
        top: 0;
        bottom: 6px
    }

    body #group-icon-header .wpml-ls-legacy-dropdown:hover .wpml-ls-sub-menu,
    body #group-icon-header .wpml-ls-legacy-dropdown:focus .wpml-ls-sub-menu,
    body #group-icon-header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
    #group-icon-header .header-currency:focus ul,
    #group-icon-header .header-currency:hover ul {
        -webkit-transform-origin: bottom center;
        -moz-transform-origin: bottom center;
        -ms-transform-origin: bottom center;
        transform-origin: bottom center
    }

    .filter-widget-area-button {
        margin: 0 20px 20px 0
    }

    .woocommerce .before-loop-wrapper>.widget,
    .woocommerce .woocommerce-ordering,
    .woocommerce-page .woocommerce-ordering,
    .before-loop-wrapper>.product-per-page-form {
        width: 50%;
        margin: 0 !important
    }

    .woocommerce .before-loop-wrapper>.widget,
    .before-loop-wrapper>.product-per-page-form {
        text-align: right
    }

    #main-content.ts-col-18 .before-loop-wrapper>.widget select,
    #main-content.ts-col-18 .product-per-page-form ul.perpage .perpage-current>span:last-child,
    #main-content.ts-col-18 .woocommerce .woocommerce-ordering .orderby-current,
    .before-loop-wrapper>.widget select,
    .product-per-page-form ul.perpage .perpage-current>span:last-child,
    .woocommerce .woocommerce-ordering .orderby-current {
        width: 140px
    }

    .woocommerce #main-content.ts-col-18 .before-loop-wrapper>.widget,
    .woocommerce #main-content.ts-col-18 .woocommerce-ordering,
    .woocommerce-page #main-content.ts-col-18 .woocommerce-ordering,
    #main-content.ts-col-18 .before-loop-wrapper>.product-per-page-form {
        width: auto;
        margin: 0 20px 0 0 !important
    }

    .woocommerce #main-content.ts-col-18 .before-loop-wrapper>.widget,
    #main-content.ts-col-18 .before-loop-wrapper>.product-per-page-form {
        text-align: left
    }

    .product-per-page-form ul.perpage {
        text-align: left
    }

    .woocommerce-page .page-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .filter-widget-area .widget-title-wrapper a.block-control {
        display: none !important
    }

    .filter-widget-area .widget-container>*:not(.widget-title-wrapper) {
        display: block !important
    }

    .list-categories:after {
        left: 5px;
        right: 5px
    }

    .woocommerce.archive #main-content.show-filter-sidebar {
        padding-left: 0
    }

    .woocommerce.columns-12 .products .product:not(.product-category),
    .woocommerce.columns-11 .products .product:not(.product-category),
    .woocommerce.columns-10 .products .product:not(.product-category),
    .woocommerce.columns-9 .products .product:not(.product-category),
    .woocommerce.columns-8 .products .product:not(.product-category),
    .woocommerce.columns-7 .products .product:not(.product-category),
    .woocommerce.columns-6 .products .product:not(.product-category),
    .woocommerce.columns-5 .products .product:not(.product-category),
    .woocommerce.columns-4 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-12 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-11 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-10 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-9 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-8 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-7 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-6 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-5 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-4 .products .product:not(.product-category),
    .woocommerce.columns-12 .products .list-categories .product.product-category,
    .woocommerce.columns-11 .products .list-categories .product.product-category,
    .woocommerce.columns-10 .products .list-categories .product.product-category,
    .woocommerce.columns-9 .products .list-categories .product.product-category,
    .woocommerce.columns-8 .products .list-categories .product.product-category,
    .woocommerce.columns-7 .products .list-categories .product.product-category,
    .woocommerce.columns-6 .products .list-categories .product.product-category,
    .woocommerce.columns-5 .products .list-categories .product.product-category,
    .woocommerce.columns-4 .products .list-categories .product.product-category {
        width: 33.33333%
    }

    .woocommerce.ts-product-category-wrapper.style-default.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-5 .products .product.product-category,
    .woocommerce.columns-12 .products .product.product-category,
    .woocommerce.columns-11 .products .product.product-category,
    .woocommerce.columns-10 .products .product.product-category,
    .woocommerce.columns-9 .products .product.product-category,
    .woocommerce.columns-8 .products .product.product-category,
    .woocommerce.columns-7 .products .product.product-category,
    .woocommerce.columns-6 .products .product.product-category {
        width: 25%
    }

    .woocommerce.columns-12.category-style-2 .products .product.product-category,
    .woocommerce.columns-11.category-style-2 .products .product.product-category,
    .woocommerce.columns-10.category-style-2 .products .product.product-category,
    .woocommerce.columns-9.category-style-2 .products .product.product-category,
    .woocommerce.columns-8.category-style-2 .products .product.product-category,
    .woocommerce.columns-7.category-style-2 .products .product.product-category,
    .woocommerce.columns-6.category-style-2 .products .product.product-category,
    .woocommerce.columns-5.category-style-2 .products .product.product-category,
    .woocommerce.columns-4.category-style-2 .products .product.product-category,
    .woocommerce.columns-3.category-style-2 .products .product.product-category {
        width: 33.33333%
    }

    .ts-product-category-wrapper.woocommerce:not(.category-style-vertical) .products .product-category img {
        max-width: 80px
    }

    .ts-shop-result-count {
        margin-bottom: 0
    }

    .woocommerce div.product.product-style-1 .summary-column-2 {
        width: 100%;
        position: static;
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .woocommerce div.product.product-style-1 .product-top-section>.ts-delivery-note {
        margin-top: -30px;
        display: block
    }

    .woocommerce div.product.product-style-1 .summary-column-2 .ts-delivery-note {
        display: none
    }

    .woocommerce div.product.product-style-1 div.summary {
        padding-right: 0
    }

    .woocommerce div.product.product-style-1 .summary-column-2 {
        width: 100%;
        margin-top: 45px
    }

    .woocommerce div.product.product-style-3 div.woocommerce-product-gallery,
    .woocommerce div.product.product-style-3 div.summary {
        width: 100%;
        float: none;
        padding-left: 0
    }

    .woocommerce .cross-sells:not(:last-child):before,
    .woocommerce .up-sells:not(:last-child):before,
    .woocommerce .related:not(:last-child):before {
        bottom: -8px
    }

    .woocommerce div.product.tabs-in-summary .summary>h2,
    .woocommerce div.product.tabs-in-summary .summary>#reviews,
    .woocommerce div.product.tabs-in-summary .summary>.woocommerce-tabs {
        margin-right: 0
    }

    #commentform.comment-form .cr-upload-local-images label,
    .comment-form .cr-upload-local-images label {
        width: 100%;
        margin: 0 0 15px
    }

    .pagination-wrap ul.pagination,
    .woocommerce nav.woocommerce-pagination ul,
    .ts-pagination ul,
    .dokan-pagination-container .dokan-pagination {
        padding: 0 42px
    }

    .pagination-wrap ul.pagination>li>a.prev,
    .pagination-wrap ul.pagination>li>a.next {
        width: 40px;
        height: 28px;
        padding: 0;
        line-height: 26px
    }

    .dokan-pagination-container .dokan-pagination li:first-child a,
    .dokan-pagination-container .dokan-pagination li:last-child a,
    .woocommerce nav.woocommerce-pagination ul li a.next,
    .woocommerce nav.woocommerce-pagination ul li a.prev,
    .ts-pagination ul li a.prev,
    .ts-pagination ul li a.next {
        width: 40px;
        height: 28px;
        line-height: 0;
        font-size: 0;
        padding: 0;
        min-width: 0
    }

    .dokan-pagination-container .dokan-pagination li:first-child a:before,
    .dokan-pagination-container .dokan-pagination li:last-child a:after,
    .woocommerce nav.woocommerce-pagination ul li a.next:after,
    .ts-pagination ul li a.next:after,
    .woocommerce nav.woocommerce-pagination ul li a.prev:before,
    .ts-pagination ul li a.prev:before {
        font-size: 12px;
        line-height: 26px;
        margin: 0
    }

    .dokan-pagination-container .dokan-pagination li:first-child a:before,
    .woocommerce nav.woocommerce-pagination ul li a.prev:before,
    .ts-pagination ul li a.prev:before {
        content: '<'
    }

    .dokan-pagination-container .dokan-pagination li:last-child a:after,
    .woocommerce nav.woocommerce-pagination ul li a.next:after,
    .ts-pagination ul li a.next:after {
        content: '>'
    }

    #main-content.ts-col-18~#right-sidebar {
        margin-top: 10px
    }

    #main-content.ts-col-12 .ts-pagination {
        padding-bottom: 0
    }

    .blog-template #main-content.ts-col-18~#right-sidebar {
        margin-top: 0
    }

    .ts-pagination ul li {
        padding-bottom: 0
    }

    .woocommerce-tabs #tab-description .ts-feature {
        margin: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .woocommerce-tabs #tab-description .ts-feature>div {
        padding: 0
    }

    .woocommerce-tabs #tab-description .ts-feature>div:not(:last-child) {
        padding-bottom: 34px;
        margin-bottom: 40px;
        border-width: 0 0 1px;
        border-style: solid
    }

    .product-style-3 .ts-product-brand-info .brand-info-wrapper .brand-info {
        width: 100%
    }

    .product-style-3 .ts-product-brand-info .brand-info-wrapper>img {
        margin: 0 0 15px
    }

    .woocommerce div.product.product-style-3 .ts-product-brand-info .description {
        max-width: 100%
    }

    .woocommerce div.product .woocommerce-tabs .panel table tr:not(:last-child):after {
        width: calc(100vw - 30px);
        left: calc(50% - 50vw + 15px)
    }

    .woocommerce div.product.product-style-5 div.woocommerce-product-gallery,
    .woocommerce div.product.product-style-5 div.summary {
        width: 100%;
        float: none;
        padding-left: 0
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
        margin-bottom: 21px;
        margin-top: 0
    }

    .woocommerce #review_form #respond .comment-form {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce #review_form #respond .comment-form>* {
        margin: 0 0 10px;
        padding-left: 0;
        padding-right: 0
    }

    .woocommerce #review_form #respond .comment-form>.comment-form-cookies-consent {
        margin: 15px 0 24px
    }

    .woocommerce #review_form #respond .comment-form>.comment-form-author,
    .woocommerce #review_form #respond .comment-form>.comment-form-email {
        width: 100%
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
        width: 100%;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-bottom: 0
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text p.meta .woocommerce-review__author {
        margin-top: 7px;
        margin-bottom: 0
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text .description {
        width: 100%
    }

    .woocommerce-account .woocommerce-MyAccount-content {
        padding: 25px 20px 30px
    }

    .woocommerce #customer_login form.login .button,
    .woocommerce #customer_login form.register .button {
        min-width: 0
    }

    .woocommerce ul.order_details {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 12px 20px
    }

    .woocommerce ul.order_details li {
        border-width: 0 0 1px;
        width: 100%;
        text-align: left;
        padding: 8px 0
    }

    .woocommerce ul.order_details li strong {
        display: inline-block
    }

    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
        margin-left: -5px;
        margin-right: -5px
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row,
    .woocommerce-shipping-fields__field-wrapper>p.form-row {
        margin-left: 5px;
        margin-right: 5px
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-last,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-first,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-last,
    .woocommerce-billing-fields__field-wrapper>#billing_postcode_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_postcode_field,
    .woocommerce-billing-fields__field-wrapper>#billing_city_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_city_field {
        width: calc(50% - 10px)
    }

    .wpcf7 form div.columns-3 {
        margin-left: 0;
        margin-right: 0
    }

    .wpcf7 form div.columns-3>label {
        width: 100%;
        float: none;
        padding: 0
    }

    body.search.search-no-results .search-wrapper {
        max-width: 80%
    }

    body.search.search-no-results .alert p {
        font-size: 120%
    }

    body.search.search-no-results .alert {
        padding-bottom: 20px !important
    }

    .ts-floating-sidebar .woocommerce ul.product_list_widget li {
        margin-bottom: 10px
    }

    .intro-main-image.elementor-widget-image img {
        max-width: 100%
    }

    .has-filters .filter-bar {
        font-size: 16px
    }

    .intro-filter-items .filter-item {
        width: 33.33333%
    }

    .columns-0 .list-posts article:nth-child(2),
    .columns-0 .list-posts article,
    .columns-2 .list-posts article,
    .ts-blogs.columns-2 .items article,
    .columns-3 .list-posts article,
    .ts-blogs.columns-3 .items article {
        width: 100%
    }

    .list-posts article .entry-content>*:not(:last-child),
    .ts-blogs article .entry-content>*:not(:last-child) {
        margin-bottom: 13px
    }

    .entry-author {
        padding: 20px
    }

    .entry-author .author-avatar {
        width: 80px;
        padding-right: 10px
    }

    .commentlist .comment-detail .comment-meta,
    .commentlist .comment-detail .comment-text {
        width: 100%;
        float: none
    }

    .commentlist li.comment .comment-detail {
        padding: 24px 0
    }

    .commentlist li.comment .comment-actions {
        top: 28px
    }

    .commentlist li.comment .comment-meta,
    .commentlist .comment-detail .comment-meta {
        padding-right: 90px
    }

    .commentlist .comment-detail .comment-meta {
        margin-bottom: 10px
    }

    .commentlist .comment-detail .comment-text {
        width: 100%;
        padding-right: 0
    }

    .comment-meta span {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px
    }

    .commentlist li.comment .avatar+.comment-meta {
        margin-top: 4px
    }

    .commentlist li #comment-wrapper {
        padding-left: 0
    }

    .comment-meta .author+.date-time {
        width: auto
    }

    #commentform .info-wrapper {
        margin: 0 -5px
    }

    #commentform .info-wrapper>p {
        padding: 0 5px
    }

    .item-wrapper .portfolio-meta>*:not(:last-child) {
        margin-bottom: 8px
    }

    .list-posts article,
    .ts-blogs .items article {
        margin-bottom: 50px;
        padding-bottom: 50px
    }

    .page-container:not(.columns-0):not(.columns-1):not(.columns-2):not(.columns-3) .list-posts article:not(:last-child) {
        padding-bottom: 50px
    }

    .ts-shortcode.ts-portfolio-wrapper .load-more-wrapper {
        margin: 48px 0 40px
    }

    .list-posts article.sticky {
        padding: 30px !important
    }

    .single-portfolio>.entry-main {
        margin-bottom: 45px
    }

    body.page .page-container #primary>article.page {
        margin-top: 0
    }

    .entry-meta-middle .author>img,
    .entry-meta-bottom .author>img,
    .columns-0 .list-posts article:nth-child(2) .entry-meta-middle .author>img,
    .columns-0 .list-posts article:nth-child(2) .entry-meta-bottom .author>img,
    .columns-0 .list-posts article:not(:nth-child(1)):not(:nth-child(2)) .entry-meta-middle .author>img,
    .columns-0 .list-posts article:not(:nth-child(1)):not(:nth-child(2)) .entry-meta-bottom .author>img,
    .columns-3 .list-posts article .entry-meta-middle .author>img,
    .columns-3 .list-posts article .entry-meta-bottom .author>img {
        width: 22px;
        height: 22px
    }

    .woocommerce .woocommerce-order,
    .woocommerce .woocommerce-customer-details {
        margin-bottom: 42px
    }

    body .ts-popup-modal .product-360-container {
        width: 200px
    }

    .threesixty .nav_bar a {
        width: 30px;
        height: 30px;
        margin: 3px 0
    }

    .threesixty .nav_bar a:before {
        font-size: 12px;
        line-height: 30px
    }

    body .ts-popup-modal .add-to-cart-popup-container {
        width: 70%;
        padding: 20px
    }

    .add-to-cart-popup-content .heading {
        margin-bottom: 10px
    }

    .add-to-cart-popup-content .item .product-image {
        width: 30%
    }

    .add-to-cart-popup-content .item .product-meta {
        width: calc(70% - 30px)
    }

    .column-tabs .list-categories {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 20px
    }

    .style-tabs-default.only-child .column-tabs .list-categories {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .style-tabs-default .column-tabs .shop-more,
    .style-tabs-default.only-child .column-tabs .shop-more {
        margin-top: 13px;
        margin-left: 0
    }

    .column-tabs .list-categories>* {
        width: 100%;
        margin: 0
    }

    .elementor-col-20 .ts-banner .box-content {
        max-width: calc(100% - 30px)
    }

    .left-top .box-content {
        left: 15px;
        top: 15px
    }

    .left-bottom .box-content {
        left: 15px;
        bottom: 20px
    }

    .left-center .box-content {
        left: 15px
    }

    .right-top .box-content {
        right: 15px;
        top: 15px
    }

    .right-bottom .box-content {
        right: 15px;
        bottom: 20px
    }

    .right-center .box-content {
        right: 15px
    }

    .center-top .box-content {
        left: 15px;
        right: 15px;
        top: 15px
    }

    .center-bottom .box-content {
        left: 15px;
        right: 15px;
        bottom: 20px
    }

    .center-center .box-content {
        left: 15px;
        right: 15px
    }

    .ts-banner .box-content .banner-text strong {
        font-size: 120%
    }

    .ts-product-deals-wrapper .shortcode-heading-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ts-product-deals-wrapper .shortcode-heading-wrapper>*:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px
    }

    .ts-portfolio-wrapper .portfolio-meta,
    .ts-portfolio-wrapper .portfolio-meta-inner {
        padding: 15px
    }

    .ts-product-brand-wrapper.style-2 .item {
        padding: 18px 0
    }

    .ts-portfolio-wrapper.columns-2 .item,
    .ts-portfolio-wrapper.columns-3 .item,
    .ts-portfolio-wrapper.columns-4 .item {
        width: 100%;
        float: none
    }

    #ts-search-sidebar .ts-sidebar-content {
        bottom: 0
    }

    .ts-floating-sidebar .ts-search-result-container {
        padding: 30px 0
    }

    .woocommerce.main-content-fullwidth #tab-more_seller_product .products .product,
    .woocommerce.layout-fullwidth #tab-more_seller_product .products .product {
        width: 33.33333%
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

    .woocommerce div.product.product-style-5:not(.product-style-3):not(.product-style-4) div.images .flex-control-thumbs li,
    .woocommerce div.product.product-style-3 div.images .flex-control-thumbs li {
        width: 16.66666%
    }
}

@media only screen and (max-width: 670px) {

    .ts-logo-slider-wrapper:not(.ts-slider).columns-6 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-5 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-4 .item {
        width: 33.333333%
    }
}

@media only screen and (max-width: 570px) {

    .woocommerce.ts-product-category-wrapper.style-icon.columns-8 .products .product,
    .woocommerce.ts-product-category-wrapper.style-icon.columns-7 .products .product,
    .woocommerce.ts-product-category-wrapper.style-icon.columns-6 .products .product {
        width: 25%
    }

    .ts-store-notice .container {
        min-height: 0;
        padding-top: 5px;
        padding-bottom: 5px
    }

    .ts-store-notice .divider {
        width: 100%;
        height: 1px;
        min-height: 0;
        opacity: 0;
        visibility: hidden
    }

    .yith-wcwl-share .yith-wcwl-after-share-section,
    .yith-wcwl-share .yith-wcwl-after-share-section input.copy-target {
        text-align: left
    }

    .yith-wcwl-share .yith-wcwl-after-share-section {
        margin-top: 10px
    }

    body .elementor-image-gallery .gallery-columns-4 .gallery-item,
    body .elementor-image-gallery .gallery-columns-5 .gallery-item,
    body .elementor-image-gallery .gallery-columns-6 .gallery-item,
    body .elementor-image-gallery .gallery-columns-7 .gallery-item,
    body .elementor-image-gallery .gallery-columns-8 .gallery-item,
    body .elementor-image-gallery .gallery-columns-9 .gallery-item {
        width: 33.333333% !important
    }

    #main-content.ts-col-18 .before-loop-wrapper>.widget select,
    #main-content.ts-col-18 .product-per-page-form ul.perpage .perpage-current>span:last-child,
    #main-content.ts-col-18 .woocommerce .woocommerce-ordering .orderby-current,
    .before-loop-wrapper>.widget select,
    .product-per-page-form ul.perpage .perpage-current>span:last-child,
    .woocommerce .woocommerce-ordering .orderby-current {
        width: 110px
    }
}

@media only screen and (max-width: 500px) {

    .ts-team-members:not(.ts-slider) .item.ts-col-4,
    .ts-team-members:not(.ts-slider) .item.ts-col-8,
    .ts-team-members:not(.ts-slider) .item.ts-col-12,
    .ts-team-members:not(.ts-slider) .item.ts-col-6 {
        width: 100%
    }
}

@media only screen and (max-width: 480px) {
    .elementor-widget ul.elementor-icon-list-items.elementor-inline-items {
        margin: 0 !important;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ts-floating-sidebar.active .ts-sidebar-content {
        width: 100%
    }

    .woocommerce.columns-12 .products .product:not(.product-category),
    .woocommerce.columns-11 .products .product:not(.product-category),
    .woocommerce.columns-10 .products .product:not(.product-category),
    .woocommerce.columns-9 .products .product:not(.product-category),
    .woocommerce.columns-8 .products .product:not(.product-category),
    .woocommerce.columns-7 .products .product:not(.product-category),
    .woocommerce.columns-6 .products .product:not(.product-category),
    .woocommerce.columns-5 .products .product:not(.product-category),
    .woocommerce.columns-4 .products .product:not(.product-category),
    .woocommerce.columns-3 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-12 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-11 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-10 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-9 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-8 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-7 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-6 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-5 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-4 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-3 .products .product:not(.product-category) {
        width: 50%
    }

    .woocommerce.ts-product-category-wrapper.style-default.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-5 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-4 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-3 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-5 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-4 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-3 .products .product.product-category {
        width: 100%
    }

    #main-content.ts-col-18 .woocommerce.columns-12 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-11 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-10 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-9 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-8 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-7 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-6 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-5 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-4 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-3 .products .product:not(.product-category) {
        width: 50%
    }

    .ts-floating-sidebar .woocommerce ul.product_list_widget li,
    .woocommerce.ts-search-result-container ul.product_list_widget li {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0
    }

    #ts-filter-widget-area .ts-sidebar-content,
    .style-filter-top .before-loop-wrapper #ts-filter-widget-area .ts-sidebar-content {
        width: 100% !important
    }

    #ts-search-sidebar .ts-sidebar-content {
        padding-left: 20px;
        padding-right: 20px
    }

    .woocommerce .woocommerce-ordering .orderby ul,
    .product-per-page-form ul.perpage ul {
        width: 130px
    }

    .woocommerce #main-content.ts-col-18 .woocommerce-ordering,
    .woocommerce-page #main-content.ts-col-18 .woocommerce-ordering,
    #main-content.ts-col-18 .before-loop-wrapper>.product-per-page-form {
        width: 50%;
        margin: 0 !important
    }

    .woocommerce #main-content.ts-col-18 .before-loop-wrapper>.widget {
        width: 100%;
        margin: 0 0 10px !important
    }

    #main-content.ts-col-18 .product-per-page-form {
        text-align: right
    }

    .woocommerce div.product div.images {
        display: block
    }

    .woocommerce div.product div.images .flex-control-thumbs {
        width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 15px -1px 0
    }

    .woocommerce div.product div.images .flex-control-thumbs li {
        width: 20%;
        padding: 15px 1px 0;
        margin: 0
    }

    #ts-product-360-modal.ts-popup-modal .close {
        right: 3px
    }

    .woocommerce.main-content-fullwidth #tab-more_seller_product .products .product,
    .woocommerce.layout-fullwidth #tab-more_seller_product .products .product {
        width: 50%
    }

    .list-posts .gallery .gallery-item {
        width: 100% !important
    }

    .list-posts .gallery .gallery-item img {
        width: 100%
    }

    .elementor-widget-wp-widget-nav_menu.columns-3 ul.menu,
    .elementor-widget-wp-widget-nav_menu.columns-4 ul.menu,
    .ts-list-of-product-categories-wrapper.columns-3 .list-categories ul,
    .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
    }

    .ts-store-notice>.container {
        padding-right: 30px
    }

    .ts-store-notice .close {
        top: 7px
    }

    .header-v4 .header-middle .shopping-cart-wrapper .cart-control .cart-total {
        display: none
    }

    .footer-container .elementor-column:not(.elementor-col-100) .ts-mailchimp-subscription-shortcode .widget-title-wrapper .widget-title {
        max-width: 100%
    }

    .social-icons .list-icons {
        margin: 0 -3px
    }

    .social-icons .list-icons li {
        margin: 0 3px
    }

    .footer-container .elementor-widget-wp-widget-nav_menu ul.menu li {
        margin: 0
    }

    .woocommerce.columns-12 .products .product.product-category,
    .woocommerce.columns-11 .products .product.product-category,
    .woocommerce.columns-10 .products .product.product-category,
    .woocommerce.columns-9 .products .product.product-category,
    .woocommerce.columns-8 .products .product.product-category,
    .woocommerce.columns-7 .products .product.product-category,
    .woocommerce.columns-6 .products .product.product-category,
    .woocommerce.columns-5 .products .product.product-category,
    .woocommerce.columns-4 .products .product.product-category {
        width: 33.333333%
    }

    .woocommerce.columns-8 .products .product,
    .woocommerce.columns-7 .products .product,
    .woocommerce.columns-6 .products .product,
    .woocommerce.columns-5 .products .product,
    .woocommerce.columns-4 .products .product,
    .woocommerce.columns-3 .products .product,
    .style-tabs-vertical .woocommerce.columns-8 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-7 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-6 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-5 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-4 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-3 .products .product:not(.product-category),
    .woocommerce.columns-12 .products .list-categories .product.product-category,
    .woocommerce.columns-11 .products .list-categories .product.product-category,
    .woocommerce.columns-10 .products .list-categories .product.product-category,
    .woocommerce.columns-9 .products .list-categories .product.product-category,
    .woocommerce.columns-8 .products .list-categories .product.product-category,
    .woocommerce.columns-7 .products .list-categories .product.product-category,
    .woocommerce.columns-6 .products .list-categories .product.product-category,
    .woocommerce.columns-5 .products .list-categories .product.product-category,
    .woocommerce.columns-4 .products .list-categories .product.product-category,
    .woocommerce.columns-3 .products .list-categories .product.product-category,
    .woocommerce #tab-more_seller_product .products .product,
    .woocommerce.main-content-fullwidth #tab-more_seller_product .products .product,
    .woocommerce.layout-fullwidth #tab-more_seller_product .products .product {
        width: 50%
    }

    .woocommerce.main-products.style-column-list .products .list-categories .product.product-category {
        width: 100%
    }

    .woocommerce.main-products.style-column-list .product .price del+ins {
        margin-right: 5px
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper {
        width: 33%
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        width: 45%;
        padding-right: 25px
    }

    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2>*,
    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2 .quantity {
        margin-bottom: 5px
    }

    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2 .quantity,
    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2 .product-group-button-meta {
        width: 100%
    }

    .main-products.style-column-list .product-group-button-meta>div.loop-add-to-cart>a.button {
        padding: 0 5px !important;
        width: 100% !important;
        min-width: 0 !important;
        margin: 0 !important
    }

    .woocommerce-cart article .woocommerce .cart-collaterals,
    .woocommerce-checkout #order_review {
        padding: 30px
    }

    .woocommerce>form.checkout #order_review_heading {
        margin: 25px 0 0
    }

    .woocommerce>form.checkout #order_review {
        padding: 60px 30px 30px;
        margin-top: -49px
    }

    .woocommerce div.product .yith-wfbt-section .yith-wfbt-form .yith-wfbt-images td:not(.image_plus) {
        min-width: 33.333333%
    }

    .woocommerce div.product.product-style-1:not(.product-style-3):not(.product-style-4) div.images .flex-control-thumbs li {
        width: 20%
    }

    ul.features {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0
    }

    .woocommerce div.product.reviews-grid #reviews #comments .commentlist {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0
    }

    .woocommerce #tab-more_seller_product .products .product {
        width: 50%
    }

    ul.features li {
        padding-top: 15px
    }

    .woocommerce.product-label-rectangle div.product .images .product-label>span.onsale,
    .woocommerce.product-label-rectangle div.product .images .product-label>span {
        font-size: 12px;
        line-height: 22px !important;
        min-width: 50px
    }

    .product-style-3 .ts-product-brand-info .brand-info-wrapper,
    .woocommerce div.product.product-style-3 .product-images-summary {
        padding: 30px 20px
    }

    .woocommerce div.product.product-style-3 .ts-delivery-note+.yith-wfbt-section {
        margin-top: 50px
    }

    .woocommerce div.product.product-style-3 .ads-banner,
    #main-content.ts-col-24 div.product.product-style-3 .ads-banner {
        width: 100%;
        padding-left: 0;
        text-align: center
    }

    .woocommerce div.product.product-style-3 .product-top-section .ts-product-brand-info {
        width: 100%
    }

    .woocommerce div.product.product-style-4 .product-images-summary {
        width: 100%;
        padding: 20px
    }

    .load-more-wrapper,
    .woocommerce .woocommerce-cart-form,
    .woocommerce-checkout #customer_details,
    .woocommerce-cart article .woocommerce .cart-collaterals,
    .woocommerce-checkout #order_review,
    .woocommerce #customer_login,
    .woocommerce>form.checkout #customer_details,
    .woocommerce>form.checkout #order_review,
    .yith_wcwl_wishlist_footer .yith-wcwl-share,
    .blog-template #left-sidebar,
    .blog-template #right-sidebar,
    .ts-pagination,
    .woocommerce .after-loop-wrapper:not(:last-child),
    .woocommerce.archive #main>.page-container,
    .single-portfolio .related-portfolios,
    .woocommerce div.product.tabs-in-summary .woocommerce.yith-wfbt-section,
    .woocommerce-account .woocommerce-MyAccount-content,
    .ts-delivery-note,
    .woocommerce div.product.product-style-4 .product-top-section>.ts-product-brand-info {
        margin-bottom: 50px
    }

    .woocommerce div.product>.ts-product-in-category-tab-wrapper {
        padding-bottom: 50px
    }

    .woocommerce-tabs {
        padding-top: 45px;
        padding-bottom: 50px
    }

    .woocommerce div.product .ts-instagram-shortcode {
        padding: 45px 0 50px
    }

    .woocommerce div.product .ts-mailchimp-subscription-shortcode {
        padding: 50px 0 40px
    }

    .ts-product-brand-info ul.product_list_widget li .cart-item-wrapper,
    .woocommerce .ts-product-brand-info ul.product_list_widget li .cart-item-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .ts-product-brand-info ul.product_list_widget li .meta-wrapper:last-child {
        margin-left: 0;
        padding-left: 0;
        text-align: left
    }

    .ts-product-brand-info ul.product_list_widget li .price,
    .woocommerce .ts-product-brand-info ul.product_list_widget li .price {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin: 8px 0 0
    }

    .ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li:not(:last-child) {
        padding-right: 20px
    }

    .woocommerce #customer_login .col-1,
    .woocommerce #customer_login .col-2,
    .woocommerce-page #customer_login .col-1,
    .woocommerce-page #customer_login .col-2 {
        width: 100%;
        float: none
    }

    .woocommerce #customer_login .col-2,
    .woocommerce-page #customer_login .col-2 {
        border-width: 1px 0 0
    }

    .woocommerce #customer_login,
    .woocommerce form.track_order,
    .woocommerce form.woocommerce-ResetPassword.lost_reset_password {
        max-width: 100%
    }

    body .wishlist_table.mobile li {
        width: calc(100% - 2px)
    }

    .woocommerce #content table.cart td.actions .coupon,
    .woocommerce table.cart td.actions .coupon,
    .woocommerce-page #content table.cart td.actions .coupon,
    .woocommerce-page table.cart td.actions .coupon {
        padding-bottom: 10px;
        float: none;
        width: 100%
    }

    .woocommerce-cart table.cart td.actions:after {
        content: '';
        clear: both;
        display: table
    }

    .woocommerce-cart table.cart td.actions>.button {
        width: calc(50% - 5px) !important;
        float: left;
        display: block
    }

    .woocommerce-cart table.cart td.actions>.button.empty-cart-button {
        margin-left: 10px
    }

    .woocommerce table.shop_table.cart td.actions {
        text-align: start !important
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item {
        margin-bottom: 10px;
        padding: 15px 35px 15px 15px
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td {
        padding: 4px 0;
        width: calc(100% - 110px)
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-thumbnail {
        width: 110px;
        padding: 0 15px 0 0
    }

    .woocommerce-cart table.shop_table.shop_table_responsive.cart tr.cart_item td.product-remove {
        width: 26px;
        right: 5px
    }

    .woocommerce table.shop_table_responsive.cart tr td:not(.product-subtotal)::before,
    .woocommerce-page table.shop_table_responsive.cart tr td:not(.product-subtotal)::before {
        display: none
    }

    .woocommerce-cart table.cart td.actions .coupon .input-text {
        min-width: 0
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-subtotal {
        clear: both;
        padding-top: 10px;
        padding-bottom: 0;
        margin-top: 17px;
        border-width: 1px 0 0;
        border-style: dotted;
        width: calc(100% + 15px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .woocommerce form.checkout_coupon,
    .woocommerce .checkout-login-coupon-wrapper form.login {
        padding: 20px
    }

    .woocommerce .checkout-login-coupon-wrapper .checkout_coupon .form-row-first {
        width: 100%
    }

    .woocommerce .checkout-login-coupon-wrapper .checkout_coupon .form-row-last {
        width: 100%;
        min-width: 0;
        margin-left: 0;
        margin-top: 10px
    }

    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce form .form-row-first,
    .woocommerce form .form-row-last,
    .woocommerce-page form .form-row-last,
    .woocommerce-page form .form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-last,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-first,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-last,
    .woocommerce-billing-fields__field-wrapper>#billing_postcode_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_postcode_field,
    .woocommerce-billing-fields__field-wrapper>#billing_city_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_city_field,
    .woocommerce-billing-fields__field-wrapper>p.form-row,
    .woocommerce-shipping-fields__field-wrapper>p.form-row {
        width: 100%;
        margin-left: 0;
        margin-right: 0
    }

    body.search.search-no-results .search-wrapper {
        max-width: 100%
    }

    div.wpcf7 .ts-row .ts-column-left,
    div.wpcf7 .ts-row .ts-column-right {
        float: none;
        margin-right: 0;
        width: 100%
    }

    div.wpcf7 .ts-row .ts-column-left {
        margin-bottom: 10px
    }

    body.error404 #primary>article div.not-found h1 {
        font-size: 100px;
        line-height: 1
    }

    body.error404 #main>.page-container {
        padding-top: 22px
    }

    body.error404 .not-found {
        margin-bottom: 50px
    }

    .intro-filter-items .filter-item {
        width: 50%
    }

    #commentform .info-wrapper {
        margin: 0
    }

    #commentform .info-wrapper>p {
        width: 100%;
        float: none;
        padding: 0
    }

    .commentlist #commentform p {
        margin-bottom: 20px
    }

    .post-password-form>p>label {
        display: block
    }

    .post-password-form>p>label>input {
        min-width: 0;
        width: 100%
    }

    .post-password-form>p>label+input[type="submit"] {
        width: 100%;
        min-width: 0;
        margin-left: 0;
        display: block;
        top: 0
    }

    .wp-block-search .wp-block-search__inside-wrapper {
        flex-direction: column
    }

    .wp-block-search .wp-block-search__button {
        width: 100%;
        min-width: 0;
        margin: 10px 0 0
    }

    .wp-block-search .wp-block-search__input {
        width: 100%;
        min-width: 0
    }

    body .ts-popup-modal .product-360-container {
        width: calc(85% - 50px)
    }

    .add-to-cart-popup-content .item:after {
        content: '';
        clear: both;
        display: table
    }

    .add-to-cart-popup-content .action .button {
        width: 100%;
        min-width: 0
    }

    .add-to-cart-popup-content .item .product-image {
        width: 40%;
        margin-right: 20px
    }

    .add-to-cart-popup-content .item .product-meta {
        width: calc(60% - 20px)
    }

    .column-tabs .list-categories {
        background-position: 25% 0
    }

    .ts-mailchimp-subscription-shortcode .mc4wp-form .subscribe-email input[type="email"] {
        -webkit-box-flex: none;
        -ms-flex: none;
        flex: none;
        margin: 0 0 10px
    }

    .ts-mailchimp-subscription-shortcode .mc4wp-form .subscribe-email .button {
        width: 100%
    }

    .ts-instagram-wrapper.columns-4 .item,
    .ts-instagram-wrapper.columns-5 .item,
    .ts-instagram-wrapper.columns-6 .item {
        width: 50%
    }

    .ts-instagram-wrapper.columns-4 .item:nth-child(4n+1),
    .ts-instagram-wrapper.columns-5 .item:nth-child(5n+1),
    .ts-instagram-wrapper.columns-6 .item:nth-child(6n+1) {
        clear: none
    }

    .ts-instagram-wrapper.columns-4 .item:nth-child(2n+1),
    .ts-instagram-wrapper.columns-5 .item:nth-child(2n+1),
    .ts-instagram-wrapper.columns-6 .item:nth-child(2n+1) {
        clear: both
    }

    .woocommerce.ts-product-category-wrapper.style-default.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-4 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-5 .products .product.product-category {
        width: 33.3333%
    }

    body .elementor-image-gallery .gallery-columns-4 .gallery-item,
    body .elementor-image-gallery .gallery-columns-5 .gallery-item,
    body .elementor-image-gallery .gallery-columns-6 .gallery-item,
    body .elementor-image-gallery .gallery-columns-7 .gallery-item,
    body .elementor-image-gallery .gallery-columns-8 .gallery-item,
    body .elementor-image-gallery .gallery-columns-9 .gallery-item {
        width: 50% !important
    }

    .woocommerce.columns-12.category-style-2 .products .product.product-category,
    .woocommerce.columns-11.category-style-2 .products .product.product-category,
    .woocommerce.columns-10.category-style-2 .products .product.product-category,
    .woocommerce.columns-9.category-style-2 .products .product.product-category,
    .woocommerce.columns-8.category-style-2 .products .product.product-category,
    .woocommerce.columns-7.category-style-2 .products .product.product-category,
    .woocommerce.columns-6.category-style-2 .products .product.product-category,
    .woocommerce.columns-5.category-style-2 .products .product.product-category,
    .woocommerce.columns-4.category-style-2 .products .product.product-category,
    .woocommerce.columns-3.category-style-2 .products .product.product-category {
        width: 50%
    }

    .ts-product-category-wrapper.woocommerce.category-style-2 .products .product-category img {
        max-width: 60px
    }

    .ts-product.style-tabs-vertical.style-tabs-vertical-banner .banners {
        display: block
    }

    .ts-product.style-tabs-vertical.style-tabs-vertical-banner .banners {
        margin-left: 0;
        margin-right: 0
    }

    .ts-product.style-tabs-vertical.style-tabs-vertical-banner .banners .banner {
        padding: 0;
        display: block
    }

    .woocommerce ul#shipping_method li input[type^="radio"] {
        margin-top: 1px
    }

    .ts-instagram-wrapper.columns-12 .item,
    .ts-instagram-wrapper.columns-11 .item,
    .ts-instagram-wrapper.columns-10 .item,
    .ts-instagram-wrapper.columns-9 .item,
    .ts-instagram-wrapper.columns-8 .item,
    .ts-instagram-wrapper.columns-7 .item,
    .ts-instagram-wrapper.columns-6 .item,
    .ts-instagram-wrapper.columns-5 .item,
    .ts-instagram-wrapper.columns-4 .item,
    .ts-instagram-wrapper.columns-3 .item {
        width: 50%
    }

    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-5 .items .item,
    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-7 .items .item,
    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-8 .items .item,
    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-6 .items .item {
        width: 50%
    }

    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper {
        width: 33%
    }

    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        width: 45%
    }
}

@media only screen and (max-width: 430px) {

    .ts-logo-slider-wrapper:not(.ts-slider).columns-6 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-5 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-4 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-3 .item {
        width: 50%
    }
}

@media only screen and (max-width: 350px) {
    .woocommerce div.product form.cart div.quantity {
        margin: 0;
        width: 100%
    }

    .woocommerce div.product form.cart .button {
        width: 100%;
        margin-top: 10px
    }

    body:not(.mobile-app-style) .ts-header .my-wishlist-wrapper .tini-wishlist .count-number,
    body:not(.mobile-app-style) .ts-header .shopping-cart-wrapper .cart-control .cart-number {
        left: 22px;
        width: 16px;
        height: 16px;
        font-size: 9px;
        line-height: 16px
    }

    body:not(.mobile-app-style) .ts-header .search-button.search-icon .icon,
    body:not(.mobile-app-style) .ts-header .my-wishlist-wrapper .tini-wishlist,
    body:not(.mobile-app-style) .ts-header .my-account-wrapper .account-control>a,
    body:not(.mobile-app-style) .ts-header .shopping-cart-wrapper .cart-control {
        padding: 10px
    }

    .ts-logo-slider-wrapper:not(.ts-slider).columns-6 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-5 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-4 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-3 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-2 .item {
        width: 100%;
        margin-right: 0
    }

    header .logo img,
    header .logo-header img {
        max-width: 158px
    }

    #main-content.ts-col-18 .before-loop-wrapper>.widget select,
    #main-content.ts-col-18 .product-per-page-form ul.perpage .perpage-current>span:last-child,
    #main-content.ts-col-18 .woocommerce .woocommerce-ordering .orderby-current,
    .before-loop-wrapper>.widget select,
    .product-per-page-form ul.perpage .perpage-current>span:last-child,
    .woocommerce .woocommerce-ordering .orderby-current {
        width: 90px
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        padding-left: 5px;
        padding-right: 20px
    }

    .main-products.style-column-list .product-group-button-meta>div.loop-add-to-cart>a.button {
        padding: 0 !important;
        font-size: 11px !important
    }

    #group-icon-header .group-button-header:not(:empty) {
        padding: 10px 20px 0
    }

    body.mobile-app-style #group-icon-header .sidebar-content {
        height: calc(100% - 50px)
    }

    .woocommerce div.product .ts-product-brand-info form.cart .button {
        width: 100% !important;
        margin: 5px 0 0;
        max-width: 100%
    }

    .woocommerce.main-products.style-column-list .product .price del,
    .woocommerce.main-products.style-column-list .product .price ins {
        width: 100%
    }

    .woocommerce.main-products.style-column-list .product .price del+ins {
        margin-right: 0
    }

    .woocommerce.main-products.style-column-list .product .price {
        font-size: 90%
    }

    .woocommerce .before-loop-wrapper>.widget {
        width: 100%;
        margin-bottom: 10px !important;
        text-align: left
    }

    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row,
    .woocommerce-shipping-fields__field-wrapper>p.form-row {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-last,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-first,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-last,
    .woocommerce-billing-fields__field-wrapper>#billing_postcode_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_postcode_field,
    .woocommerce-billing-fields__field-wrapper>#billing_city_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_city_field {
        width: 100%
    }

    .ts-product.style-list .shortcode-heading-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .ts-product.style-list .shortcode-heading-wrapper .shop-more {
        margin-left: 0
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper {
        display: block
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper:after,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper:after {
        content: '';
        clear: both;
        display: table
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .thumbnail-wrapper,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .thumbnail-wrapper {
        width: 30%;
        float: left
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper {
        width: 70%;
        padding-left: 15px;
        padding-right: 35px;
        float: left
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        margin-top: 7px
    }

    .ts-product.style-list .product-group-button-meta>div.loop-add-to-cart>a.button {
        width: 100%
    }

    .main-products.style-column-list .products .product:not(.product-category) .price del,
    .main-products.style-column-list .products .product:not(.product-category) .price ins,
    .ts-product.style-list .products .product:not(.product-category) .price del,
    .ts-product.style-list .products .product:not(.product-category) .price ins {
        width: auto
    }

    .main-products.style-column-list .products .product:not(.product-category) .price del+ins,
    .ts-product.style-list .products .product:not(.product-category) .price del+ins {
        margin-right: 5px;
        margin-bottom: 0
    }

    .ts-product.style-list .products .product:not(.product-category) .meta-wrapper-2 .price,
    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 .price {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media only screen and (max-height: 500px) {
    #ts-add-to-cart-popup-modal .close {
        top: 5px;
        right: 5px;
        transform: translateY(0);
        color: #191919
    }
}