// Animation css
@import '~animate.css/animate.min.css';

// Bootstrap Layout scss
@import '~bootstrap/scss/bootstrap';

// Owl Carousel
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Themify icon scss
@import 'themify';

// Theme Scss
@import 'theme/variables';
@import '../css/14cda.css';
@import '../css/330a0.css';
@import '../css/5f5cb.css';
@import '../css/b2ad2.css';
@import '../css/c1c3c.css';
@import '../css/d42bf.css';
@import '../css/da217.css';
@import '../css/e5f8a.css';
@import '../css/f856d.css';

:root {
  --theme-deafult: #ff4c3b;
  // This gradient color only for gym and marijuana layout
  --theme-gradient1: #01effc;
  --theme-gradient2: #485ff2;
}
@import 'theme/style';
@import 'theme/theme-dark';
@import 'theme/menu';
@import 'theme/responsive';
@import 'theme/custom';

// Toastr Css
@import '~ngx-toastr/toastr.css';

// Rating Scss
@import '~ngx-bar-rating/themes/br-default-theme';