@media only screen and (max-width: 767px) {
    header .logo-wrapper {
        padding: 0
    }

    .mobile-remove-br br {
        display: none
    }

    .mobile-remove-bg,
    .mobile-remove-bg>.elementor-column-wrap {
        background-image: none !important
    }

    .elementor-column.mobile-order-top {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }

    .search-table .search-button input[type^="submit"],
    .woocommerce-product-search button[type="submit"] {
        min-width: 0
    }

    .ts-mask {
        -webkit-clip-path: ellipse(200% 100% at 50% 0);
        clip-path: ellipse(200% 100% at 50% 0)
    }

    .header-v3 header .logo-wrapper {
        padding: 0
    }

    .mobile-app-style #ts-filter-widget-area .ts-sidebar-content,
    .mobile-app-style .style-filter-top .before-loop-wrapper #ts-filter-widget-area .ts-sidebar-content {
        padding-bottom: 70px
    }

    article.single-post .entry-format .thumbnail img {
        margin-bottom: 22px
    }

    article.single-post.no-featured-image .entry-meta-top {
        margin-top: 13px
    }

    .woocommerce .woocommerce-ordering .orderby ul,
    .product-per-page-form ul.perpage ul {
        width: 140px
    }

    #ts-filter-widget-area .before-loop-wrapper>.widget.product-filter-by-brand {
        position: relative;
        z-index: 1000
    }

    body.mobile-app-style:not(.ts_desktop):not(.page-template-blank-page-template) {
        padding-bottom: 46px
    }

    .elementor-section.elementor-section-stretched {
        width: 100vw !important;
        left: calc(-50vw + 50%) !important
    }

    .visible-phone {
        display: block !important
    }

    .hidden-phone,
    .sticky-wrapper.is-sticky .icon-menu-sticky-header.hidden-phone {
        display: none !important
    }

    .mobile-ts-aligncenter {
        text-align: center !important
    }

    .ts-header .container,
    footer .container,
    .layout-fullwidth .footer-container .container,
    .breadcrumb-title-wrapper .breadcrumb-content {
        padding-left: 15px;
        padding-right: 15px
    }

    .single-post>.single-related-wrapper:last-child:after {
        left: 15px;
        right: 15px
    }

    .footer-container .ts-list-of-product-categories-wrapper.columns-2 .list-categories ul,
    .footer-container .ts-list-of-product-categories-wrapper.columns-3 .list-categories ul,
    .footer-container .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0
    }

    #main>.page-container {
        padding-left: 15px;
        padding-right: 15px
    }

    #left-sidebar,
    #right-sidebar {
        margin-bottom: 50px
    }

    #main-content,
    #left-sidebar,
    #right-sidebar {
        width: 100%;
        padding-left: 0;
        padding-right: 0
    }

    #left-sidebar.ts-col-6 {
        padding-right: 0
    }

    #right-sidebar.ts-col-6 {
        padding-left: 0
    }

    #left-sidebar .widget-container .widget-title-wrapper .widget-title:not(:empty),
    #left-sidebar .widget-title-wrapper .widget-title:not(:empty),
    #right-sidebar .widget-container .widget-title-wrapper .widget-title:not(:empty),
    #right-sidebar .widget-title-wrapper .widget-title:not(:empty) {
        margin: 0
    }

    #left-sidebar .widget-container.mailchimp-subscription .widget-title-wrapper .widget-title:not(:empty),
    #right-sidebar .widget-container.mailchimp-subscription .widget-title-wrapper .widget-title:not(:empty),
    #left-sidebar .widget-container .widget-title-wrapper .block-control.active~.widget-title:not(:empty),
    #left-sidebar .widget-title-wrapper .block-control.active~.widget-title:not(:empty),
    #right-sidebar .widget-container .widget-title-wrapper .block-control.active~.widget-title:not(:empty),
    #right-sidebar .widget-title-wrapper .block-control.active~.widget-title:not(:empty) {
        margin-bottom: 10px
    }

    body.page article.page+.comments-area {
        min-height: 240px
    }

    body.page article.page .comments-area .commentlist {
        margin-bottom: 100px
    }

    body .elementor-section.elementor-section-boxed.elementor-section-stretched>.elementor-container {
        max-width: calc(100% - 30px)
    }

    body .mobile-menu-wrapper .ts-megamenu .elementor-section,
    body .mobile-menu-wrapper .ts-megamenu .elementor-section.elementor-section-stretched {
        width: 100% !important;
        max-width: calc(100vw - 40px) !important;
        left: 0 !important
    }

    body .mobile-menu-wrapper .ts-megamenu .elementor-section.elementor-section-boxed>.elementor-container,
    body .mobile-menu-wrapper .ts-megamenu .elementor-section.elementor-section-boxed.elementor-section-stretched>.elementor-container {
        max-width: 100%;
        margin: 0
    }

    .widget-title-wrapper a.block-control {
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: right;
        z-index: 2
    }

    #right-sidebar {
        margin-top: 64px;
        margin-bottom: 0
    }

    .single-post #right-sidebar {
        margin-top: 45px
    }

    #left-sidebar .widget-container,
    #right-sidebar .widget-container {
        border-width: 0;
        padding: 15px;
        margin-top: 8px;
        margin-bottom: 20px;
        border-width: 1px;
        border-style: solid
    }

    #left-sidebar .widget-container.widget_media_image,
    #right-sidebar .widget-container.widget_media_image {
        padding: 0;
        border-width: 0
    }

    #left-sidebar .widget-container .widget-title-wrapper .widget-title:empty,
    #left-sidebar .widget-title-wrapper .widget-title:empty,
    #right-sidebar .widget-container .widget-title-wrapper .widget-title:empty,
    #right-sidebar .widget-title-wrapper .widget-title:empty {
        min-height: 18px
    }

    #left-sidebar .widget-container.mailchimp-subscription .block-control,
    #right-sidebar .widget-container.mailchimp-subscription .block-control {
        display: none !important
    }

    #left-sidebar .widget-container.mailchimp-subscription .subscribe-widget,
    #right-sidebar .widget-container.mailchimp-subscription .subscribe-widget {
        display: block !important
    }

    #left-sidebar .product-filter-by-color ul,
    #right-sidebar .product-filter-by-color ul,
    #left-sidebar .product-filter-by-brand .product-filter-by-brand-wrapper>ul,
    #right-sidebar .product-filter-by-brand .product-filter-by-brand-wrapper>ul {
        margin-bottom: 5px
    }

    #left-sidebar .widget_text .textwidget>p,
    #right-sidebar .widget_text .textwidget>p {
        margin-bottom: 10px
    }

    #left-sidebar .woocommerce ul.product_list_widget,
    #right-sidebar .woocommerce ul.product_list_widget {
        padding-bottom: 5px
    }

    #left-sidebar .product-filter-by-brand>.product-filter-by-brand-wrapper,
    #right-sidebar .product-filter-by-brand>.product-filter-by-brand-wrapper,
    #left-sidebar .widget_rating_filter>ul,
    #right-sidebar .widget_rating_filter>ul,
    #left-sidebar .product-filter-by-color>ul,
    #right-sidebar .product-filter-by-color>ul {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .footer-container .ts-mailchimp-subscription-shortcode .widget-title-wrapper .widget-title:not(:empty) {
        margin-bottom: 20px
    }

    .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
        margin: 5px 1px 0
    }

    #right-sidebar .widget_text .textwidget>p[style="margin-bottom: 30px;"] {
        margin-bottom: 10px !important
    }

    #left-sidebar .widget_text .textwidget>p:first-child>img:first-child,
    #right-sidebar .widget_text .textwidget>p:first-child>img:first-child {
        margin-top: 5px
    }

    #left-sidebar .widget_text .textwidget>*:last-child,
    #right-sidebar .widget_text .textwidget>*:last-child {
        margin-bottom: 0
    }

    #left-sidebar .mc4wp-response,
    #right-sidebar .mc4wp-response {
        margin-top: 5px
    }

    .woocommerce .widget_price_filter form {
        padding-top: 11px
    }

    .woocommerce .widget_price_filter .price_slider_amount .price_label {
        margin-bottom: 10px
    }

    ul.blog-filter-bar {
        margin-bottom: 15px
    }

    .ts-product.ts-slider.partial-view.partial-left .owl-nav,
    .ts-product.ts-slider.partial-view.partial-right .owl-nav {
        left: 5px
    }

    .ts-product.ts-slider.partial-view.partial-left .owl-nav {
        right: 5px
    }

    .ts-portfolio-wrapper .filter-bar li,
    ul.blog-filter-bar li {
        padding: 0;
        margin-bottom: 5px
    }

    body .select2-container--default .select2-selection--single .select2-selection__arrow {
        margin-top: -14px
    }

    .mobile-order-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .mobile-2-columns ul {
        margin-left: -5px;
        margin-right: -5px
    }

    .mobile-2-columns ul li {
        width: 50%;
        float: left;
        padding-left: 5px;
        padding-right: 5px
    }

    .footer-container .ts-language-switcher ul.wpml-ls-sub-menu li,
    .footer-container .ts-currency-switcher ul li {
        line-height: 20px;
        padding: 4px 0
    }

    .admin-bar .elementor-section.elementor-section-height-full {
        height: calc(100vh - 46px)
    }

    .single #right-sidebar {
        margin-bottom: 50px
    }

    body .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
        width: 100%;
        max-width: 100%
    }

    body .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
        display: none
    }

    body .elementor-widget-image-box.ts-feature .elementor-image-box-wrapper,
    .footer-container .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
        text-align: left;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    body .elementor-widget-image-box.ts-feature .elementor-image-box-wrapper,
    .footer-container .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    body .elementor-element.ts-feature.elementor-position-left .elementor-image-box-img {
        margin-right: 14px !important
    }

    .logo-wrapper .normal-logo {
        display: none
    }

    .logo-wrapper .mobile-logo {
        display: block
    }

    .menu-center .logo-wrapper {
        width: auto
    }

    .shopping-cart-wrapper .dropdown-container,
    .my-account-wrapper .dropdown-container {
        display: none !important
    }

    .header-middle>.container,.header-middle>.container-fluid {
        padding-top: 12px;
        padding-bottom: 12px
    }

    div.header-right>*:not(:last-child) {
        margin-right: 0
    }

    html .header-v2 #page .vertical-categories-wrapper {
        padding-bottom: 50px
    }

    .header-v4 .header-middle .header-left>.ts-mobile-icon-toggle {
        padding: 8px 13px;
        border-width: 0
    }

    .header-v4 .header-middle .shopping-cart-wrapper {
        padding: 0px 5px 0px 0
    }

    .header-v4 .ts-header .header-middle div.header-right {
        padding-left: 0px
    }

    .header-v4 .header-middle .search-button.search-icon {
        min-width: 0;
        background: transparent !important
    }

    .header-v4 .header-middle .header-right .search-button.search-icon {
        border-width: 0
    }

    .header-v4 .header-middle .logo-wrapper {
        padding: 0 20px 0 0;
        text-align: left
    }

    .ts-header div.header-right .search-button.search-icon .icon {
        padding: 10px 0 10px 10px
    }

    .ts-header div.header-right .search-button.search-icon .icon:before {
        font-size: 18px
    }

    #group-icon-header .header-social-icon {
        text-align: center
    }

    .vertical-categories-wrapper {
        display: none
    }

    .header-v2 .ts-header .header-middle .header-right {
        padding-left: 0;
        margin-left: auto
    }

    .header-v2 .header-middle .header-right>.search-button.search-icon {
        padding-left: 15px
    }

    .vertical-categories-wrapper~* {
        transform: translateX(0);
        max-width: 100%
    }

    body:not(.mobile-app-style) .ts-header .my-wishlist-wrapper,body:not(.mobile-app-style) .ts-header .my-notificarion-wrapper,
    body:not(.mobile-app-style) .ts-header .shopping-cart-wrapper {
        display: block !important
    }

    body:not(.mobile-app-style) .ts-header .ts-mobile-icon-toggle {
        padding: 13px 0 13px 13px
    }

    body:not(.mobile-app-style):not(.header-v2) .ts-header .ts-mobile-icon-toggle {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    body:not(.mobile-app-style) .ts-header .ts-mobile-icon-toggle .icon {
        width: 20px;
        height: 20px
    }

    body:not(.mobile-app-style) .ts-header .ts-mobile-icon-toggle .icon:before {
        font-size: 18px;
        line-height: 22px
    }

    body:not(.mobile-app-style) .ts-header .search-button.search-icon {
        display: none !important
    }

    .header-v2:not(.mobile-app-style) header .logo-wrapper {
        padding: 0 10px 0 0
    }

    .header-v2:not(.mobile-app-style) header .logo-wrapper {
        padding: 0 10px 0 0
    }

    .header-v2:not(.mobile-app-style) .ts-header .header-middle .header-right {
        padding-left: 0;
        margin-left: auto
    }

    .header-v2:not(.mobile-app-style) .header-middle .header-right>* {
        padding: 0
    }

    .header-v2:not(.mobile-app-style) .header-middle .header-right>.shopping-cart-wrapper {
        padding-left: 10px
    }

    .header-v2 .header-middle .shopping-cart-wrapper .cart-control .cart-total {
        display: none
    }

    .header-v2 .ts-header .shopping-cart-wrapper:last-child .cart-control {
        padding-right: 10px
    }

    .mobile-menu-wrapper .mobile-menu>ul>li.empty-label.ts-megamenu:only-child .sub-menu {
        transform: translateX(0);
        padding: 20px
    }

    .footer-container .elementor-widget-wp-widget-nav_menu.menu-horizontal li:not(:last-child) {
        margin-right: 20px
    }

    #ts-mobile-button-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 46px;
        border-width: 1px 0 0 0;
        border-style: solid;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 99999
    }

    #ts-mobile-button-bottom>.shopping-cart-wrapper:last-child,
    #ts-mobile-button-bottom>.my-wishlist-wrapper:last-child {
        padding-right: 10px
    }

    #ts-mobile-button-bottom>div {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center
    }

    #ts-mobile-button-bottom>div .icon,
    #ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist,
    #ts-mobile-button-bottom .my-notification-wrapper i,
    #ts-mobile-button-bottom .my-account-wrapper .account-control>a,
    #ts-mobile-button-bottom .shopping-cart-wrapper .cart-control {
        width: 46px;
        height: 46px;
        display: block;
        line-height: 46px;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        color: inherit
    }

    #ts-mobile-button-bottom>div .icon:before,
    #ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist:before,
    #ts-mobile-button-bottom .my-notification-wrapper i:before,
    #ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .ic-cart:before,
    #ts-mobile-button-bottom .ts-tiny-account-wrapper .account-control>a:before {
        font-size: 22px;
        line-height: inherit
    }

    #ts-mobile-button-bottom .my-wishlist-wrapper>a .count-number,
    #ts-mobile-button-bottom .my-notification-wrapper>a .count-number,
    #ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .cart-number {
        right: 0;
        top: 3px
    }

    #ts-mobile-button-bottom a {
        text-decoration: none !important;
        color: inherit
    }

    .ts-mobile-icon-toggle {
        position: relative
    }

    .ts-mobile-icon-toggle .icon {
        display: inline-block;
        vertical-align: top;
        line-height: 0;
        transition: 350ms ease
    }

    .mobile-app-style #to-top {
        bottom: 47px
    }

    #group-icon-header .tab-mobile-menu li {
        background: #252B33
    }

    #group-icon-header .tab-mobile-menu li,
    #group-icon-header .close:after {
        color: #fff
    }

    #group-icon-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    body.menu-mobile-active #group-icon-header {
        height: 100%
    }

    #group-icon-header .ts-sidebar-content {
        width: 100%;
        left: 0;
        right: auto;
        padding: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow-y: auto;
        transform: translateX(-100%)
    }

    #group-icon-header.active .ts-sidebar-content {
        transform: translateX(0)
    }

    .admin-bar .ts-floating-sidebar .ts-sidebar-content {
        top: 46px
    }

    #group-icon-header .sidebar-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: hidden;
        height: calc(100% - 30px)
    }

    body.mobile-app-style #group-icon-header .sidebar-content {
        height: calc(100% - 65px)
    }

    #group-icon-header .tab-mobile-menu {
        margin: 0;
        display: table;
        width: 100%
    }

    #group-icon-header .tab-mobile-menu:after {
        display: table;
        clear: both;
        content: ""
    }

    #group-icon-header .tab-mobile-menu li {
        list-style: none;
        text-transform: uppercase;
        padding: 5px;
        text-align: center;
        position: relative;
        white-space: nowrap;
        display: table-cell;
        line-height: 36px;
        min-width: 120px
    }

    .mobile-menu-wrapper nav ul li>a {
        color: inherit
    }

    #group-icon-header .tab-mobile-menu li:first-child {
        padding-left: 30px
    }

    #group-icon-header .tab-mobile-menu li:last-child {
        padding-right: 30px
    }

    #group-icon-header .tab-mobile-menu li:first-child:last-child {
        width: 100%
    }

    #group-icon-header .mobile-menu-wrapper {
        position: relative;
        margin-top: -50px;
        margin-bottom: 0 !important;
        padding-top: 58px;
        height: 100%
    }

    #group-icon-header .tab-vertical-menu {
        display: none
    }

    #group-icon-header .mobile-menu-wrapper .menu-main-mobile,
    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper,
    #group-icon-header .tab-vertical-menu .content-wrapper {
        height: 100%;
        overflow-y: auto
    }

    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper .vertical-menu {
        position: static;
        display: block;
        width: 100% !important;
        height: 100%;
        padding: 0 20px
    }

    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper .vertical-menu,
    #group-icon-header .tab-vertical-menu .vertical-menu-wrapper:hover .vertical-menu {
        -webkit-animation-name: none;
        -moz-animation-name: none;
        animation-name: none
    }

    #group-icon-header .tab-vertical-menu .vertical-menu>ul>li {
        width: 100%
    }

    .vertical-menu>ul>li:first-child {
        border-top-width: 0;
        padding-top: 0
    }

    .vertical-menu ul.menu li .menu-icon {
        width: 50px
    }

    .mobile-menu-wrapper .vertical-menu .has-icon+span.ts-menu-drop-icon:not(.active) {
        top: 7px;
        width: 50px;
        height: 50px;
        line-height: 50px
    }

    .mobile-menu-wrapper .vertical-menu ul.menu li .menu-icon {
        margin-right: 10px
    }

    .mobile-menu-wrapper .vertical-menu-wrapper {
        position: static
    }

    .mobile-menu-wrapper nav.vertical-menu>ul>li {
        padding-left: 0;
        padding-right: 0
    }

    .ts-search-by-category .search-button:before {
        right: -10px
    }

    body.menu-mobile-active .ts-header .container,
    body.menu-mobile-active #main,
    body.menu-mobile-active #colophon {
        transform: translateX(0)
    }

    header .ts-mobile-icon-toggle .icon:before,
    header .shopping-cart-wrapper a>.ic-cart:before {
        font-size: 20px;
        line-height: 44px;
        display: inline-block
    }

    header .ts-mobile-icon-toggle .icon:before {
        font-size: 15px
    }

    body .mobile-menu-wrapper.ts-menu nav.mobile-menu {
        width: 100%;
        display: block;
        float: none;
        margin: 0;
        padding: 0
    }

    .mobile-menu-wrapper ul.menu li .menu-icon {
        margin-right: 5px
    }

    .mobile-menu-wrapper span.ts-menu-drop-icon {
        position: relative;
        top: 12px;
        left: auto;
        bottom: auto;
        text-align: center;
        right: -10px;
        z-index: 3;
        cursor: pointer;
        padding: 0;
        transform: none;
        width: 30px;
        float: right;
        transition: color 150ms ease 0s
    }

    body #group-icon-header .menu-title {
        margin: 0;
        padding: 15px 0;
        text-align: center;
        white-space: nowrap;
        line-height: 20px;
        position: relative
    }

    body #group-icon-header .menu-title:after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 20px;
        right: 20px;
        border-width: 1px 0 0;
        border-style: solid;
        z-index: 7
    }

    #group-icon-header .menu-title span {
        position: relative;
        z-index: 7;
        display: inline-block;
        padding: 0 40px
    }

    .mobile-menu-wrapper ul {
        margin: 0
    }

    .mobile-menu-wrapper ul.sub-menu {
        position: absolute;
        padding-top: 58px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        height: 100%;
        transform: translateX(-100%);
        display: block !important;
        transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        overflow: scroll
    }

    .mobile-menu-wrapper nav>ul>li>ul.sub-menu {
        top: 0
    }

    .mobile-menu-wrapper li.active .ts-menu-drop-icon.active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        z-index: 5;
        text-align: start;
        padding: 18px
    }

    .mobile-menu-wrapper nav>ul>li.active>.ts-menu-drop-icon.active {
        top: 46px
    }

    .mobile-menu-wrapper li.active>ul.sub-menu {
        transform: translateX(0)
    }

    .mobile-menu-wrapper nav>ul {
        margin: 0;
        padding: 0
    }

    .mobile-menu-wrapper nav>ul ul {
        margin: 0;
        padding: 0
    }

    .mobile-menu-wrapper nav>ul ul.sub-menu li:last-child>ul.sub-menu {
        margin-bottom: 0
    }

    .mobile-menu-wrapper nav ul>li {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        float: none
    }

    .mobile-menu-wrapper nav>ul>li,
    #group-icon-header .tab-vertical-menu>.content-wrapper,
    .mobile-menu-wrapper li.active>ul.sub-menu {
        padding-left: 20px;
        padding-right: 20px
    }

    .mobile-menu-wrapper .menu-sub-label {
        top: -5px;
        right: 0;
        transform: translateX(80%)
    }

    .mobile-menu-wrapper nav ul li>a {
        padding: 8px 0;
        display: inline-block;
        color: inherit;
        z-index: 1;
        position: relative
    }

    .mobile-menu-wrapper nav ul li>.shop-more {
        padding: 8px 0
    }

    .mobile-menu-wrapper nav ul li.has-line {
        padding-top: 16px
    }

    .mobile-menu-wrapper nav ul li.has-line:before {
        content: '';
        width: 23px;
        height: 1px;
        border-top: 1px solid #d9d9d9;
        display: block;
        margin-bottom: 8px
    }

    .mobile-menu-wrapper nav li a:hover {
        text-decoration: none
    }

    .mobile-menu-wrapper .vertical-menu>ul.menu li.ts-megamenu-fullwidth ul.sub-menu .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-4 .items .item,
    .mobile-menu-wrapper .vertical-menu>ul.menu li.ts-megamenu-fullwidth ul.sub-menu .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-3 .items .item {
        width: 50%
    }

    .mobile-menu-wrapper .ts-list-of-product-categories-wrapper.columns-2 .list-categories ul,
    .mobile-menu-wrapper .ts-list-of-product-categories-wrapper.columns-3 .list-categories ul,
    .mobile-menu-wrapper .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1
    }

    #group-icon-header .group-button-header:not(:empty) {
        padding: 20px 20px 0;
        margin-top: auto;
        position: relative;
        line-height: 18px
    }

    #group-icon-header .group-button-header .ts-tiny-account-wrapper.ts-style-text {
        text-align: center
    }

    #group-icon-header .group-button-header .ts-tiny-account-wrapper.ts-style-text .account-control {
        display: inline-block
    }

    #group-icon-header .group-button-header:not(:empty):before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        border-width: 1px 0 0;
        border-style: solid
    }

    .group-button-header>*:not(:last-child) {
        margin-bottom: 15px
    }

    .group-button-header>.language-currency {
        margin-top: -5px;
        text-align: center
    }

    .group-button-header .header-language,
    .group-button-header .header-currency {
        display: inline-block;
        vertical-align: middle;
        float: none
    }

    #group-icon-header .group-button-header a {
        color: inherit
    }

    .mobile-menu-wrapper *[class^="elementor-"] {
        position: static
    }

    body #group-icon-header .header-language a:hover,
    body #group-icon-header .header-language a:active,
    body #group-icon-header .header-language a:focus,
    body #group-icon-header .header-currency a:active,
    body #group-icon-header .header-currency a:focus,
    body #group-icon-header .header-currency a:hover {
        color: inherit
    }

    .ts-megamenu-container .elementor-widget-container>h5,
    .ts-megamenu-container .ts-list-of-product-categories-wrapper h3.heading-title {
        margin-bottom: 15px
    }

    .elementor-widget-wp-widget-nav_menu.columns-4 ul.menu,
    .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }

    body #group-icon-header .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
    body #group-icon-header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
    #group-icon-header .header-currency ul {
        padding: 20px 30px 26px 30px;
        top: auto;
        bottom: 100%;
        margin-bottom: 0;
        color: #000
    }

    #group-icon-header .wcml_currency_switcher>ul:before,
    #group-icon-header .wpml-ls-legacy-dropdown ul.wpml-ls-sub-menu:before,
    #group-icon-header .wpml-ls-item-legacy-dropdown-click ul.wpml-ls-sub-menu:before {
        top: 0;
        bottom: 6px
    }

    body #group-icon-header .wpml-ls-legacy-dropdown:hover .wpml-ls-sub-menu,
    body #group-icon-header .wpml-ls-legacy-dropdown:focus .wpml-ls-sub-menu,
    body #group-icon-header .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
    #group-icon-header .header-currency:focus ul,
    #group-icon-header .header-currency:hover ul {
        -webkit-transform-origin: bottom center;
        -moz-transform-origin: bottom center;
        -ms-transform-origin: bottom center;
        transform-origin: bottom center
    }

    .filter-widget-area-button {
        margin: 0 20px 20px 0
    }

    .woocommerce .before-loop-wrapper>.widget,
    .woocommerce .woocommerce-ordering,
    .woocommerce-page .woocommerce-ordering,
    .before-loop-wrapper>.product-per-page-form {
        width: 50%;
        margin: 0 !important
    }

    .woocommerce .before-loop-wrapper>.widget,
    .before-loop-wrapper>.product-per-page-form {
        text-align: right
    }

    #main-content.ts-col-18 .before-loop-wrapper>.widget select,
    #main-content.ts-col-18 .product-per-page-form ul.perpage .perpage-current>span:last-child,
    #main-content.ts-col-18 .woocommerce .woocommerce-ordering .orderby-current,
    .before-loop-wrapper>.widget select,
    .product-per-page-form ul.perpage .perpage-current>span:last-child,
    .woocommerce .woocommerce-ordering .orderby-current {
        width: 140px
    }

    .woocommerce #main-content.ts-col-18 .before-loop-wrapper>.widget,
    .woocommerce #main-content.ts-col-18 .woocommerce-ordering,
    .woocommerce-page #main-content.ts-col-18 .woocommerce-ordering,
    #main-content.ts-col-18 .before-loop-wrapper>.product-per-page-form {
        width: auto;
        margin: 0 20px 0 0 !important
    }

    .woocommerce #main-content.ts-col-18 .before-loop-wrapper>.widget,
    #main-content.ts-col-18 .before-loop-wrapper>.product-per-page-form {
        text-align: left
    }

    .product-per-page-form ul.perpage {
        text-align: left
    }

    .woocommerce-page .page-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .filter-widget-area .widget-title-wrapper a.block-control {
        display: none !important
    }

    .filter-widget-area .widget-container>*:not(.widget-title-wrapper) {
        display: block !important
    }

    .list-categories:after {
        left: 5px;
        right: 5px
    }

    .woocommerce.archive #main-content.show-filter-sidebar {
        padding-left: 0
    }

    .woocommerce.columns-12 .products .product:not(.product-category),
    .woocommerce.columns-11 .products .product:not(.product-category),
    .woocommerce.columns-10 .products .product:not(.product-category),
    .woocommerce.columns-9 .products .product:not(.product-category),
    .woocommerce.columns-8 .products .product:not(.product-category),
    .woocommerce.columns-7 .products .product:not(.product-category),
    .woocommerce.columns-6 .products .product:not(.product-category),
    .woocommerce.columns-5 .products .product:not(.product-category),
    .woocommerce.columns-4 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-12 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-11 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-10 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-9 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-8 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-7 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-6 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-5 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-4 .products .product:not(.product-category),
    .woocommerce.columns-12 .products .list-categories .product.product-category,
    .woocommerce.columns-11 .products .list-categories .product.product-category,
    .woocommerce.columns-10 .products .list-categories .product.product-category,
    .woocommerce.columns-9 .products .list-categories .product.product-category,
    .woocommerce.columns-8 .products .list-categories .product.product-category,
    .woocommerce.columns-7 .products .list-categories .product.product-category,
    .woocommerce.columns-6 .products .list-categories .product.product-category,
    .woocommerce.columns-5 .products .list-categories .product.product-category,
    .woocommerce.columns-4 .products .list-categories .product.product-category {
        width: 33.33333%
    }

    .woocommerce.ts-product-category-wrapper.style-default.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-5 .products .product.product-category,
    .woocommerce.columns-12 .products .product.product-category,
    .woocommerce.columns-11 .products .product.product-category,
    .woocommerce.columns-10 .products .product.product-category,
    .woocommerce.columns-9 .products .product.product-category,
    .woocommerce.columns-8 .products .product.product-category,
    .woocommerce.columns-7 .products .product.product-category,
    .woocommerce.columns-6 .products .product.product-category {
        width: 25%
    }

    .woocommerce.columns-12.category-style-2 .products .product.product-category,
    .woocommerce.columns-11.category-style-2 .products .product.product-category,
    .woocommerce.columns-10.category-style-2 .products .product.product-category,
    .woocommerce.columns-9.category-style-2 .products .product.product-category,
    .woocommerce.columns-8.category-style-2 .products .product.product-category,
    .woocommerce.columns-7.category-style-2 .products .product.product-category,
    .woocommerce.columns-6.category-style-2 .products .product.product-category,
    .woocommerce.columns-5.category-style-2 .products .product.product-category,
    .woocommerce.columns-4.category-style-2 .products .product.product-category,
    .woocommerce.columns-3.category-style-2 .products .product.product-category {
        width: 33.33333%
    }

    .ts-product-category-wrapper.woocommerce:not(.category-style-vertical) .products .product-category img {
        max-width: 80px
    }

    .ts-shop-result-count {
        margin-bottom: 0
    }

    .woocommerce div.product.product-style-1 .summary-column-2 {
        width: 100%;
        position: static;
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .woocommerce div.product.product-style-1 .product-top-section>.ts-delivery-note {
        margin-top: -30px;
        display: block
    }

    .woocommerce div.product.product-style-1 .summary-column-2 .ts-delivery-note {
        display: none
    }

    .woocommerce div.product.product-style-1 div.summary {
        padding-right: 0
    }

    .woocommerce div.product.product-style-1 .summary-column-2 {
        width: 100%;
        margin-top: 45px
    }

    .woocommerce div.product.product-style-3 div.woocommerce-product-gallery,
    .woocommerce div.product.product-style-3 div.summary {
        width: 100%;
        float: none;
        padding-left: 0
    }

    .woocommerce .cross-sells:not(:last-child):before,
    .woocommerce .up-sells:not(:last-child):before,
    .woocommerce .related:not(:last-child):before {
        bottom: -8px
    }

    .woocommerce div.product.tabs-in-summary .summary>h2,
    .woocommerce div.product.tabs-in-summary .summary>#reviews,
    .woocommerce div.product.tabs-in-summary .summary>.woocommerce-tabs {
        margin-right: 0
    }

    #commentform.comment-form .cr-upload-local-images label,
    .comment-form .cr-upload-local-images label {
        width: 100%;
        margin: 0 0 15px
    }

    .pagination-wrap ul.pagination,
    .woocommerce nav.woocommerce-pagination ul,
    .ts-pagination ul,
    .dokan-pagination-container .dokan-pagination {
        padding: 0 42px
    }

    .pagination-wrap ul.pagination>li>a.prev,
    .pagination-wrap ul.pagination>li>a.next {
        width: 40px;
        height: 28px;
        padding: 0;
        line-height: 26px
    }

    .dokan-pagination-container .dokan-pagination li:first-child a,
    .dokan-pagination-container .dokan-pagination li:last-child a,
    .woocommerce nav.woocommerce-pagination ul li a.next,
    .woocommerce nav.woocommerce-pagination ul li a.prev,
    .ts-pagination ul li a.prev,
    .ts-pagination ul li a.next {
        width: 40px;
        height: 28px;
        line-height: 0;
        font-size: 0;
        padding: 0;
        min-width: 0
    }

    .dokan-pagination-container .dokan-pagination li:first-child a:before,
    .dokan-pagination-container .dokan-pagination li:last-child a:after,
    .woocommerce nav.woocommerce-pagination ul li a.next:after,
    .ts-pagination ul li a.next:after,
    .woocommerce nav.woocommerce-pagination ul li a.prev:before,
    .ts-pagination ul li a.prev:before {
        font-size: 12px;
        line-height: 26px;
        margin: 0
    }

    .dokan-pagination-container .dokan-pagination li:first-child a:before,
    .woocommerce nav.woocommerce-pagination ul li a.prev:before,
    .ts-pagination ul li a.prev:before {
        content: '<'
    }

    .dokan-pagination-container .dokan-pagination li:last-child a:after,
    .woocommerce nav.woocommerce-pagination ul li a.next:after,
    .ts-pagination ul li a.next:after {
        content: '>'
    }

    #main-content.ts-col-18~#right-sidebar {
        margin-top: 10px
    }

    #main-content.ts-col-12 .ts-pagination {
        padding-bottom: 0
    }

    .blog-template #main-content.ts-col-18~#right-sidebar {
        margin-top: 0
    }

    .ts-pagination ul li {
        padding-bottom: 0
    }

    .woocommerce-tabs #tab-description .ts-feature {
        margin: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .woocommerce-tabs #tab-description .ts-feature>div {
        padding: 0
    }

    .woocommerce-tabs #tab-description .ts-feature>div:not(:last-child) {
        padding-bottom: 34px;
        margin-bottom: 40px;
        border-width: 0 0 1px;
        border-style: solid
    }

    .product-style-3 .ts-product-brand-info .brand-info-wrapper .brand-info {
        width: 100%
    }

    .product-style-3 .ts-product-brand-info .brand-info-wrapper>img {
        margin: 0 0 15px
    }

    .woocommerce div.product.product-style-3 .ts-product-brand-info .description {
        max-width: 100%
    }

    .woocommerce div.product .woocommerce-tabs .panel table tr:not(:last-child):after {
        width: calc(100vw - 30px);
        left: calc(50% - 50vw + 15px)
    }

    .woocommerce div.product.product-style-5 div.woocommerce-product-gallery,
    .woocommerce div.product.product-style-5 div.summary {
        width: 100%;
        float: none;
        padding-left: 0
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
        margin-bottom: 21px;
        margin-top: 0
    }

    .woocommerce #review_form #respond .comment-form {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce #review_form #respond .comment-form>* {
        margin: 0 0 10px;
        padding-left: 0;
        padding-right: 0
    }

    .woocommerce #review_form #respond .comment-form>.comment-form-cookies-consent {
        margin: 15px 0 24px
    }

    .woocommerce #review_form #respond .comment-form>.comment-form-author,
    .woocommerce #review_form #respond .comment-form>.comment-form-email {
        width: 100%
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
        width: 100%;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-bottom: 0
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text p.meta .woocommerce-review__author {
        margin-top: 7px;
        margin-bottom: 0
    }

    .woocommerce #reviews #comments ol.commentlist li .comment-text .description {
        width: 100%
    }

    .woocommerce-account .woocommerce-MyAccount-content {
        padding: 25px 20px 30px
    }

    .woocommerce #customer_login form.login .button,
    .woocommerce #customer_login form.register .button {
        min-width: 0
    }

    .woocommerce ul.order_details {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 12px 20px
    }

    .woocommerce ul.order_details li {
        border-width: 0 0 1px;
        width: 100%;
        text-align: left;
        padding: 8px 0
    }

    .woocommerce ul.order_details li strong {
        display: inline-block
    }

    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
        margin-left: -5px;
        margin-right: -5px
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row,
    .woocommerce-shipping-fields__field-wrapper>p.form-row {
        margin-left: 5px;
        margin-right: 5px
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-last,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-first,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-last,
    .woocommerce-billing-fields__field-wrapper>#billing_postcode_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_postcode_field,
    .woocommerce-billing-fields__field-wrapper>#billing_city_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_city_field {
        width: calc(50% - 10px)
    }

    .wpcf7 form div.columns-3 {
        margin-left: 0;
        margin-right: 0
    }

    .wpcf7 form div.columns-3>label {
        width: 100%;
        float: none;
        padding: 0
    }

    body.search.search-no-results .search-wrapper {
        max-width: 80%
    }

    body.search.search-no-results .alert p {
        font-size: 120%
    }

    body.search.search-no-results .alert {
        padding-bottom: 20px !important
    }

    .ts-floating-sidebar .woocommerce ul.product_list_widget li {
        margin-bottom: 10px
    }

    .intro-main-image.elementor-widget-image img {
        max-width: 100%
    }

    .has-filters .filter-bar {
        font-size: 16px
    }

    .intro-filter-items .filter-item {
        width: 33.33333%
    }

    .columns-0 .list-posts article:nth-child(2),
    .columns-0 .list-posts article,
    .columns-2 .list-posts article,
    .ts-blogs.columns-2 .items article,
    .columns-3 .list-posts article,
    .ts-blogs.columns-3 .items article {
        width: 100%
    }

    .list-posts article .entry-content>*:not(:last-child),
    .ts-blogs article .entry-content>*:not(:last-child) {
        margin-bottom: 13px
    }

    .entry-author {
        padding: 20px
    }

    .entry-author .author-avatar {
        width: 80px;
        padding-right: 10px
    }

    .commentlist .comment-detail .comment-meta,
    .commentlist .comment-detail .comment-text {
        width: 100%;
        float: none
    }

    .commentlist li.comment .comment-detail {
        padding: 24px 0
    }

    .commentlist li.comment .comment-actions {
        top: 28px
    }

    .commentlist li.comment .comment-meta,
    .commentlist .comment-detail .comment-meta {
        padding-right: 90px
    }

    .commentlist .comment-detail .comment-meta {
        margin-bottom: 10px
    }

    .commentlist .comment-detail .comment-text {
        width: 100%;
        padding-right: 0
    }

    .comment-meta span {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px
    }

    .commentlist li.comment .avatar+.comment-meta {
        margin-top: 4px
    }

    .commentlist li #comment-wrapper {
        padding-left: 0
    }

    .comment-meta .author+.date-time {
        width: auto
    }

    #commentform .info-wrapper {
        margin: 0 -5px
    }

    #commentform .info-wrapper>p {
        padding: 0 5px
    }

    .item-wrapper .portfolio-meta>*:not(:last-child) {
        margin-bottom: 8px
    }

    .list-posts article,
    .ts-blogs .items article {
        margin-bottom: 50px;
        padding-bottom: 50px
    }

    .page-container:not(.columns-0):not(.columns-1):not(.columns-2):not(.columns-3) .list-posts article:not(:last-child) {
        padding-bottom: 50px
    }

    .ts-shortcode.ts-portfolio-wrapper .load-more-wrapper {
        margin: 48px 0 40px
    }

    .list-posts article.sticky {
        padding: 30px !important
    }

    .single-portfolio>.entry-main {
        margin-bottom: 45px
    }

    body.page .page-container #primary>article.page {
        margin-top: 0
    }

    .entry-meta-middle .author>img,
    .entry-meta-bottom .author>img,
    .columns-0 .list-posts article:nth-child(2) .entry-meta-middle .author>img,
    .columns-0 .list-posts article:nth-child(2) .entry-meta-bottom .author>img,
    .columns-0 .list-posts article:not(:nth-child(1)):not(:nth-child(2)) .entry-meta-middle .author>img,
    .columns-0 .list-posts article:not(:nth-child(1)):not(:nth-child(2)) .entry-meta-bottom .author>img,
    .columns-3 .list-posts article .entry-meta-middle .author>img,
    .columns-3 .list-posts article .entry-meta-bottom .author>img {
        width: 22px;
        height: 22px
    }

    .woocommerce .woocommerce-order,
    .woocommerce .woocommerce-customer-details {
        margin-bottom: 42px
    }

    body .ts-popup-modal .product-360-container {
        width: 200px
    }

    .threesixty .nav_bar a {
        width: 30px;
        height: 30px;
        margin: 3px 0
    }

    .threesixty .nav_bar a:before {
        font-size: 12px;
        line-height: 30px
    }

    body .ts-popup-modal .add-to-cart-popup-container {
        width: 70%;
        padding: 20px
    }

    .add-to-cart-popup-content .heading {
        margin-bottom: 10px
    }

    .add-to-cart-popup-content .item .product-image {
        width: 30%
    }

    .add-to-cart-popup-content .item .product-meta {
        width: calc(70% - 30px)
    }

    .column-tabs .list-categories {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 20px
    }

    .style-tabs-default.only-child .column-tabs .list-categories {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .style-tabs-default .column-tabs .shop-more,
    .style-tabs-default.only-child .column-tabs .shop-more {
        margin-top: 13px;
        margin-left: 0
    }

    .column-tabs .list-categories>* {
        width: 100%;
        margin: 0
    }

    .elementor-col-20 .ts-banner .box-content {
        max-width: calc(100% - 30px)
    }

    .left-top .box-content {
        left: 15px;
        top: 15px
    }

    .left-bottom .box-content {
        left: 15px;
        bottom: 20px
    }

    .left-center .box-content {
        left: 15px
    }

    .right-top .box-content {
        right: 15px;
        top: 15px
    }

    .right-bottom .box-content {
        right: 15px;
        bottom: 20px
    }

    .right-center .box-content {
        right: 15px
    }

    .center-top .box-content {
        left: 15px;
        right: 15px;
        top: 15px
    }

    .center-bottom .box-content {
        left: 15px;
        right: 15px;
        bottom: 20px
    }

    .center-center .box-content {
        left: 15px;
        right: 15px
    }

    .ts-banner .box-content .banner-text strong {
        font-size: 120%
    }

    .ts-product-deals-wrapper .shortcode-heading-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ts-product-deals-wrapper .shortcode-heading-wrapper>*:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px
    }

    .ts-portfolio-wrapper .portfolio-meta,
    .ts-portfolio-wrapper .portfolio-meta-inner {
        padding: 15px
    }

    .ts-product-brand-wrapper.style-2 .item {
        padding: 18px 0
    }

    .ts-portfolio-wrapper.columns-2 .item,
    .ts-portfolio-wrapper.columns-3 .item,
    .ts-portfolio-wrapper.columns-4 .item {
        width: 100%;
        float: none
    }

    #ts-search-sidebar .ts-sidebar-content {
        bottom: 0
    }

    .ts-floating-sidebar .ts-search-result-container {
        padding: 30px 0
    }

    .woocommerce.main-content-fullwidth #tab-more_seller_product .products .product,
    .woocommerce.layout-fullwidth #tab-more_seller_product .products .product {
        width: 33.33333%
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

    .woocommerce div.product.product-style-5:not(.product-style-3):not(.product-style-4) div.images .flex-control-thumbs li,
    .woocommerce div.product.product-style-3 div.images .flex-control-thumbs li {
        width: 16.66666%
    }
}

@media only screen and (max-width: 670px) {

    .ts-logo-slider-wrapper:not(.ts-slider).columns-6 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-5 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-4 .item {
        width: 33.333333%
    }
}

@media only screen and (max-width: 570px) {

    .woocommerce.ts-product-category-wrapper.style-icon.columns-8 .products .product,
    .woocommerce.ts-product-category-wrapper.style-icon.columns-7 .products .product,
    .woocommerce.ts-product-category-wrapper.style-icon.columns-6 .products .product {
        width: 25%
    }

    .ts-store-notice .container {
        min-height: 0;
        padding-top: 5px;
        padding-bottom: 5px
    }

    .ts-store-notice .divider {
        width: 100%;
        height: 1px;
        min-height: 0;
        opacity: 0;
        visibility: hidden
    }

    .yith-wcwl-share .yith-wcwl-after-share-section,
    .yith-wcwl-share .yith-wcwl-after-share-section input.copy-target {
        text-align: left
    }

    .yith-wcwl-share .yith-wcwl-after-share-section {
        margin-top: 10px
    }

    body .elementor-image-gallery .gallery-columns-4 .gallery-item,
    body .elementor-image-gallery .gallery-columns-5 .gallery-item,
    body .elementor-image-gallery .gallery-columns-6 .gallery-item,
    body .elementor-image-gallery .gallery-columns-7 .gallery-item,
    body .elementor-image-gallery .gallery-columns-8 .gallery-item,
    body .elementor-image-gallery .gallery-columns-9 .gallery-item {
        width: 33.333333% !important
    }

    #main-content.ts-col-18 .before-loop-wrapper>.widget select,
    #main-content.ts-col-18 .product-per-page-form ul.perpage .perpage-current>span:last-child,
    #main-content.ts-col-18 .woocommerce .woocommerce-ordering .orderby-current,
    .before-loop-wrapper>.widget select,
    .product-per-page-form ul.perpage .perpage-current>span:last-child,
    .woocommerce .woocommerce-ordering .orderby-current {
        width: 110px
    }
}

@media only screen and (max-width: 500px) {

    .ts-team-members:not(.ts-slider) .item.ts-col-4,
    .ts-team-members:not(.ts-slider) .item.ts-col-8,
    .ts-team-members:not(.ts-slider) .item.ts-col-12,
    .ts-team-members:not(.ts-slider) .item.ts-col-6 {
        width: 100%
    }
}

@media only screen and (max-width: 480px) {
    .elementor-widget ul.elementor-icon-list-items.elementor-inline-items {
        margin: 0 !important;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .ts-floating-sidebar.active .ts-sidebar-content {
        width: 100%
    }

    .woocommerce.columns-12 .products .product:not(.product-category),
    .woocommerce.columns-11 .products .product:not(.product-category),
    .woocommerce.columns-10 .products .product:not(.product-category),
    .woocommerce.columns-9 .products .product:not(.product-category),
    .woocommerce.columns-8 .products .product:not(.product-category),
    .woocommerce.columns-7 .products .product:not(.product-category),
    .woocommerce.columns-6 .products .product:not(.product-category),
    .woocommerce.columns-5 .products .product:not(.product-category),
    .woocommerce.columns-4 .products .product:not(.product-category),
    .woocommerce.columns-3 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-12 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-11 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-10 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-9 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-8 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-7 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-6 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-5 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-4 .products .product:not(.product-category),
    #main-content.show-filter-sidebar .woocommerce.columns-3 .products .product:not(.product-category) {
        width: 50%
    }

    .woocommerce.ts-product-category-wrapper.style-default.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-5 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-4 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-3 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-5 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-4 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-icon-background.columns-3 .products .product.product-category {
        width: 100%
    }

    #main-content.ts-col-18 .woocommerce.columns-12 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-11 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-10 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-9 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-8 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-7 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-6 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-5 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-4 .products .product:not(.product-category),
    #main-content.ts-col-18 .woocommerce.columns-3 .products .product:not(.product-category) {
        width: 50%
    }

    .ts-floating-sidebar .woocommerce ul.product_list_widget li,
    .woocommerce.ts-search-result-container ul.product_list_widget li {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0
    }

    #ts-filter-widget-area .ts-sidebar-content,
    .style-filter-top .before-loop-wrapper #ts-filter-widget-area .ts-sidebar-content {
        width: 100% !important
    }

    #ts-search-sidebar .ts-sidebar-content {
        padding-left: 20px;
        padding-right: 20px
    }

    .woocommerce .woocommerce-ordering .orderby ul,
    .product-per-page-form ul.perpage ul {
        width: 130px
    }

    .woocommerce #main-content.ts-col-18 .woocommerce-ordering,
    .woocommerce-page #main-content.ts-col-18 .woocommerce-ordering,
    #main-content.ts-col-18 .before-loop-wrapper>.product-per-page-form {
        width: 50%;
        margin: 0 !important
    }

    .woocommerce #main-content.ts-col-18 .before-loop-wrapper>.widget {
        width: 100%;
        margin: 0 0 10px !important
    }

    #main-content.ts-col-18 .product-per-page-form {
        text-align: right
    }

    .woocommerce div.product div.images {
        display: block
    }

    .woocommerce div.product div.images .flex-control-thumbs {
        width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 15px -1px 0
    }

    .woocommerce div.product div.images .flex-control-thumbs li {
        width: 20%;
        padding: 15px 1px 0;
        margin: 0
    }

    #ts-product-360-modal.ts-popup-modal .close {
        right: 3px
    }

    .woocommerce.main-content-fullwidth #tab-more_seller_product .products .product,
    .woocommerce.layout-fullwidth #tab-more_seller_product .products .product {
        width: 50%
    }

    .list-posts .gallery .gallery-item {
        width: 100% !important
    }

    .list-posts .gallery .gallery-item img {
        width: 100%
    }

    .elementor-widget-wp-widget-nav_menu.columns-3 ul.menu,
    .elementor-widget-wp-widget-nav_menu.columns-4 ul.menu,
    .ts-list-of-product-categories-wrapper.columns-3 .list-categories ul,
    .ts-list-of-product-categories-wrapper.columns-4 .list-categories ul {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2
    }

    .ts-store-notice>.container {
        padding-right: 30px
    }

    .ts-store-notice .close {
        top: 7px
    }

    .header-v4 .header-middle .shopping-cart-wrapper .cart-control .cart-total {
        display: none
    }

    .footer-container .elementor-column:not(.elementor-col-100) .ts-mailchimp-subscription-shortcode .widget-title-wrapper .widget-title {
        max-width: 100%
    }

    .social-icons .list-icons {
        margin: 0 -3px
    }

    .social-icons .list-icons li {
        margin: 0 3px
    }

    .footer-container .elementor-widget-wp-widget-nav_menu ul.menu li {
        margin: 0
    }

    .woocommerce.columns-12 .products .product.product-category,
    .woocommerce.columns-11 .products .product.product-category,
    .woocommerce.columns-10 .products .product.product-category,
    .woocommerce.columns-9 .products .product.product-category,
    .woocommerce.columns-8 .products .product.product-category,
    .woocommerce.columns-7 .products .product.product-category,
    .woocommerce.columns-6 .products .product.product-category,
    .woocommerce.columns-5 .products .product.product-category,
    .woocommerce.columns-4 .products .product.product-category {
        width: 33.333333%
    }

    .woocommerce.columns-8 .products .product,
    .woocommerce.columns-7 .products .product,
    .woocommerce.columns-6 .products .product,
    .woocommerce.columns-5 .products .product,
    .woocommerce.columns-4 .products .product,
    .woocommerce.columns-3 .products .product,
    .style-tabs-vertical .woocommerce.columns-8 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-7 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-6 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-5 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-4 .products .product:not(.product-category),
    .style-tabs-vertical .woocommerce.columns-3 .products .product:not(.product-category),
    .woocommerce.columns-12 .products .list-categories .product.product-category,
    .woocommerce.columns-11 .products .list-categories .product.product-category,
    .woocommerce.columns-10 .products .list-categories .product.product-category,
    .woocommerce.columns-9 .products .list-categories .product.product-category,
    .woocommerce.columns-8 .products .list-categories .product.product-category,
    .woocommerce.columns-7 .products .list-categories .product.product-category,
    .woocommerce.columns-6 .products .list-categories .product.product-category,
    .woocommerce.columns-5 .products .list-categories .product.product-category,
    .woocommerce.columns-4 .products .list-categories .product.product-category,
    .woocommerce.columns-3 .products .list-categories .product.product-category,
    .woocommerce #tab-more_seller_product .products .product,
    .woocommerce.main-content-fullwidth #tab-more_seller_product .products .product,
    .woocommerce.layout-fullwidth #tab-more_seller_product .products .product {
        width: 50%
    }

    .woocommerce.main-products.style-column-list .products .list-categories .product.product-category {
        width: 100%
    }

    .woocommerce.main-products.style-column-list .product .price del+ins {
        margin-right: 5px
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper {
        width: 33%
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        width: 45%;
        padding-right: 25px
    }

    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2>*,
    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2 .quantity {
        margin-bottom: 5px
    }

    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2 .quantity,
    .main-products.style-column-list .products .product:not(.product-category) .meta-wrapper-2 .product-group-button-meta {
        width: 100%
    }

    .main-products.style-column-list .product-group-button-meta>div.loop-add-to-cart>a.button {
        padding: 0 5px !important;
        width: 100% !important;
        min-width: 0 !important;
        margin: 0 !important
    }

    .woocommerce-cart article .woocommerce .cart-collaterals,
    .woocommerce-checkout #order_review {
        padding: 30px
    }

    .woocommerce>form.checkout #order_review_heading {
        margin: 25px 0 0
    }

    .woocommerce>form.checkout #order_review {
        padding: 60px 30px 30px;
        margin-top: -49px
    }

    .woocommerce div.product .yith-wfbt-section .yith-wfbt-form .yith-wfbt-images td:not(.image_plus) {
        min-width: 33.333333%
    }

    .woocommerce div.product.product-style-1:not(.product-style-3):not(.product-style-4) div.images .flex-control-thumbs li {
        width: 20%
    }

    ul.features {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0
    }

    .woocommerce div.product.reviews-grid #reviews #comments .commentlist {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0
    }

    .woocommerce #tab-more_seller_product .products .product {
        width: 50%
    }

    ul.features li {
        padding-top: 15px
    }

    .woocommerce.product-label-rectangle div.product .images .product-label>span.onsale,
    .woocommerce.product-label-rectangle div.product .images .product-label>span {
        font-size: 12px;
        line-height: 22px !important;
        min-width: 50px
    }

    .product-style-3 .ts-product-brand-info .brand-info-wrapper,
    .woocommerce div.product.product-style-3 .product-images-summary {
        padding: 30px 20px
    }

    .woocommerce div.product.product-style-3 .ts-delivery-note+.yith-wfbt-section {
        margin-top: 50px
    }

    .woocommerce div.product.product-style-3 .ads-banner,
    #main-content.ts-col-24 div.product.product-style-3 .ads-banner {
        width: 100%;
        padding-left: 0;
        text-align: center
    }

    .woocommerce div.product.product-style-3 .product-top-section .ts-product-brand-info {
        width: 100%
    }

    .woocommerce div.product.product-style-4 .product-images-summary {
        width: 100%;
        padding: 20px
    }

    .load-more-wrapper,
    .woocommerce .woocommerce-cart-form,
    .woocommerce-checkout #customer_details,
    .woocommerce-cart article .woocommerce .cart-collaterals,
    .woocommerce-checkout #order_review,
    .woocommerce #customer_login,
    .woocommerce>form.checkout #customer_details,
    .woocommerce>form.checkout #order_review,
    .yith_wcwl_wishlist_footer .yith-wcwl-share,
    .blog-template #left-sidebar,
    .blog-template #right-sidebar,
    .ts-pagination,
    .woocommerce .after-loop-wrapper:not(:last-child),
    .woocommerce.archive #main>.page-container,
    .single-portfolio .related-portfolios,
    .woocommerce div.product.tabs-in-summary .woocommerce.yith-wfbt-section,
    .woocommerce-account .woocommerce-MyAccount-content,
    .ts-delivery-note,
    .woocommerce div.product.product-style-4 .product-top-section>.ts-product-brand-info {
        margin-bottom: 50px
    }

    .woocommerce div.product>.ts-product-in-category-tab-wrapper {
        padding-bottom: 50px
    }

    .woocommerce-tabs {
        padding-top: 45px;
        padding-bottom: 50px
    }

    .woocommerce div.product .ts-instagram-shortcode {
        padding: 45px 0 50px
    }

    .woocommerce div.product .ts-mailchimp-subscription-shortcode {
        padding: 50px 0 40px
    }

    .ts-product-brand-info ul.product_list_widget li .cart-item-wrapper,
    .woocommerce .ts-product-brand-info ul.product_list_widget li .cart-item-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .ts-product-brand-info ul.product_list_widget li .meta-wrapper:last-child {
        margin-left: 0;
        padding-left: 0;
        text-align: left
    }

    .ts-product-brand-info ul.product_list_widget li .price,
    .woocommerce .ts-product-brand-info ul.product_list_widget li .price {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin: 8px 0 0
    }

    .ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li:not(:last-child) {
        padding-right: 20px
    }

    .woocommerce #customer_login .col-1,
    .woocommerce #customer_login .col-2,
    .woocommerce-page #customer_login .col-1,
    .woocommerce-page #customer_login .col-2 {
        width: 100%;
        float: none
    }

    .woocommerce #customer_login .col-2,
    .woocommerce-page #customer_login .col-2 {
        border-width: 1px 0 0
    }

    .woocommerce #customer_login,
    .woocommerce form.track_order,
    .woocommerce form.woocommerce-ResetPassword.lost_reset_password {
        max-width: 100%
    }

    body .wishlist_table.mobile li {
        width: calc(100% - 2px)
    }

    .woocommerce #content table.cart td.actions .coupon,
    .woocommerce table.cart td.actions .coupon,
    .woocommerce-page #content table.cart td.actions .coupon,
    .woocommerce-page table.cart td.actions .coupon {
        padding-bottom: 10px;
        float: none;
        width: 100%
    }

    .woocommerce-cart table.cart td.actions:after {
        content: '';
        clear: both;
        display: table
    }

    .woocommerce-cart table.cart td.actions>.button {
        width: calc(50% - 5px) !important;
        float: left;
        display: block
    }

    .woocommerce-cart table.cart td.actions>.button.empty-cart-button {
        margin-left: 10px
    }

    .woocommerce table.shop_table.cart td.actions {
        text-align: start !important
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item {
        margin-bottom: 10px;
        padding: 15px 35px 15px 15px
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td {
        padding: 4px 0;
        width: calc(100% - 110px)
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-thumbnail {
        width: 110px;
        padding: 0 15px 0 0
    }

    .woocommerce-cart table.shop_table.shop_table_responsive.cart tr.cart_item td.product-remove {
        width: 26px;
        right: 5px
    }

    .woocommerce table.shop_table_responsive.cart tr td:not(.product-subtotal)::before,
    .woocommerce-page table.shop_table_responsive.cart tr td:not(.product-subtotal)::before {
        display: none
    }

    .woocommerce-cart table.cart td.actions .coupon .input-text {
        min-width: 0
    }

    .woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-subtotal {
        clear: both;
        padding-top: 10px;
        padding-bottom: 0;
        margin-top: 17px;
        border-width: 1px 0 0;
        border-style: dotted;
        width: calc(100% + 15px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .woocommerce form.checkout_coupon,
    .woocommerce .checkout-login-coupon-wrapper form.login {
        padding: 20px
    }

    .woocommerce .checkout-login-coupon-wrapper .checkout_coupon .form-row-first {
        width: 100%
    }

    .woocommerce .checkout-login-coupon-wrapper .checkout_coupon .form-row-last {
        width: 100%;
        min-width: 0;
        margin-left: 0;
        margin-top: 10px
    }

    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce form .form-row-first,
    .woocommerce form .form-row-last,
    .woocommerce-page form .form-row-last,
    .woocommerce-page form .form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-last,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-first,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-last,
    .woocommerce-billing-fields__field-wrapper>#billing_postcode_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_postcode_field,
    .woocommerce-billing-fields__field-wrapper>#billing_city_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_city_field,
    .woocommerce-billing-fields__field-wrapper>p.form-row,
    .woocommerce-shipping-fields__field-wrapper>p.form-row {
        width: 100%;
        margin-left: 0;
        margin-right: 0
    }

    body.search.search-no-results .search-wrapper {
        max-width: 100%
    }

    div.wpcf7 .ts-row .ts-column-left,
    div.wpcf7 .ts-row .ts-column-right {
        float: none;
        margin-right: 0;
        width: 100%
    }

    div.wpcf7 .ts-row .ts-column-left {
        margin-bottom: 10px
    }

    body.error404 #primary>article div.not-found h1 {
        font-size: 100px;
        line-height: 1
    }

    body.error404 #main>.page-container {
        padding-top: 22px
    }

    body.error404 .not-found {
        margin-bottom: 50px
    }

    .intro-filter-items .filter-item {
        width: 50%
    }

    #commentform .info-wrapper {
        margin: 0
    }

    #commentform .info-wrapper>p {
        width: 100%;
        float: none;
        padding: 0
    }

    .commentlist #commentform p {
        margin-bottom: 20px
    }

    .post-password-form>p>label {
        display: block
    }

    .post-password-form>p>label>input {
        min-width: 0;
        width: 100%
    }

    .post-password-form>p>label+input[type="submit"] {
        width: 100%;
        min-width: 0;
        margin-left: 0;
        display: block;
        top: 0
    }

    .wp-block-search .wp-block-search__inside-wrapper {
        flex-direction: column
    }

    .wp-block-search .wp-block-search__button {
        width: 100%;
        min-width: 0;
        margin: 10px 0 0
    }

    .wp-block-search .wp-block-search__input {
        width: 100%;
        min-width: 0
    }

    body .ts-popup-modal .product-360-container {
        width: calc(85% - 50px)
    }

    .add-to-cart-popup-content .item:after {
        content: '';
        clear: both;
        display: table
    }

    .add-to-cart-popup-content .action .button {
        width: 100%;
        min-width: 0
    }

    .add-to-cart-popup-content .item .product-image {
        width: 40%;
        margin-right: 20px
    }

    .add-to-cart-popup-content .item .product-meta {
        width: calc(60% - 20px)
    }

    .column-tabs .list-categories {
        background-position: 25% 0
    }

    .ts-mailchimp-subscription-shortcode .mc4wp-form .subscribe-email input[type="email"] {
        -webkit-box-flex: none;
        -ms-flex: none;
        flex: none;
        margin: 0 0 10px
    }

    .ts-mailchimp-subscription-shortcode .mc4wp-form .subscribe-email .button {
        width: 100%
    }

    .ts-instagram-wrapper.columns-4 .item,
    .ts-instagram-wrapper.columns-5 .item,
    .ts-instagram-wrapper.columns-6 .item {
        width: 50%
    }

    .ts-instagram-wrapper.columns-4 .item:nth-child(4n+1),
    .ts-instagram-wrapper.columns-5 .item:nth-child(5n+1),
    .ts-instagram-wrapper.columns-6 .item:nth-child(6n+1) {
        clear: none
    }

    .ts-instagram-wrapper.columns-4 .item:nth-child(2n+1),
    .ts-instagram-wrapper.columns-5 .item:nth-child(2n+1),
    .ts-instagram-wrapper.columns-6 .item:nth-child(2n+1) {
        clear: both
    }

    .woocommerce.ts-product-category-wrapper.style-default.columns-8 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-7 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-6 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-4 .products .product.product-category,
    .woocommerce.ts-product-category-wrapper.style-default.columns-5 .products .product.product-category {
        width: 33.3333%
    }

    body .elementor-image-gallery .gallery-columns-4 .gallery-item,
    body .elementor-image-gallery .gallery-columns-5 .gallery-item,
    body .elementor-image-gallery .gallery-columns-6 .gallery-item,
    body .elementor-image-gallery .gallery-columns-7 .gallery-item,
    body .elementor-image-gallery .gallery-columns-8 .gallery-item,
    body .elementor-image-gallery .gallery-columns-9 .gallery-item {
        width: 50% !important
    }

    .woocommerce.columns-12.category-style-2 .products .product.product-category,
    .woocommerce.columns-11.category-style-2 .products .product.product-category,
    .woocommerce.columns-10.category-style-2 .products .product.product-category,
    .woocommerce.columns-9.category-style-2 .products .product.product-category,
    .woocommerce.columns-8.category-style-2 .products .product.product-category,
    .woocommerce.columns-7.category-style-2 .products .product.product-category,
    .woocommerce.columns-6.category-style-2 .products .product.product-category,
    .woocommerce.columns-5.category-style-2 .products .product.product-category,
    .woocommerce.columns-4.category-style-2 .products .product.product-category,
    .woocommerce.columns-3.category-style-2 .products .product.product-category {
        width: 50%
    }

    .ts-product-category-wrapper.woocommerce.category-style-2 .products .product-category img {
        max-width: 60px
    }

    .ts-product.style-tabs-vertical.style-tabs-vertical-banner .banners {
        display: block
    }

    .ts-product.style-tabs-vertical.style-tabs-vertical-banner .banners {
        margin-left: 0;
        margin-right: 0
    }

    .ts-product.style-tabs-vertical.style-tabs-vertical-banner .banners .banner {
        padding: 0;
        display: block
    }

    .woocommerce ul#shipping_method li input[type^="radio"] {
        margin-top: 1px
    }

    .ts-instagram-wrapper.columns-12 .item,
    .ts-instagram-wrapper.columns-11 .item,
    .ts-instagram-wrapper.columns-10 .item,
    .ts-instagram-wrapper.columns-9 .item,
    .ts-instagram-wrapper.columns-8 .item,
    .ts-instagram-wrapper.columns-7 .item,
    .ts-instagram-wrapper.columns-6 .item,
    .ts-instagram-wrapper.columns-5 .item,
    .ts-instagram-wrapper.columns-4 .item,
    .ts-instagram-wrapper.columns-3 .item {
        width: 50%
    }

    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-5 .items .item,
    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-7 .items .item,
    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-8 .items .item,
    .vertical-menu-wrapper .ts-shortcode.ts-logo-slider-wrapper:not(.ts-slider).columns-6 .items .item {
        width: 50%
    }

    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper {
        width: 33%
    }

    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        width: 45%
    }
}

@media only screen and (max-width: 430px) {

    .ts-logo-slider-wrapper:not(.ts-slider).columns-6 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-5 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-4 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-3 .item {
        width: 50%
    }
}

@media only screen and (max-width: 350px) {
    .woocommerce div.product form.cart div.quantity {
        margin: 0;
        width: 100%
    }

    .woocommerce div.product form.cart .button {
        width: 100%;
        margin-top: 10px
    }

    body:not(.mobile-app-style) .ts-header .my-wishlist-wrapper .tini-wishlist .count-number,
    body:not(.mobile-app-style) .ts-header .my-notification-wrapper .tini-wishlist .count-number,
    body:not(.mobile-app-style) .ts-header .shopping-cart-wrapper .cart-control .cart-number {
        left: 22px;
        width: 16px;
        height: 16px;
        font-size: 9px;
        line-height: 16px
    }

    body:not(.mobile-app-style) .ts-header .search-button.search-icon .icon,
    body:not(.mobile-app-style) .ts-header .my-wishlist-wrapper .tini-wishlist,
    body:not(.mobile-app-style) .ts-header .my-notification-wrapper .tini-wishlist,
    body:not(.mobile-app-style) .ts-header .my-account-wrapper .account-control>a,
    body:not(.mobile-app-style) .ts-header .shopping-cart-wrapper .cart-control {
        padding: 10px
    }

    .ts-logo-slider-wrapper:not(.ts-slider).columns-6 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-5 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-4 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-3 .item,
    .ts-logo-slider-wrapper:not(.ts-slider).columns-2 .item {
        width: 100%;
        margin-right: 0
    }

    header .logo img,
    header .logo-header img {
        max-width: 158px
    }

    #main-content.ts-col-18 .before-loop-wrapper>.widget select,
    #main-content.ts-col-18 .product-per-page-form ul.perpage .perpage-current>span:last-child,
    #main-content.ts-col-18 .woocommerce .woocommerce-ordering .orderby-current,
    .before-loop-wrapper>.widget select,
    .product-per-page-form ul.perpage .perpage-current>span:last-child,
    .woocommerce .woocommerce-ordering .orderby-current {
        width: 90px
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        padding-left: 5px;
        padding-right: 20px
    }

    .main-products.style-column-list .product-group-button-meta>div.loop-add-to-cart>a.button {
        padding: 0 !important;
        font-size: 11px !important
    }

    #group-icon-header .group-button-header:not(:empty) {
        padding: 10px 20px 0
    }

    body.mobile-app-style #group-icon-header .sidebar-content {
        height: calc(100% - 50px)
    }

    .woocommerce div.product .ts-product-brand-info form.cart .button {
        width: 100% !important;
        margin: 5px 0 0;
        max-width: 100%
    }

    .woocommerce.main-products.style-column-list .product .price del,
    .woocommerce.main-products.style-column-list .product .price ins {
        width: 100%
    }

    .woocommerce.main-products.style-column-list .product .price del+ins {
        margin-right: 0
    }

    .woocommerce.main-products.style-column-list .product .price {
        font-size: 90%
    }

    .woocommerce .before-loop-wrapper>.widget {
        width: 100%;
        margin-bottom: 10px !important;
        text-align: left
    }

    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row,
    .woocommerce-shipping-fields__field-wrapper>p.form-row {
        margin-left: 0;
        margin-right: 0
    }

    .woocommerce-billing-fields__field-wrapper>p.form-row-first,
    .woocommerce-billing-fields__field-wrapper>p.form-row-last,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-first,
    .woocommerce-shipping-fields__field-wrapper>p.form-row-last,
    .woocommerce-billing-fields__field-wrapper>#billing_postcode_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_postcode_field,
    .woocommerce-billing-fields__field-wrapper>#billing_city_field,
    .woocommerce-shipping-fields__field-wrapper>#shipping_city_field {
        width: 100%
    }

    .ts-product.style-list .shortcode-heading-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .ts-product.style-list .shortcode-heading-wrapper .shop-more {
        margin-left: 0
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper {
        display: block
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper:after,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper:after {
        content: '';
        clear: both;
        display: table
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .thumbnail-wrapper,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .thumbnail-wrapper {
        width: 30%;
        float: left
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper {
        width: 70%;
        padding-left: 15px;
        padding-right: 35px;
        float: left
    }

    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2,
    .ts-product.style-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 {
        margin-top: 7px
    }

    .ts-product.style-list .product-group-button-meta>div.loop-add-to-cart>a.button {
        width: 100%
    }

    .main-products.style-column-list .products .product:not(.product-category) .price del,
    .main-products.style-column-list .products .product:not(.product-category) .price ins,
    .ts-product.style-list .products .product:not(.product-category) .price del,
    .ts-product.style-list .products .product:not(.product-category) .price ins {
        width: auto
    }

    .main-products.style-column-list .products .product:not(.product-category) .price del+ins,
    .ts-product.style-list .products .product:not(.product-category) .price del+ins {
        margin-right: 5px;
        margin-bottom: 0
    }

    .ts-product.style-list .products .product:not(.product-category) .meta-wrapper-2 .price,
    .main-products.style-column-list .products .product:not(.product-category) .product-wrapper .meta-wrapper-2 .price {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media only screen and (max-height: 500px) {
    #ts-add-to-cart-popup-modal .close {
        top: 5px;
        right: 5px;
        transform: translateY(0);
        color: #191919
    }
}

header .logo img,
header .logo-header img {
    width: 190px
}

@media only screen and (max-width: 1279px) {

    header .logo img,
    header .logo-header img {
        width: 160px
    }
}

html,
body,
label,
input,
textarea,
keygen,
select,
button,
body .font-body,
.ts-header nav.main-menu>ul.menu>li.font-body>a,
.ts-header nav.main-menu>ul>li.font-body>a,
.price del,
.product-price del,
.woocommerce div.product p.price del,
.woocommerce div.product span.price del,
.woocommerce div.product .yith-wfbt-section .yith-wfbt-form .price del,
ul.product_list_widget li .price del,
.woocommerce ul.product_list_widget li .price del,
.woocommerce .widget_shopping_cart .cart_list li .price del,
.woocommerce.widget_shopping_cart .cart_list li .price del,
.ts-tiny-cart-wrapper .dropdown-container ul.cart_list li .price,
.category-name .count,
.portfolio-info .cat-links a,
.mobile-menu-wrapper .mobile-menu .product,
.ts-header .menu-wrapper .vertical-menu-wrapper .product,
.ts-header .menu-wrapper .vertical-menu-wrapper .product,
.ts-header .menu-wrapper .vertical-menu-wrapper .product,
.ts-header .menu-wrapper .ts-menu .product,
.single-portfolio .meta-content .portfolio-info>span:last-child,
.single-portfolio .meta-content .portfolio-info>a:last-child,
.woocommerce div.product form.cart .variations label,
.ts-testimonial-wrapper blockquote .author-role,
.style-tabs-default .column-tabs ul.tabs li,
.style-tabs-vertical .column-tabs ul.tabs li,
.ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li,
body table.compare-list,
.ts-testimonial-wrapper.style-default blockquote,
.ts-list-of-product-categories-wrapper.style-horizontal h3.heading-title,
.ts-blogs article .entry-title,
.list-posts article .entry-title,
.button-text:not(.ts-banner):not(.elementor-widget-button),
.elementor-widget-button.button-text .elementor-button,
.ts-search-result-container .view-all-wrapper a,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px
}

.product-style-3 .brand-info-wrapper h3,
.product-per-page-form ul.perpage .perpage-current>span:last-child,
.woocommerce .woocommerce-ordering .orderby-current,
.woocommerce .woocommerce-ordering .orderby li a.current,
.product-per-page-form ul.perpage ul li a.current,
.product-filter-by-brand-wrapper select,
.product-filter-by-brand-wrapper select option[selected="selected"],
body #ts-filter-widget-area .select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
.woocommerce-page.archive #left-sidebar .select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
.woocommerce-page.archive #right-sidebar .select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__placeholder,
#tab-description .ul-style.list-inline li,
.ts-active-filters .widget_layered_nav_filters ul li a,
.woocommerce-widget-layered-nav ul.woocommerce-widget-layered-nav-list>li>a,
.woocommerce-widget-layered-nav ul li.chosen>a,
.ts-product-categories-widget-wrapper ul.product-categories>li>a,
.ts-product-categories-widget-wrapper ul li.current>a,
.widget_product_categories ul.product-categories>li>a,
.widget_product_categories ul li.current-cat>a,
.ts-product-attribute>div.option:not(.color)>a {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 500
}

strong,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
table thead th,
table th,
.woocommerce table.shop_table th,
.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot th,
body .wp-block-calendar table th,
body blockquote,
.ts-store-notice,
.ts-tiny-cart-wrapper .total>span.total-title,
.widget_shopping_cart .total-title,
.woocommerce .widget_shopping_cart .total strong,
.woocommerce.widget_shopping_cart .total strong,
.elementor-widget-wp-widget-woocommerce_widget_cart .total strong,
.shopping-cart-wrapper .cart-control .cart-total,
.my-account-wrapper .ts-style-text .account-control>a,
.ts-testimonial-wrapper.style-default blockquote strong,
#group-icon-header .tab-mobile-menu li,
.ts-blogs article .entry-title,
.list-posts article .entry-title,
.ts-store-notice a,
div.product .summary .tags-link a,
.hightlight,
.category-name .heading-title,
.column-tabs ul.tabs li,
.entry-author .author a,
.comments-area .add-comment .comments-count,
.meta-bottom-2 .single-navigation,
ul.blog-filter-bar li.current,
.counter-wrapper>div,
.ts-portfolio-wrapper .filter-bar li.current,
.cart-collaterals .cart_totals>h2,
.cart_list .subtotal,
.ts-tiny-cart-wrapper .total,
.widget_shopping_cart .total-title,
.price,
.product-price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
ul.product_list_widget li .price,
.woocommerce ul.product_list_widget li .price,
.woocommerce .widget_shopping_cart .cart_list li .price,
.woocommerce.widget_shopping_cart .cart_list li .price,
.yith-wfbt-section .total_price,
.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total,
.elementor-widget-wp-widget-woocommerce_widget_cart .total,
.woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-subtotal,
body .wishlist_table.images_grid li .item-details table.item-details-table td.label,
body .wishlist_table.mobile li .item-details table.item-details-table td.label,
body .wishlist_table.mobile li table.additional-info td.label,
body .wishlist_table.modern_grid li .item-details table.item-details-table td.label,
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong,
.ts-list-of-product-categories-wrapper.style-horizontal>.list-categories ul li,
#main-content .woocommerce.columns-1>.products .product .product-name,
#main-content .woocommerce.columns-1>.products .product .meta-wrapper-2 .price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .yith-wfbt-section .yith-wfbt-form .price,
.woocommerce div.product .yith-wfbt-section .yith-wfbt-form .price ins,
.woocommerce div.product form.cart div.quantity .screen-reader-text,
.woocommerce #reviews .comment-reply-title,
.woocommerce-tabs #reviews>.woocommerce-product-rating .review-count,
div.product .single-navigation>a>span,
.woocommerce-orders-table__cell-order-number,
.woocommerce-account .woocommerce-MyAccount-navigation li a,
html body>h1,
.more-less-buttons a,
.button,
a.button,
button,
.ts-button,
input[type^="submit"],
.shopping-cart p.buttons a,
a.wp-block-button__link,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page input.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
.woocommerce #respond input#submit,
.yith-woocompare-widget a.clear-all,
.yith-woocompare-widget a.compare,
.elementor-button-wrapper .elementor-button,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare,
div.product .summary .meta-content>div>span:first-child,
div.product .summary .detail-meta-top>div>span:first-child,
.woocommerce div.product .woocommerce-tabs ul.tabs li,
div.button a,
input[type="submit"].dokan-btn,
a.dokan-btn,
.dokan-btn,
.wishlist_table .product-add-to-cart a,
body .woocommerce table.compare-list .add-to-cart td a,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button,
.woocommerce .product .category-name .count,
.ts-product-brand-wrapper .item .count,
.woocommerce .cart-collaterals .amount,
.woocommerce-shipping-fields h3#ship-to-different-address,
.ts-product-video-button,
.ts-product-360-button,
.portfolio-info>span:first-child,
.woocommerce>form>fieldset legend,
#ts-search-result-container .view-all-wrapper,
.woocommerce form table.shop_table tbody th,
.woocommerce form table.shop_table tfoot td,
.woocommerce form table.shop_table tfoot th,
.woocommerce table.shop_table ul#shipping_method .amount,
.column-tabs ul.tabs li.current,
.ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li.current,
.product-group-button .button-tooltip,
.ts-product-attribute .button-tooltip,
.availability .availability-text,
.view-all-wrapper a,
.ts-shortcode a.view-more,
.ts-portfolio-wrapper .item-wrapper a.like,
.portfolio-info .portfolio-like,
.tags-link a,
.wp-block-tag-cloud a,
.tagcloud a,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li span.checkboxbutton.checked,
.widget-container.product-filter-by-brand ul>li.selected label,
.product-filter-by-availability ul li input[checked="checked"]+label,
.product-filter-by-price ul li.chosen label,
.woocommerce .widget_rating_filter ul li.chosen a,
.product-filter-by-color ul li.chosen a,
.product_list_widget .product-label>span.onsale,
.product_list_widget .product-label>span,
.woocommerce .product-label>span.onsale,
.woocommerce .product-label>span,
.dropdown-container .theme-title span,
.my-wishlist-wrapper .tini-wishlist .count-number,
.my-notification-wrapper .tini-wishlist .count-number,
.shopping-cart-wrapper .cart-control .cart-number,
.ts-pagination ul li a,
.ts-pagination ul li span,
.pagination-wrap ul.pagination>li>a,
.pagination-wrap ul.pagination>li>span,
.dokan-pagination-container .dokan-pagination li a,
.dokan-pagination-container .dokan-pagination li span,
.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span,
.pagination-wrap ul.pagination>li>a.prev,
.pagination-wrap ul.pagination>li>a.next,
.dokan-pagination-container .dokan-pagination li:first-child a,
.dokan-pagination-container .dokan-pagination li:last-child a,
.woocommerce nav.woocommerce-pagination ul li a.next,
.woocommerce nav.woocommerce-pagination ul li a.prev,
.ts-pagination ul li a.prev,
.ts-pagination ul li a.next {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700
}

.ts-banner .box-content .banner-text strong {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 900
}

.mobile-menu-wrapper .mobile-menu,
.ts-header .menu-wrapper .vertical-menu-wrapper,
.ts-header .normal-menu nav.vertical-menu ul.sub-menu>li,
.ts-header .menu-wrapper .ts-menu {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700
}

.mobile-menu-wrapper .mobile-menu ul.sub-menu,
.ts-header .menu-wrapper .vertical-menu-wrapper ul.sub-menu,
.ts-header .menu-wrapper .ts-menu ul.sub-menu {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400
}

.widget_nav_menu li[class*="agrofood-"],
.widget_nav_menu li[class*="fa-"],
.mobile-menu-wrapper .mobile-menu li[class*="agrofood-"],
.ts-header nav.main-menu li[class*="agrofood-"],
.mobile-menu-wrapper .mobile-menu li[class*="fa-"],
.ts-header nav.main-menu li[class*="fa-"] {
    font-family: Helvetica !important
}

.product-name,
h3.product-name,
.product-name h3 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 500
}

.yith-wfbt-item .product-name,
.ts-tiny-cart-wrapper .cart_list li .product-name,
.woocommerce .ts-tiny-cart-wrapper .product-name,
.woocommerce table.shop_table td.product-name {
    font-style: normal;
    font-weight: 400
}

html,
body,
html body>h1,
.ts-testimonial-wrapper.style-default blockquote strong,
.ts-sidebar .widget_text small+a,
.woocommerce-shipping-fields h3#ship-to-different-address,
.ts-blogs-widget-wrapper .post-title,
.list-posts .entry-content .button-readmore,
.ts-blogs .entry-content .button-readmore,
.yith-wfbt-form .yith-wfbt-submit-block .button,
.commentlist li.comment .comment-actions strong {
    font-size: 14px;
    line-height: 22px
}

.list-posts,
.ts-blogs,
.single-post>.entry-content>.content-wrapper {
    font-size: 15px
}

.ts-store-notice,
.footer-container,
.entry-meta-middle,
.entry-meta-bottom,
.elementor-text-editor .boxed,
.shopping-cart-wrapper .cart-control .cart-total,
.ts-header nav.main-menu>ul.menu>li.font-body>a,
.ts-header nav.main-menu>ul>li.font-body>a,
.mobile-menu-wrapper nav ul li.font-body>a,
.ts-testimonial-wrapper.style-default blockquote,
.style-tabs-default .column-tabs ul.tabs li,
.style-tabs-vertical .column-tabs ul.tabs li,
.woocommerce ul.cart_list li .price,
.woocommerce ul.product_list_widget li .price,
.woocommerce>form.checkout #order_review,
#group-icon-header .tab-mobile-menu li,
.wishlist_table.mobile,
ul.blog-filter-bar li,
.woocommerce-cart article .woocommerce .cart-collaterals,
.ts-mailchimp-subscription-shortcode .mc4wp-form .subscribe-email .terms-conditions label,
#group-icon-header .tab-mobile-menu li,
.woocommerce-checkout #order_review,
.woocommerce #reviews #comments ol.commentlist li .comment-text .description {
    font-size: 13px
}

.header-language,
.header-currency,
.ts-language-switcher,
.ts-currency-switcher,
.comment-meta .author,
.commentlist li.comment .comment-actions,
.comments-area .add-comment,
.meta-bottom-2 .single-navigation,
.product-group-button .button-tooltip,
.ts-product-attribute .button-tooltip,
body table.compare-list,
.ts-product.style-grid.has-shop-more-button .shop-more .button-text:not(.ts-banner):not(.elementor-widget-button),
.woocommerce>.woocommerce-order .woocommerce-customer-details,
.woocommerce .woocommerce-MyAccount-content .woocommerce-customer-details,
.ts-testimonial-wrapper blockquote .author-role .author,
.woocommerce ul.cart_list li .product-categories,
.woocommerce ul.cart_list li .product-brands,
.woocommerce ul.product_list_widget li .product-categories,
.woocommerce ul.product_list_widget li .product-brands,
.ts-active-filters,
.woocommerce #reviews>.woocommerce-product-rating,
.woocommerce .before-loop-wrapper,
.brand-info-wrapper,
.product-style-3 .brand-info-wrapper h3,
#ts-quickshop-modal .woocommerce-product-details__short-description,
#ts-filter-widget-area .ts-sidebar-content,
.ts-search-result-container .view-all-wrapper a,
#left-sidebar,
#right-sidebar {
    font-size: 13px
}

.column-tabs .shop-more .button-text,
.ts-product-brand-info .show-all a,
.woocommerce .widget_price_filter .price_slider_amount .price_label>span,
.woocommerce .widget_price_filter .price_slider_amount .button {
    font-size: 13px !important
}

small,
.font-small,
.font-small li,
.breadcrumb-title-wrapper .breadcrumbs,
.header-top,
.comment-meta,
#comment-wrapper .heading-title small,
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta,
.widget_recent_entries .post-date,
.single-portfolio .meta-content,
.woocommerce ul.order_details li,
.product-style-3 .brand-info-wrapper .description,
.woocommerce-privacy-policy-text,
.elementor-widget-wp-widget-recent-posts .post-date,
.dokan-store-wrap .commentlist li p strong[itemprop="author"],
.dokan-store-wrap .commentlist li p em.verified,
.dokan-store-wrap .commentlist li p time,
.ts-testimonial-wrapper blockquote .author-role {
    font-size: 13px
}

.header-contact-info>strong {
    font-size: 15px
}

.ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu-heading,
.ts-header .menu-wrapper .ts-menu,
.ts-megamenu-container .elementor-widget-container>h5 {
    font-size: 17px;
    line-height: 22px
}

.mobile-menu-wrapper .mobile-menu li,
.ts-header nav.main-menu li {
    line-height: 22px !important
}

.mobile-menu-wrapper .mobile-menu ul.sub-menu,
.ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu,
.ts-header .menu-wrapper .ts-menu ul.sub-menu {
    font-size: 13px;
    line-height: 18px
}

.ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu>ul>li {
    font-size: 14px
}

.mobile-menu-wrapper .mobile-menu ul.sub-menu li,
.ts-header nav.main-menu ul.sub-menu li {
    line-height: 18px !important
}

.product-name,
h3.product-name,
.product-name h3,
.product .price del,
.woocommerce div.product div.summary,
.ts-product.style-list .product .price,
.ts-product.style-list .product .price del,
.ts-product.style-list .product .price ins,
.main-products.style-column-list .product .price,
.main-products.style-column-list .product .price del,
.main-products.style-column-list .product .price ins,
.woocommerce div.product .ts-product-brand-info .price,
.woocommerce div.product .ts-product-brand-info .price del,
.woocommerce div.product .ts-product-brand-info .price ins,
.yith-wfbt-section,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li label>* {
    font-size: 13px;
    line-height: 16px
}

.yith-wfbt-section .total_price_label {
    font-size: 13px
}

.product.product-category .product-wrapper {
    font-size: 14px
}

.product-brands,
.product-sku,
.product-categories,
.short-description,
.product .availability,
.ts-product-video-button,
.ts-product-360-button,
.woocommerce .product .category-name .count,
.products .product .meta-wrapper>.count-rating,
.woocommerce div.product .summary .woocommerce-product-rating,
.woocommerce div.product form.cart .variations,
.woocommerce div.product form.cart .reset_variations,
.woocommerce div.product form.cart .single_variation_wrap,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a.added,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons .added a:after,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons .ts-tooltip:before {
    font-size: 12px
}

div.product .summary .meta-content,
div.product .summary .detail-meta-top {
    font-size: 12px
}

input,
textarea,
keygen,
select,
select option,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea,
.dokan-form-control,
.more-less-buttons a,
.woocommerce-columns>h3,
.hidden-title-form input[type="text"],
body .select2-container--default .select2-selection--single .select2-selection__rendered,
body select.dokan-form-control {
    font-size: 15px;
    line-height: 24px
}

.button-text:not(.ts-banner):not(.elementor-widget-button),
.elementor-widget-button.button-text .elementor-button {
    font-size: 16px
}

.button,
a.button,
button,
input[type^="submit"],
.shopping-cart p.buttons a,
a.wp-block-button__link,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page input.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
#content button.button,
.woocommerce #respond input#submit,
div.button a,
input[type="submit"].dokan-btn,
a.dokan-btn,
.dokan-btn,
.woocommerce .button.button-small,
.button.button-small,
.woocommerce .button.button-small.button-border,
.button.button-small.button-border,
.woocommerce-cart .cart-collaterals .shipping-calculator-form .button,
.wishlist_table .product-add-to-cart a,
body .woocommerce table.compare-list .add-to-cart td a,
.elementor-button-wrapper .elementor-button,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare {
    font-size: 16px;
    line-height: 24px
}

.yith-woocompare-widget a.clear-all,
.yith-woocompare-widget a.compare {
    line-height: 24px
}

.elementor-button-wrapper .elementor-button.elementor-size-xs {
    font-size: 13px !important
}

.elementor-button-wrapper .elementor-button.elementor-size-xl {
    font-size: 18px !important
}

#left-sidebar .widget-container a.button,
#left-sidebar .widget-container button,
#left-sidebar .widget-container input[type^="submit"],
#left-sidebar .yith-woocompare-widget a.clear-all,
#left-sidebar .yith-woocompare-widget a.compare,
#left-sidebar .widget-container .dokan-btn,
#right-sidebar .widget-container a.button,
#right-sidebar .widget-container button,
#right-sidebar .widget-container input[type^="submit"],
#right-sidebar .yith-woocompare-widget a.clear-all,
#right-sidebar .yith-woocompare-widget a.compare,
#right-sidebar .widget-container .dokan-btn,
#ts-filter-widget-area .widget-container a.button,
#ts-filter-widget-area .widget-container button,
#ts-filter-widget-area .widget-container input[type^="submit"],
#ts-filter-widget-area .yith-woocompare-widget a.clear-all,
#ts-filter-widget-area .yith-woocompare-widget a.compare,
#ts-filter-widget-area .widget-container .dokan-btn,
#add_payment_method table.cart td.actions .coupon .input-text,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text,
.woocommerce-cart .cart-collaterals .shipping-calculator-form .button,
.woocommerce-cart table.cart td.actions .button {
    font-size: 14px
}

h1,
.h1,
.h1 .elementor-heading-title,
article.single-portfolio .entry-content>.entry-title,
.woocommerce div.product .summary p.price,
.woocommerce div.product .summary span.price,
.main-content-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title,
.layout-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title {
    font-size: 40px;
    line-height: 56px
}

h2,
.h2,
.h2 .elementor-heading-title,
.breadcrumb-title-wrapper .page-title,
.related-portfolios .shortcode-title,
article.single .entry-header .entry-title,
.woocommerce div.product .summary .entry-title,
.woocommerce .cross-sells>h2,
.woocommerce .up-sells>h2,
.woocommerce .related>h2,
.woocommerce.related>h2,
.theme-title .heading-title,
.yith-wfbt-section .total_price,
.woocommerce div.product>.ts-mailchimp-subscription-shortcode .widget-title,
.style-tabs-vertical .column-tabs .heading-tab .heading-title,
.ts-shortcode .shortcode-heading-wrapper .shortcode-title {
    font-size: 30px;
    line-height: 42px
}

h3,
.h3,
.h3 .elementor-heading-title,
.ts-blogs.columns-1 article .entry-title,
.columns-1 .list-posts article .entry-title,
.ts-blogs.columns-2 article .entry-title,
.columns-2 .list-posts article .entry-title,
.columns-0 .list-posts article:nth-child(1) .entry-title,
.columns-0 .list-posts article:nth-child(2) .entry-title,
.page-container:not(.columns-0):not(.columns-1):not(.columns-2):not(.columns-3) .list-posts article .entry-title,
.woocommerce div.product .woocommerce-tabs ul.tabs li,
.ts-portfolio-wrapper .portfolio-meta h4,
.yith-wfbt-section>h3,
.woocommerce-MyAccount-content form>h3,
#customer_login h2,
.woocommerce-order-details>h2,
.woocommerce .cart-collaterals .order-total .amount,
.dokan-dashboard h1.entry-title,
.dokan-dashboard header.dokan-dashboard-header h1,
#main-content .woocommerce.columns-1>.products .product .meta-wrapper-2 .price {
    font-size: 25px;
    line-height: 32px
}

h4,
.h4,
.h4 .elementor-heading-title,
#main-content .woocommerce.columns-1>.products .product .product-name,
.widget-container .widget-title-wrapper,
.widget-container .wp-block-group h2,
.woocommerce .cart-collaterals .cart_totals>h2,
.woocommerce-billing-fields>h3,
.woocommerce>form.checkout #order_review_heading,
.elementor-widget-image-box .elementor-image-box-title {
    font-size: 22px;
    line-height: 28px
}

h5,
.h5,
.h5 .elementor-heading-title,
.widget-container .widget-title,
.comments-title .heading-title,
.ts-search-by-category>h2,
.dropdown-container .theme-title,
#comment-wrapper .heading-title,
.woocommerce #reviews #comments h2,
#reviews .woocommerce-Reviews-title,
.column-tabs ul.tabs li,
.style-tabs-default .column-tabs .heading-tab .heading-title,
#main-content.ts-col-24 .frequently-bought-together-vertical .yith-wfbt-section>h3,
.ts-megamenu .elementor-widget .elementor-widget-container .banner-wrapper h5,
.elementor-widget[data-widget_type*="wp-widget-"] h2.widgettitle,
.ts-product.style-list .shortcode-heading-wrapper .shortcode-title {
    font-size: 20px;
    line-height: 26px
}

h6,
.h6,
.h6 .elementor-heading-title,
.ts-blogs article .entry-title,
.list-posts article .entry-title,
.filter-widget-area .widget-container .widget-title,
.ts-megamenu .elementor-widget .elementor-widget-container h5,
.footer-container .elementor-widget .elementor-widget-container h5,
.footer-container .ts-list-of-product-categories-wrapper h3.heading-title,
body .dokan-category-menu h3.widget-title,
body .dokan-widget-area .widget .widget-title,
body .cart-empty.woocommerce-info,
.ts-team-members h3,
.commentlist li #comment-wrapper .heading-title,
.woocommerce-account .addresses .title h3,
.woocommerce-account .addresses h2,
.woocommerce-customer-details .addresses h2,
.ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li,
.woocommerce .tabs-in-summary #reviews #comments .woocommerce-Reviews-title {
    font-size: 17px;
    line-height: 22px
}

body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widget-title,
body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widgettitle,
body:not(.woocommerce.archive) #left-sidebar .widget-container .wp-block-group h2,
body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widget-title,
body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widgettitle,
body:not(.woocommerce.archive) #right-sidebar .widget-container .wp-block-group h2,
.brand-info-wrapper h3 {
    font-size: 16px
}

@media only screen and (max-width: 1400px) {

    #tab-description .ts-row .h1,
    .woocommerce div.product.product-style-3 .summary p.price {
        font-size: 30px;
        line-height: 42px
    }

    .woocommerce div.product .summary p.price,
    .woocommerce div.product .summary span.price {
        font-size: 35px
    }

    .yith-wfbt-section .total_price {
        font-size: 25px
    }
}

@media only screen and (min-width: 1279px) {

    .show-filter-top .product-filter-by-brand ul li label,
    .show-filter-top .product-filter-by-availability ul li label,
    .show-filter-top .product-filter-by-color ul li a {
        font-family: Helvetica;
        font-style: normal;
        font-weight: 500
    }
}

@media only screen and (max-width: 1279px) {

    h1,
    .h1,
    .h1 .elementor-heading-title,
    article.single-portfolio .entry-content>.entry-title,
    .woocommerce div.product .summary p.price,
    .woocommerce div.product .summary span.price,
    .main-content-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title,
    .layout-fullwidth #main-content.ts-col-24 article.single .entry-header .entry-title {
        font-size: 30px;
        line-height: 42px
    }

    h2,
    .h2,
    .h2 .elementor-heading-title,
    .breadcrumb-title-wrapper .page-title,
    .related-portfolios .shortcode-title,
    article.single .entry-header .entry-title,
    .woocommerce div.product .summary .entry-title,
    .woocommerce .cross-sells>h2,
    .woocommerce .up-sells>h2,
    .woocommerce .related>h2,
    .woocommerce.related>h2,
    .theme-title .heading-title,
    .yith-wfbt-section .total_price,
    .woocommerce div.product>.ts-mailchimp-subscription-shortcode .widget-title,
    .ts-shortcode .shortcode-heading-wrapper .shortcode-title {
        font-size: 25px;
        line-height: 32px
    }

    h3,
    .h3,
    .h3 .elementor-heading-title,
    .ts-blogs.columns-1 article .entry-title,
    .columns-1 .list-posts article .entry-title,
    .ts-blogs.columns-2 article .entry-title,
    .columns-2 .list-posts article .entry-title,
    .columns-0 .list-posts article:nth-child(1) .entry-title,
    .page-container:not(.columns-0):not(.columns-1):not(.columns-2):not(.columns-3) .list-posts article .entry-title,
    .woocommerce div.product .woocommerce-tabs ul.tabs li,
    #reviews .woocommerce-Reviews-title,
    .yith-wfbt-section>h3,
    .woocommerce-MyAccount-content form>h3,
    #customer_login h2,
    .woocommerce-order-details>h2,
    .woocommerce .cart-collaterals .order-total .amount,
    .dokan-dashboard h1.entry-title,
    .dokan-dashboard header.dokan-dashboard-header h1,
    .style-tabs-default .column-tabs .heading-tab .heading-title,
    .style-tabs-vertical .column-tabs .heading-tab .heading-title,
    #main-content .woocommerce.columns-1>.products .product .meta-wrapper-2 .price {
        font-size: 22px;
        line-height: 28px
    }

    h4,
    .h4,
    .h4 .elementor-heading-title,
    #main-content .woocommerce.columns-1>.products .product .product-name,
    .widget-container .widget-title-wrapper,
    .widget-container .wp-block-group h2,
    .woocommerce .cart-collaterals .cart_totals>h2,
    .woocommerce-billing-fields>h3,
    .woocommerce>form.checkout #order_review_heading,
    .elementor-widget-image-box .elementor-image-box-title,
    .column-tabs ul.tabs li {
        font-size: 20px;
        line-height: 26px
    }

    h5,
    .h5,
    .h5 .elementor-heading-title,
    .widget-container .widget-title,
    .comments-title .heading-title,
    .ts-search-by-category>h2,
    .dropdown-container .theme-title,
    #comment-wrapper .heading-title,
    .woocommerce #reviews #comments h2,
    #reviews .woocommerce-Reviews-title,
    #main-content.ts-col-24 .frequently-bought-together-vertical .yith-wfbt-section>h3,
    .elementor-widget[data-widget_type*="wp-widget-"] h2.widgettitle,
    .ts-portfolio-wrapper .portfolio-meta h4 {
        font-size: 17px;
        line-height: 22px
    }

    h6,
    .h6,
    .h6 .elementor-heading-title,
    .ts-blogs article .entry-title,
    .list-posts article .entry-title,
    .columns-0 .list-posts article:nth-child(2) .entry-title,
    .filter-widget-area .widget-container .widget-title,
    .ts-megamenu .elementor-widget .elementor-widget-container h5,
    .footer-container .elementor-widget .elementor-widget-container h5,
    .footer-container .ts-list-of-product-categories-wrapper h3.heading-title,
    body .dokan-category-menu h3.widget-title,
    body .dokan-widget-area .widget .widget-title,
    body .cart-empty.woocommerce-info,
    .ts-team-members h3,
    .commentlist li #comment-wrapper .heading-title,
    .woocommerce-account .addresses .title h3,
    .woocommerce-account .addresses h2,
    .woocommerce-customer-details .addresses h2,
    body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widget-title,
    body:not(.woocommerce.archive) #left-sidebar .widget-container .widget-title-wrapper .widgettitle,
    body:not(.woocommerce.archive) #left-sidebar .widget-container .wp-block-group h2,
    body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widget-title,
    body:not(.woocommerce.archive) #right-sidebar .widget-container .widget-title-wrapper .widgettitle,
    body:not(.woocommerce.archive) #right-sidebar .widget-container .wp-block-group h2,
    .ts-product-in-product-type-tab-wrapper .column-tabs ul.tabs li,
    .woocommerce .tabs-in-summary #reviews #comments .woocommerce-Reviews-title {
        font-size: 15px;
        line-height: 20px
    }

    .mobile-menu-wrapper .mobile-menu,
    .ts-header .menu-wrapper .vertical-menu-wrapper .vertical-menu-heading,
    .ts-megamenu-container .elementor-widget-container>h5,
    .ts-header .menu-wrapper .ts-menu {
        font-size: 15px
    }

    input,
    textarea,
    keygen,
    select,
    select option,
    body .select2-container,
    .woocommerce form .form-row input.input-text,
    .woocommerce form .form-row textarea,
    .dokan-form-control,
    .more-less-buttons a,
    #add_payment_method table.cart td.actions .coupon .input-text,
    .woocommerce-cart table.cart td.actions .coupon .input-text,
    .woocommerce-checkout table.cart td.actions .coupon .input-text,
    .woocommerce-columns>h3,
    .hidden-title-form input[type="text"] {
        font-size: 14px;
        line-height: 18px
    }

    .button-text:not(.ts-banner):not(.elementor-widget-button),
    .elementor-widget-button.button-text .elementor-button {
        font-size: 15px
    }

    .button,
    a.button,
    button,
    input[type^="submit"],
    .shopping-cart p.buttons a,
    a.wp-block-button__link,
    .woocommerce a.button,
    .woocommerce button.button,
    .woocommerce input.button,
    .woocommerce-page a.button,
    .woocommerce-page button.button,
    .woocommerce-page input.button,
    .woocommerce a.button.alt,
    .woocommerce button.button.alt,
    .woocommerce input.button.alt,
    .woocommerce-page a.button.alt,
    .woocommerce-page button.button.alt,
    .woocommerce-page input.button.alt,
    #content button.button,
    .woocommerce #respond input#submit,
    div.button a,
    input[type="submit"].dokan-btn,
    a.dokan-btn,
    .dokan-btn,
    .woocommerce .button.button-small,
    .button.button-small,
    .woocommerce .button.button-small.button-border,
    .button.button-small.button-border,
    .woocommerce-cart .cart-collaterals .shipping-calculator-form .button,
    .wishlist_table .product-add-to-cart a,
    body .woocommerce table.compare-list .add-to-cart td a,
    .elementor-button-wrapper .elementor-button,
    .elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
    .elementor-widget-wp-widget-yith-woocompare-widget a.compare {
        font-size: 15px;
        line-height: 18px
    }

    .elementor-button-wrapper .elementor-button.elementor-size-xs {
        font-size: 12px !important
    }

    .elementor-button-wrapper .elementor-button.elementor-size-xl {
        font-size: 17px
    }

    .entry-meta-middle,
    .entry-meta-bottom {
        font-size: 12px
    }
}

@media only screen and (max-width: 767px) {
    .columns-0 .list-posts article:nth-child(1) .entry-title {
        font-size: 15px;
        line-height: 20px
    }
}

body #main,
body.dokan-store #main:before,
#cboxLoadedContent,
.shopping-cart-wrapper .dropdown-container:before,
.my-account-wrapper .dropdown-container:before,
form.checkout div.create-account,
.ts-popup-modal .popup-container,
body #ts-search-result-container:before,
#yith-wcwl-popup-message,
.dataTables_wrapper,
body>.compare-list,
.single-navigation>div .product-info:before,
.single-navigation .product-info:before,
.archive.ajax-pagination .woocommerce>.products:after,
.dropdown-container ul.cart_list li.loading:before,
.thumbnail-wrapper .button-in.wishlist>a.loading:before,
.meta-wrapper .button-in.wishlist>a.loading:before,
.woocommerce a.button.loading:before,
.woocommerce button.button.loading:before,
.woocommerce input.button.loading:before,
div.blockUI.blockOverlay:before,
.woocommerce .blockUI.blockOverlay:before,
div.product .single-navigation a .product-info,
.ts-floating-sidebar .ts-sidebar-content,
.mobile-menu-wrapper ul.sub-menu,
.ts-team-members .team-info,
.mobile-menu-wrapper li.active .ts-menu-drop-icon.active,
.woocommerce .woocommerce-ordering .orderby ul:before,
.product-per-page-form ul.perpage ul:before,
#comments .wcpr-filter-container ul.wcpr-filter-button-ul,
.single-post .entry-header-meta .entry-header-meta-content,
.vertical-categories-wrapper .products .product-category,
.archive.woocommerce .woocommerce .product-wrapper,
.shopping-cart-wrapper .dropdown-container:before,
.my-account-wrapper .dropdown-container:before,
.wcml_currency_switcher>ul:before,
.wpml-ls-legacy-dropdown ul.wpml-ls-sub-menu:before,
.wpml-ls-item-legacy-dropdown-click ul.wpml-ls-sub-menu:before {
    background-color: rgba(255, 255, 255, 1)
}

.ts-tiny-cart-wrapper .total {
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 1)
}

.ts-tiny-cart-wrapper li div.blockUI.blockOverlay,
.widget_shopping_cart li div.blockUI.blockOverlay,
.elementor-widget-wp-widget-woocommerce_widget_cart li div.blockUI.blockOverlay {
    background-color: rgba(255, 255, 255, 1) !important
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger,
.portfolio-thumbnail>figure~a.like {
    background: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1)
}

.more-less-buttons>a.more-button:after {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 1)));
    background-image: -o-linear-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 1)));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%)
}

.ts-team-members .team-info {
    background-color: rgba(255, 255, 255, 0.9)
}

blockquote {
    background: rgba(255, 246, 236, 1);
    color: rgba(0, 0, 0, 1)
}

body,
body table.compare-list,
.comment-author-link a,
.widget-container li>a,
.widget_categories li>a,
.widget_archive li>a,
.wp-block-archives-list li>a,
.header-middle .header-currency ul,
.header-middle .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
.header-middle .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
.header-top .header-currency ul,
.header-top .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
.header-top .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
body .header-top .dropdown-container,
body .header-middle .dropdown-container,
body .header-bottom .dropdown-container,
footer#colophon .wcml_currency_switcher>ul,
footer#colophon .wpml-ls-legacy-dropdown ul.wpml-ls-sub-menu,
footer#colophon .wpml-ls-item-legacy-dropdown-click ul.wpml-ls-sub-menu,
.ts-testimonial-wrapper blockquote .author-role .author,
.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta,
.my-account-wrapper .dropdown-container,
.ul-style.primary-color li,
.header-transparent.header-text-light .dropdown-container,
body.header-transparent.header-text-light .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
body.header-transparent.header-text-light .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu,
.header-transparent.header-text-light .header-currency ul,
.button-text:not(.ts-banner):not(.elementor-widget-button),
.ts-product.style-grid.has-shop-more-button .shop-more .button-text,
.ts-product.style-grid.has-shop-more-button .shop-more .button-text:hover,
.elementor-widget-button.button-text .elementor-button,
.elementor-widget-button.button-text .elementor-button:hover,
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button,
.woocommerce-page .woocommerce-error .button,
.woocommerce-page .woocommerce-info .button,
.woocommerce-page .woocommerce-message .button,
.owl-nav>div:before,
.ts-social-sharing li a,
.woocommerce-info,
.woocommerce .woocommerce-info,
.alert.alert-success,
div.wpcf7-mail-sent-ok,
#yith-wcwl-popup-message,
.woocommerce div.product .woocommerce-tabs ul.tabs li a:hover,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a,
.woocommerce.yith-wfbt-section .yith-wfbt-images td>a,
#ts-product-360-modal.ts-popup-modal .close,
.more-less-buttons>a,
.woocommerce-product-rating a,
.woocommerce-product-rating a:hover,
#reviews>.woocommerce-product-rating .review-count,
.woocommerce-privacy-policy-text,
.woocommerce>form.checkout a,
body .hidden-title-form a,
.ts-product-video-button,
.ts-product-360-button,
.dokan-store-wrap .commentlist li p time,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a {
    color: rgba(0, 0, 0, 1)
}

.owl-dot.active,
.social-icons .list-icons .ts-tooltip {
    background: rgba(0, 0, 0, 1)
}

.social-icons .list-icons .ts-tooltip:before {
    border-top-color: rgba(0, 0, 0, 1)
}

.threesixty .nav_bar a {
    background: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1)
}

.threesixty .nav_bar a:hover {
    color: rgba(0, 0, 0, 1)
}

.thumbnail-wrapper .product-group-button>div {
    background-color: rgba(255, 255, 255, 1)
}

.product-group-button>div a:after,
.product-group-button-meta>div.button-in a:before {
    color: rgba(0, 0, 0, 1)
}

.product-group-button-meta>div a.added:before,
.product-group-button-meta>div .added a:before,
.product-group-button>div a.added:after,
.product-group-button>div .added a:after,
.product_list_widget .button-in a:hover,
.woocommerce .product_list_widget .button-in a:hover,
.product_list_widget .button-in .added a:after,
.product_list_widget .button-in a.added:after {
    color: rgba(254, 121, 52, 1)
}

.product-group-button>div a.loading:after,
.product-group-button-meta>div a.loading:after,
.ts-product.style-list .product-group-button-meta>div.wishlist a.loading:before,
.main-products.style-column-list .product-group-button-meta>div.wishlist a.loading:before {
    border-color: rgba(0, 0, 0, 0.5);
    border-top-color: rgba(0, 0, 0, 1)
}

.tags-link a,
.wp-block-tag-cloud a,
.tagcloud a {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(229, 218, 218, 1);
    color: rgba(0, 0, 0, 1)
}

.tags-link a:hover,
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
    background-color: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

.pagination-wrap ul.pagination>li>a.prev,
.pagination-wrap ul.pagination>li>a.next,
.dokan-pagination-container .dokan-pagination li:first-child a,
.dokan-pagination-container .dokan-pagination li:last-child a,
.woocommerce nav.woocommerce-pagination ul li a.next,
.woocommerce nav.woocommerce-pagination ul li a.prev,
.product-group-button .button-tooltip,
.ts-pagination ul li a.prev,
.ts-pagination ul li a.next {
    background-color: rgba(0, 180, 18, 1) !important;
    border-color: rgba(0, 180, 18, 1) !important;
    color: rgba(255, 255, 255, 1) !important
}

.product-group-button .button-tooltip:after {
    border-left-color: rgba(0, 180, 18, 1)
}

.rtl .product-group-button .button-tooltip:after {
    border-right-color: rgba(0, 180, 18, 1)
}

.ts-pagination ul li a:focus,
.ts-pagination ul li a:hover,
.ts-pagination ul li span.current,
.pagination-wrap ul.pagination>li>a:hover,
.pagination-wrap ul.pagination>li>span.current,
.dokan-pagination-container .dokan-pagination li:hover a,
.dokan-pagination-container .dokan-pagination li.active a,
.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span.current,
.pagination-wrap ul.pagination>li>a.prev:hover,
.pagination-wrap ul.pagination>li>a.next:hover,
.dokan-pagination-container .dokan-pagination li:first-child a:hover,
.dokan-pagination-container .dokan-pagination li:last-child a:hover,
.woocommerce nav.woocommerce-pagination ul li a.next:hover,
.woocommerce nav.woocommerce-pagination ul li a.prev:hover,
.ts-pagination ul li a.prev:hover,
.ts-pagination ul li a.next:hover {
    color: rgba(0, 180, 18, 1) !important;
    border-color: rgba(0, 180, 18, 1) !important
}

.woocommerce a.remove,
.ts-floating-sidebar .close,
.cart_list li a.remove,
ul.products-list a.remove,
table.shop_table .product-remove a,
table.compare-list tr.remove td>a .remove,
.woocommerce .woocommerce-widget-layered-nav-dropdown .woocommerce-widget-layered-nav-dropdown__submit {
    color: rgba(0, 0, 0, 1) !important
}

.entry-meta-middle,
.entry-author .role,
.widget-container .count,
.ts-testimonial-wrapper blockquote .author-role,
.woocommerce-review__published-date,
.commentlist li .date-time,
.ts-search-result-container .description,
.product-style-3 .brand-info-wrapper .description,
.woocommerce-privacy-policy-text,
div.product .summary .meta-content,
.tagcloud .tag-link-count,
.product-brands,
.product-sku,
.product-categories,
.woocommerce div.product .woocommerce-tabs ul.tabs li a,
.woocommerce-MyAccount-content>form .form-row>span>em,
.yith-wcwl-share .yith-wcwl-after-share-section,
.widget_archive li,
.wp-block-archives-list li,
.widget_categories li,
.elementor-widget-wp-widget-categories li,
.product-filter-by-color ul li .count,
ul.product_list_widget li .reviewer,
.elementor-widget-wp-widget-recent-posts .post-date,
#cancel-comment-reply-link,
.meta-wrapper .short-description,
.ts-product-brand-wrapper .item .count,
.woocommerce .widget_rating_filter ul li a,
.product-filter-by-brand li label .count,
.my-account-wrapper .form-content>form>a.register,
.comments-area .navigation .nav-previous+.nav-next:before,
.commentlist li.comment .comment-actions a {
    color: rgba(128, 128, 128, 1)
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
dt,
label,
p>label,
fieldset div>label,
blockquote,
blockquote .author,
table thead th,
.wpcf7 p,
.woocommerce>form>fieldset legend,
.woocommerce table.shop_table th,
html input:focus:invalid:focus,
html select:focus:invalid:focus,
#yith-wcwl-popup-message,
table#wp-calendar thead th,
html body>h1,
.woocommerce table.shop_attributes th,
.column-tabs ul.tabs li,
.ts-banner.text-under-image .box-content .description,
.ts-banner.text-under-image .box-content h2,
.ts-banner.text-under-image .box-content h6,
.ts-banner.text-under-image.style-arrow .ts-banner-button a,
body table.compare-list th,
body table.compare-list tr.title th,
body table.compare-list tr.image th,
body table.compare-list tr.price th {
    color: rgba(0, 0, 0, 1)
}

.column-tabs ul.tabs li:after {
    border-color: rgba(0, 0, 0, 1)
}

.primary-color,
.hightlight,
.ul-style.primary-color li:before,
.woocommerce-tabs .ul-style li:before,
.short-description .ul-style li:before,
.woocommerce-product-details__short-description .ul-style li:before,
blockquote:before,
.out-of-stock .availability-text,
.woocommerce div.product form.cart .woocommerce-variation-availability p.stock.out-of-stock,
.elementor-lightbox .dialog-lightbox-close-button:hover,
.ts-store-notice .close:hover,
body #cboxClose:hover:after,
html body>h1 a.close:hover,
body table.compare-list tr.remove td>a:hover,
.elementor-lightbox .elementor-swiper-button:hover,
.woocommerce-account .addresses .title .edit:hover:before,
body .hidden-title-form a:hover,
.ts-header a:hover,
.product .meta-wrapper a:hover,
.meta-bottom-2 a:hover,
.filter-widget-area-button a:hover,
.filter-widget-area-button a.active,
.my-account-wrapper .dropdown-container a:hover,
body .wpml-ls-legacy-dropdown a:hover,
body .wpml-ls-legacy-dropdown a:focus,
body .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a,
body .wpml-ls-legacy-dropdown-click a:hover,
body .wpml-ls-legacy-dropdown-click a:focus,
body .wpml-ls-legacy-dropdown-click .wpml-ls-current-language:hover>a,
.ts-search-result-container .view-all-wrapper a:hover,
.widget_text a[href^="mailto"]:hover,
.widget_text a[href^="tel"]:hover,
.woocommerce .ts-search-result-container ul.product_list_widget ul.ul-style li:before,
.woocommerce.ts-search-result-container ul.product_list_widget ul.ul-style li:before,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a:hover {
    color: rgba(0, 180, 18, 1)
}

.ts-language-switcher a:hover,
.ts-currency-switcher a:hover,
#ts-product-video-modal .close:hover,
ul.products-list a.remove:hover,
table.shop_table .product-remove a:hover,
table.compare-list tr.remove td>a .remove:hover,
.cart_list li a.remove:hover {
    color: rgba(0, 180, 18, 1) !important
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range:before {
    background: rgba(0, 180, 18, 1)
}

.ts-header .ts-menu ul li:hover:before,
.ts-header .ts-menu ul li:hover>a,
.ts-header .ts-menu ul li.current-menu-item:before,
.ts-header .ts-menu ul li.current-menu-item>a,
.ts-header .ts-menu ul li.current-menu-parent>a,
.ts-header .ts-menu ul li.current-menu-ancestor>a,
.ts-header .ts-menu ul li.current-product_cat-ancestor>a,
.ts-header .ts-menu ul li.current-menu-item .ts-menu-drop-icon,
.ts-header .ts-menu ul li.current-menu-parent .ts-menu-drop-icon,
.ts-header .ts-menu ul li.current-menu-ancestor .ts-menu-drop-icon,
.ts-header .ts-menu ul .sub-menu li.current-menu-item>a,
.ts-header .ts-menu ul .sub-menu li.current-menu-parent>a,
.ts-header .ts-menu ul .sub-menu li.current-menu-ancestor>a,
.ts-header .ts-menu ul .sub-menu li.current-product_cat-ancestor>a,
.ts-header .ts-menu ul .sub-menu li.current-menu-item .ts-menu-drop-icon,
.ts-header .ts-menu ul .sub-menu li.current-menu-parent .ts-menu-drop-icon,
.ts-header .ts-menu ul .sub-menu li.current-menu-ancestor .ts-menu-drop-icon,
#group-icon-header .social-icons ul li a:hover,
.ts-header .social-icons ul li a:hover,
.ts-list-of-product-categories-wrapper .list-categories ul li a:hover,
.footer-container .elementor-widget-wp-widget-nav_menu ul.menu li a:hover,
.footer-container ul.nostyle li a:hover,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li>a .menu-label:before,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-menu-item>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current_page_parent>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-menu-parent>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current_page_item>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-menu-ancestor>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-page-ancestor>a,
.header-transparent.header-text-light .header-template>div:not(.is-sticky) .header-middle .menu-wrapper nav>ul.menu>li.current-product_cat-ancestor>a {
    color: rgba(0, 180, 18, 1) !important
}

.ts-banner .box-content .sale-label,
.add-to-cart-popup-content .heading .theme-title,
.ts-portfolio-wrapper .item-wrapper a.like,
.portfolio-info .portfolio-like {
    background: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

#to-top a,
.my-account-wrapper .ts-style-text .account-control>a:hover,
.header-text-light .my-account-wrapper .ts-style-text .account-control>a:hover,
.woocommerce-account .woocommerce-MyAccount-navigation li a:hover,
.woocommerce-account .woocommerce-MyAccount-navigation li.is-active a {
    background-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

.product-on-sale-form:hover label:before,
.product-on-sale-form.checked label:before,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li span.checkboxbutton.checked,
.widget-container.product-filter-by-brand ul>li label:hover:before,
.widget-container.product-filter-by-brand ul>li.selected label:before,
.product-filter-by-availability ul li label:hover:before,
.product-filter-by-availability ul li input[checked="checked"]+label:before,
.product-filter-by-price ul li label:hover:before,
.product-filter-by-price ul li.chosen label:before,
.woocommerce .widget_rating_filter ul li a:hover::before,
.woocommerce .widget_rating_filter ul li.chosen a::before,
.product-filter-by-color ul li a:hover:before,
.product-filter-by-color ul li.chosen a:before {
    background: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1) !important
}

.product-on-sale-form:hover label:after,
.product-on-sale-form.checked label:after,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items li span.checkboxbutton.checked:after,
.widget-container.product-filter-by-brand ul>li label:hover:after,
.widget-container.product-filter-by-brand ul>li.selected label:after,
.product-filter-by-availability ul li label:hover:after,
.product-filter-by-availability ul li input[checked="checked"]+label:after,
.product-filter-by-price ul li label:hover:after,
.product-filter-by-price ul li.chosen label:after,
.woocommerce .widget_rating_filter ul li a:hover::after,
.woocommerce .widget_rating_filter ul li.chosen a::after,
.product-filter-by-color ul li a:hover:after,
.product-filter-by-color ul li.chosen a:after {
    color: rgba(255, 255, 255, 1)
}

.woocommerce div.product div.images .flex-control-thumbs li img.flex-active,
.woocommerce div.product div.images .flex-control-thumbs li img:hover {
    border-color: rgba(0, 180, 18, 1)
}

#review_form .cr-upload-images-preview .cr-upload-images-pbar .cr-upload-images-pbarin,
.cr-upload-images-preview .cr-upload-images-pbarin {
    background-color: rgba(0, 180, 18, 1)
}

#review_form .cr-upload-images-preview .cr-upload-images-containers .cr-upload-images-delete,
.cr-upload-images-preview .cr-upload-images-containers .cr-upload-images-delete {
    background-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

.list-posts article.sticky {
    border-top-color: rgba(0, 180, 18, 1)
}

.summary-column-2 .ts-product-attribute>div.option:not(.color).selected>a,
.summary-column-2 .ts-product-attribute>div.option:not(.color):hover>a,
.ts-product-attribute>div.option:not(.color).selected>a,
.ts-product-attribute>div.option:not(.color):hover>a {
    background: rgba(236, 253, 238, 1);
    border-color: rgba(0, 180, 18, 1)
}

.yith-wfbt-section .total_price,
.availability .availability-text,
.header-contact-info:before {
    color: rgba(255, 109, 34, 1)
}

.woocommerce.yith-wfbt-section:before {
    background-color: rgba(255, 246, 236, 1)
}

#group-icon-header .tab-mobile-menu li.active,
.woocommerce.yith-wfbt-section .yith-wfbt-images td:not(:last-child)>a:after {
    background: rgba(255, 109, 34, 1);
    color: rgba(255, 255, 255, 1)
}

.ts-delivery-note>span,
.woocommerce div.product div.summary .ts-delivery-note>span {
    border-color: rgba(255, 109, 34, 1)
}

.ts-delivery-note svg path {
    stroke: rgba(255, 109, 34, 1)
}

a,
.elementor-widget-text-editor table a {
    color: rgba(0, 180, 18, 1)
}

a:hover,
.elementor-widget-text-editor table a:hover,
.product-brands a:hover,
.product-categories a:hover,
.portfolio-info .cat-links a:hover,
.widget_categories>ul li>a:hover,
.widget_archive li>a:hover,
.widget_nav_menu li>a:hover,
.widget_pages li>a:hover,
.widget_meta li>a:hover,
.widget_recent_comments li>a:hover,
.widget_recent_entries li>a:hover,
.widget_rss li>a:hover,
ul.product_list_widget li a:hover,
.woocommerce ul.cart_list li a:hover,
.woocommerce ul.product_list_widget li a:hover,
.ts-product-brand-info .social-profile li a:hover,
.ts-header .menu-wrapper .vertical-menu-wrapper ul.sub-menu a:hover,
.ts-header .menu-wrapper .ts-menu ul.sub-menu a:hover,
.vertical-categories-wrapper .products .product-category .heading-title a:hover,
.woocommerce-product-rating .woocommerce-review-link:hover,
.woocommerce div.product .summary .woocommerce-product-rating .woocommerce-review-link:hover,
.widget_categories>ul li.current-cat>a,
.woocommerce div.product .summary .product-brands a:hover,
.woocommerce div.product .summary .cat-links a:hover,
.woocommerce div.product .summary .tag-links a:hover,
.ts-product-category-wrapper .product:not(.product-category) .category-name a:hover,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items a:hover,
.elementor-widget[data-widget_type*="wp-widget-"] ul li a:hover,
.comments-area .add-comment>a:hover,
.commentlist li.comment .comment-actions a:hover,
.woocommerce .woocommerce-ordering ul li a:hover,
.product-per-page-form ul.perpage ul li a:hover {
    color: rgba(0, 180, 18, 1)
}

.item-wrapper .portfolio-meta a:hover {
    color: rgba(0, 180, 18, 1) !important
}

.button,
a.button,
button,
input[type^="submit"],
.shopping-cart p.buttons a,
a.wp-block-button__link,
.is-style-outline>.wp-block-button__link:not(.has-text-color):hover,
.wp-block-button__link.is-style-outline:not(.has-text-color):hover,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page input.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
#content button.button,
.wp-block-search .wp-block-search__button,
.woocommerce #respond input#submit,
div.button a,
input[type="submit"].dokan-btn,
a.dokan-btn,
.dokan-btn,
.dokan-btn.dokan-store-list-filter-button,
.dokan-btn.dokan-store-list-filter-button:hover,
.elementor-button-wrapper .elementor-button,
.wishlist_table .product-add-to-cart a,
body .woocommerce table.compare-list .add-to-cart td a,
.yith-woocompare-widget a.clear-all,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all,
.woocommerce-cart table.cart td.actions>.button:hover,
div.wpcf7 input[type^="submit"],
.woocommerce a.button.disabled,
.woocommerce a.button.disabled:hover,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled],
.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
    background: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

div.wpcf7 input[type^="submit"]:hover,
.button:hover,
a.button:hover,
button:hover,
input[type^="submit"]:hover,
.shopping-cart p.buttons a:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce-page a.button:hover,
.woocommerce-page button.button:hover,
.woocommerce-page input.button:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce-page a.button.alt:hover,
.woocommerce-page button.button.alt:hover,
.woocommerce-page input.button.alt:hover,
#content button.button:hover,
.wp-block-search .wp-block-search__button:hover,
.woocommerce #respond input#submit:hover,
div.button a:hover,
input[type="submit"].dokan-btn:hover,
a.dokan-btn:hover,
.dokan-btn:hover,
.wishlist_table .product-add-to-cart a:hover,
a.wp-block-button__link:hover,
.is-style-outline>.wp-block-button__link:not(.has-text-color),
.wp-block-button__link.is-style-outline:not(.has-text-color),
.elementor-button-wrapper .elementor-button:hover,
body .woocommerce table.compare-list .add-to-cart td a:hover,
.woocommerce-cart table.cart td.actions>.button,
.yith-woocompare-widget a.clear-all:hover,
.elementor-widget-wp-widget-yith-woocompare-widget a.clear-all:hover {
    color: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1)
}

.button-primary,
.button.button-primary,
#content button.button.button-primary,
.shop-more a.button,
.woocommerce-page button.button.button-primary,
.yith-wfbt-form .yith-wfbt-submit-block .button,
form.track_order input[type^="submit"],
form.track_order button[type^="submit"],
.woocommerce-MyAccount-content form button[type^="submit"],
.woocommerce form.woocommerce-ResetPassword.lost_reset_password button[type^="submit"],
.load-more-wrapper .button,
.ts-shop-load-more .button,
.woocommerce .ts-shop-load-more .button,
.woocommerce-cart .return-to-shop a.button,
.widget_shopping_cart .buttons a.checkout:hover,
.woocommerce #customer_login form.login .button,
.woocommerce #customer_login form.register .button,
.yith-woocompare-widget a.compare,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare,
.elementor-widget-wp-widget-woocommerce_widget_cart .buttons a.checkout,
#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
.dropdown-footer>a.button.checkout-button:hover {
    background: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

.button-primary:hover,
.button.button-primary:hover,
.shop-more a.button:hover,
#content button.button.button-primary:hover,
.woocommerce-page button.button.button-primary:hover,
.woocommerce div.product form.cart .button:hover,
.yith-wfbt-form .yith-wfbt-submit-block .button:hover,
form.track_order input[type^="submit"]:hover,
form.track_order button[type^="submit"]:hover,
.woocommerce-MyAccount-content form button[type^="submit"]:hover,
.woocommerce form.woocommerce-ResetPassword.lost_reset_password button[type^="submit"]:hover,
.load-more-wrapper .button:hover,
.ts-shop-load-more .button:hover,
.woocommerce .ts-shop-load-more .button:hover,
.woocommerce-cart .return-to-shop a.button:hover,
.widget_shopping_cart .buttons a.checkout,
.woocommerce #customer_login form.login .button:hover,
.woocommerce #customer_login form.register .button:hover,
.yith-woocompare-widget a.compare:hover,
.elementor-widget-wp-widget-yith-woocompare-widget a.compare:hover,
.elementor-widget-wp-widget-woocommerce_widget_cart .buttons a.checkout:hover,
#add_payment_method .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button:hover,
.dropdown-footer>a.button.checkout-button {
    color: rgba(0, 180, 18, 1)
}

.woocommerce div.product form.cart .button.loading:after,
.search-table .search-field.loading~.search-button:before,
.wishlist_table .product-add-to-cart a.add_to_cart.loading:after,
body .woocommerce table.compare-list .add-to-cart td a.loading:after,
.product-group-button-meta>div a.loading:after,
.woocommerce .product-group-button-meta>div a.button.loading:after,
rs-layer .products .product div.loop-add-to-cart .button.loading:after {
    background: rgba(0, 180, 18, 1) !important
}

.ts-portfolio-wrapper .item-wrapper a.like.loading:before,
.portfolio-like .ic-like.loading:before,
.portfolio-thumbnail>figure~a.like.loading:hover:before,
.woocommerce div.product form.cart .button.loading:before,
.search-table .search-field.loading~.search-button:after,
#ts-search-sidebar .search-table .search-field.loading~.search-button:after,
.wishlist_table .product-add-to-cart a.add_to_cart.loading:before,
body .woocommerce table.compare-list .add-to-cart td a.loading:before,
.product-group-button-meta>div a.loading:before,
.woocommerce .product-group-button-meta>div a.button.loading:before,
rs-layer .products .product div.loop-add-to-cart .button.loading:before {
    border-color: rgba(255, 255, 255, 0.5);
    border-top-color: rgba(255, 255, 255, 1)
}

.woocommerce div.product form.cart .button.loading:hover:after,
.wishlist_table .product-add-to-cart a.add_to_cart.loading:hover:after,
body .woocommerce table.compare-list .add-to-cart td a.loading:hover:after,
.product-group-button-meta>div a.loading:hover:after,
.woocommerce .product-group-button-meta>div a.button.loading:hover:after,
rs-layer .products .product div.loop-add-to-cart .button.loading:hover:after {
    background: rgba(255, 255, 255, 1) !important
}

.woocommerce div.product form.cart .button.loading:hover:before,
.search-table .search-field.loading~.search-button:hover:after,
#ts-search-sidebar .search-table .search-field.loading~.search-button:hover:after,
.wishlist_table .product-add-to-cart a.add_to_cart.loading:hover:before,
body .woocommerce table.compare-list .add-to-cart td a.loading:hover:before,
.product-group-button-meta>div a.loading:hover:before,
.woocommerce .product-group-button-meta>div a.button.loading:hover:before,
rs-layer .products .product div.loop-add-to-cart .button.loading:hover:before {
    border-color: rgba(0, 180, 18, 0.5);
    border-top-color: rgba(0, 180, 18, 1)
}

.load-more-wrapper .button.loading,
.ts-shop-load-more .button.loading,
.woocommerce .ts-shop-load-more .button.loading {
    border-top-color: rgba(0, 180, 18, 1)
}

select,
textarea,
html input[type="search"],
html input[type="text"],
html input[type="email"],
html input[type="password"],
html input[type="date"],
html input[type="number"],
html input[type="tel"],
.woocommerce .quantity input.qty,
.quantity input.qty,
body .wishlist-title a.show-title-form,
body .select2-container--default .select2-search--dropdown .select2-search__field,
body .select2-container--default .select2-selection--single,
.woocommerce-checkout .select2-dropdown,
body .select2-container--default .select2-selection--single,
body .select2-container--default .select2-search--dropdown .select2-search__field,
.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select,
body .select2-container--default .select2-selection--multiple,
body .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: rgba(0, 0, 0, 1);
    background-color: rgba(242, 242, 242, 1)
}

.vertical-categories-wrapper .products .product-category .product-wrapper:hover,
.ts-active-filters .widget_layered_nav_filters ul li a,
#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box,
.elementor-widget-wp-widget-ts_products.nav-top .ts-slider .owl-nav>div,
.elementor-widget-wp-widget-ts_blogs.nav-top .ts-slider .owl-nav>div,
.elementor-widget-wp-widget-ts_recent_comments.nav-top .ts-slider .owl-nav>div,
.widget-container .ts-slider .owl-nav>div,
#review_form .cr-upload-local-images #cr_review_image,
.cr-upload-local-images #cr_review_image {
    background-color: rgba(242, 242, 242, 1)
}

.ts-header nav.vertical-menu>ul.menu>li:hover,
.ts-header nav.vertical-menu>ul.menu>li.ts-active,
.ts-header .normal-menu nav.vertical-menu ul.sub-menu>li:hover,
.ts-header nav.vertical-menu ul li.current-menu-item {
    color: rgba(0, 0, 0, 1);
    background-color: rgba(240, 240, 240, 1)
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px rgba(242, 242, 242, 1) inset !important
}

body .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: rgba(0, 0, 0, 1)
}

body .wishlist-title a.show-title-form:hover {
    background-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
    border-bottom-color: rgba(242, 242, 242, 1)
}

*,
*:before,
*:after,
img,
.entry-meta-bottom,
.commentlist li.comment,
.woocommerce #reviews #comments ol.commentlist li,
.ts-tiny-cart-wrapper .total,
.widget_shopping_cart .total,
.elementor-widget-wp-widget-woocommerce_widget_cart .total,
.twitter-wrapper .avatar-name img,
body.single-post article .entry-format.no-featured-image,
.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th,
.woocommerce div.product form.cart table.group_table td,
.woocommerce form.checkout_coupon,
.woocommerce .checkout-login-coupon-wrapper form.login,
.ts-product-brand-wrapper .item img,
body #yith-woocompare table.compare-list tbody th,
body #yith-woocompare table.compare-list tbody td,
.woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item td.product-subtotal,
.ts-header .dropdown-container *,
.color-swatch>div img,
.ts-product-attribute a img,
.ts-header nav>ul.menu ul.sub-menu:before,
.woocommerce div.product.show-tabs-content-default .woocommerce-tabs #reviews nav.woocommerce-pagination,
#main-content.ts-col-24 .woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-images,
#main-content.ts-col-24 .woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-images:after,
#main-content.ts-col-24 .woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items:after {
    border-color: rgba(229, 229, 229, 1)
}

.images.loading:after,
.ts-product .content-wrapper.loading:after,
.ts-logo-slider-wrapper.loading .content-wrapper:after,
.related-posts.loading .content-wrapper:after,
.search-table .search-button:after,
.woocommerce .product figure.loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.blogs article a.gallery.loading:after,
.ts-blogs-wrapper.loading .content-wrapper:after,
.ts-testimonial-wrapper .items.loading:after,
.ts-twitter-slider .items.loading:after,
article .thumbnail.loading:after,
.ts-portfolio-wrapper.loading:after,
.thumbnails.loading:after,
.ts-product-category-wrapper .content-wrapper.loading:after,
.thumbnails-container.loading:after,
.column-products.loading:after,
.ts-team-members .loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.ts-tiny-cart-wrapper li div.blockUI.blockOverlay:after,
.widget_shopping_cart li div.blockUI.blockOverlay:after,
.elementor-widget-wp-widget-woocommerce_widget_cart div.blockUI.blockOverlay:after,
.dropdown-container ul.cart_list li.loading:after,
.woocommerce a.button.loading:after,
.woocommerce button.button.loading:after,
.woocommerce input.button.loading:after,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a.loading:after,
div.blockUI.blockOverlay:after,
.woocommerce div.blockUI.blockOverlay:after,
.archive.ajax-pagination .woocommerce>.products:before,
div.wpcf7 .ajax-loader:after {
    border-color: rgba(229, 229, 229, 1)
}

.images.loading:after,
.search-table .search-button:after,
.ts-product .content-wrapper.loading:after,
.ts-logo-slider-wrapper.loading .content-wrapper:after,
.related-posts.loading .content-wrapper:after,
.woocommerce .product figure.loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.blogs article a.gallery.loading:after,
.ts-blogs-wrapper.loading .content-wrapper:after,
.ts-testimonial-wrapper .items.loading:after,
.ts-twitter-slider .items.loading:after,
article .thumbnail.loading:after,
.ts-portfolio-wrapper.loading:after,
.thumbnails.loading:after,
.ts-product-category-wrapper .content-wrapper.loading:after,
.thumbnails-container.loading:after,
.column-products.loading:after,
.ts-team-members .loading:after,
.ts-products-widget-wrapper.loading:after,
.ts-blogs-widget-wrapper.loading:after,
.ts-recent-comments-widget-wrapper.loading:after,
.ts-tiny-cart-wrapper li div.blockUI.blockOverlay:after,
.widget_shopping_cart li div.blockUI.blockOverlay:after,
.elementor-widget-wp-widget-woocommerce_widget_cart div.blockUI.blockOverlay:after,
.dropdown-container ul.cart_list li.loading:after,
.woocommerce a.button.loading:after,
.woocommerce button.button.loading:after,
.woocommerce input.button.loading:after,
.woocommerce div.product .single-product-buttons-sharing .single-product-buttons a.loading:after,
div.blockUI.blockOverlay:after,
.woocommerce div.blockUI.blockOverlay:after,
.archive.ajax-pagination .woocommerce>.products:before,
div.wpcf7 .ajax-loader:after {
    border-top-color: rgba(0, 0, 0, 1)
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a:after,
.woocommerce div.product.color-variation-thumbnail .ts-product-attribute div.option.color a:hover img,
.woocommerce div.product.color-variation-thumbnail .ts-product-attribute div.option.color.selected a img {
    border-color: rgba(0, 0, 0, 1)
}

.woocommerce div.product.color-variation-thumbnail .ts-product-attribute div.option.color a:after {
    background: rgba(0, 0, 0, 1)
}

.ts-store-notice {
    background-color: rgba(255, 109, 34, 1);
    border-color: rgba(255, 109, 34, 1);
    color: rgba(255, 255, 255, 1)
}

.header-top,
.header-middle,
.header-bottom {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(229, 229, 229, 1);
    color: rgba(0, 0, 0, 1)
}

.header-contact-info {
    color: rgba(128, 128, 128, 1)
}

.header-middle .header-right>*:before {
    border-color: rgba(229, 229, 229, 1)
}

.ts-header .search-content input[type="text"] {
    color: rgba(0, 0, 0, 1);
    background-color: rgba(240, 240, 240, 1)
}

.ts-header .search-table .loading~.search-button:after,
.ts-header .search-table .loading~.search-button:hover:after {
    border-color: rgba(0, 0, 0, 0.5) !important;
    border-top-color: rgba(0, 0, 0, 1) !important
}

.ts-megamenu .category-style-vertical .products .product-category .product-wrapper {
    background-color: rgba(255, 255, 255, 1)
}

.ts-megamenu .category-style-vertical .products .product-category.active .product-wrapper,
.ts-megamenu .category-style-vertical .products .product-category .product-wrapper:hover {
    background-color: rgba(240, 240, 240, 1)
}

.ts-header .social-icons ul li:hover i,
.ts-megamenu .category-style-vertical .products .product-category .product-wrapper:hover a {
    color: rgba(0, 180, 18, 1)
}

.ts-header .search-content ::-webkit-input-placeholder {
    color: rgba(77, 77, 77, 1)
}

.ts-header .search-content :-moz-placeholder {
    color: rgba(77, 77, 77, 1)
}

.ts-header .search-content ::-moz-placeholder {
    color: rgba(77, 77, 77, 1)
}

.ts-header .search-content :-ms-input-placeholder {
    color: rgba(77, 77, 77, 1)
}

.ts-header nav>ul.menu li ul.sub-menu:before,
.ts-header nav>ul.menu li ul.sub-menu:after,
.ts-header .vertical-menu-wrapper:not(.normal-menu) nav.vertical-menu:before,
.ts-header .vertical-menu>ul,
.ts-header .vertical-menu>ul>li,
.ts-header .vertical-menu li:not(.ts-megamenu)>ul.sub-menu>li {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(229, 229, 229, 1)
}

.ts-header nav>ul.menu>li.has-line,
.ts-header nav>ul>li.has-line,
.ts-header *,
.ts-header *:before,
.ts-header *:after,
ul.sub-menu .tab-vertical-menu .products .product-category,
ul.sub-menu .category-style-vertical .products .product-category {
    border-color: rgba(229, 229, 229, 1)
}

.ts-header .menu-wrapper .vertical-menu,
.ts-header nav>ul.menu li ul.sub-menu,
ul.sub-menu .elementor-element .list-categories ul li a,
ul.sub-menu .elementor-element .heading-title,
.header-contact-info strong {
    color: rgba(0, 0, 0, 1)
}

.ts-header .social-icons ul li i {
    color: rgba(0, 0, 0, 1)
}

.icon-menu-sticky-header .icon:before,
.search-button.search-icon .icon:before,
.my-wishlist-wrapper .tini-wishlist:before,
.shopping-cart-wrapper .cart-control .ic-cart:before,
.shopping-cart-wrapper .cart-control .cart-total,
.ts-tiny-account-wrapper .account-control>a:before,
.header-transparent.header-text-light .is-sticky .header-middle .icon-menu-sticky-header .icon:before,
.header-transparent.header-text-light .is-sticky .header-middle .search-button.search-icon .icon:before,
.header-transparent.header-text-light .is-sticky .header-middle .my-wishlist-wrapper .tini-wishlist:before,
.header-transparent.header-text-light .is-sticky .header-middle .shopping-cart-wrapper .cart-control .ic-cart:before,
.header-transparent.header-text-light .is-sticky .header-middle .ts-tiny-account-wrapper .account-control>a:before {
    color: rgba(0, 0, 0, 1)
}

.icon-menu-sticky-header:hover .icon:before,
.search-button.search-icon:hover .icon:before,
.my-wishlist-wrapper:hover .tini-wishlist:before,
.shopping-cart-wrapper:hover .cart-control .ic-cart:before,
.shopping-cart-wrapper:hover .cart-control .cart-total,
.ts-tiny-account-wrapper:hover .account-control>a:before,
.header-transparent.header-text-light .icon-menu-sticky-header:hover .icon:before,
.header-transparent.header-text-light .search-button.search-icon:hover .icon:before,
.header-transparent.header-text-light .my-wishlist-wrapper:hover .tini-wishlist:before,
.header-transparent.header-text-light .shopping-cart-wrapper:hover .cart-control .ic-cart:before,
.header-transparent.header-text-light .shopping-cart-wrapper:hover .cart-control .cart-total,
.header-transparent.header-text-light .ts-tiny-account-wrapper:hover .account-control>a:before {
    color: rgba(0, 180, 18, 1)
}

.my-wishlist-wrapper .tini-wishlist .count-number,
.shopping-cart-wrapper .cart-control .cart-number,
.dropdown-container .theme-title span {
    background: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

.header-v4 .header-middle .header-left>*,
.header-v4 .header-middle .header-right>*,
.header-v4 .header-middle .header-left .ts-search-by-category,
.header-v4.header-transparent .is-sticky .header-middle .header-left .ts-search-by-category,
.header-v4.header-transparent .is-sticky .header-middle .header-left>*,
.header-v4.header-transparent .is-sticky .header-middle .header-right>* {
    background-color: rgba(240, 240, 240, 1)
}

.header-v4.header-transparent .header-middle .header-left .ts-search-by-category,
.header-v4.header-transparent .header-middle .header-left>*,
.header-v4.header-transparent .header-middle .header-right>* {
    background-color: rgba(255, 255, 255, 1)
}

.breadcrumb-title-wrapper {
    background-color: rgba(255, 255, 255, 1)
}

.breadcrumb-title-wrapper .breadcrumbs,
.breadcrumb-title-wrapper .page-title {
    color: rgba(0, 0, 0, 1)
}

.breadcrumb-title-wrapper .breadcrumbs a,
.breadcrumb-title-wrapper .brn_arrow:before,
.breadcrumb-title-wrapper .breadcrumbs-container>span:not(.current):before,
.breadcrumb-title-wrapper.breadcrumb-v1 .description {
    color: rgba(128, 128, 128, 1)
}

footer#colophon {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(0, 0, 0, 1)
}

footer#colophon,
.footer-container * {
    border-color: rgba(230, 230, 230, 1)
}

.footer-container .elementor-widget-divider {
    --divider-color: rgba(230, 230, 230, 1)
}

.column-tabs .list-categories,
.woocommerce .products .product:not(.product-category) .product-wrapper,
.product-hover-vertical-style-2 .products .product:not(.product-category) .product-wrapper:hover .product-group-button-meta {
    background-color: rgba(255, 255, 255, 1)
}

.product-name,
h3.product-name,
.product-name h3,
.product_list_widget .title,
ul.product_list_widget li a,
.woocommerce ul.cart_list li a,
.woocommerce ul.product_list_widget li a,
.price,
.product-price,
.woocommerce div.product p.price,
.woocommerce div.product span.price,
ul.product_list_widget .product-categories,
.price del,
td[data-title="Price"] del,
table.group_table del,
.product-price del,
body .wishlist_table.mobile table.item-details-table td del {
    color: rgba(0, 0, 0, 1)
}

.price ins,
td[data-title="Price"] ins,
table.group_table ins,
body .wishlist_table.mobile table.item-details-table td ins,
.product-price ins {
    color: rgba(255, 109, 34, 1)
}

.star-rating::before,
.woocommerce .star-rating::before,
.woocommerce p.stars a,
.woocommerce p.stars a:hover~a,
.woocommerce p.stars.selected a.active~a,
.ts-testimonial-wrapper .rating:before,
blockquote .rating:before {
    color: rgba(204, 204, 204, 1) !important
}

.star-rating span,
.woocommerce .star-rating span,
.product_list_widget .star-rating span,
.woocommerce p.stars:hover a,
.woocommerce p.stars.selected a,
.woocommerce .star-rating span:before,
.ts-testimonial-wrapper .rating span:before,
blockquote .rating span:before {
    color: rgba(255, 109, 34, 1) !important
}

.ts-product-deals-wrapper .content-wrapper,
.woocommerce.main-products>.products>.list-categories {
    background-color: rgba(255, 246, 236, 1);
    border-color: rgba(255, 109, 34, 1)
}

.meta-wrapper .counter-wrapper .number,
.counter-wrapper>div .number>span {
    background-color: rgba(255, 109, 34, 1);
    color: rgba(255, 255, 255, 1)
}

.counter-wrapper .separate {
    color: rgba(255, 109, 34, 1)
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td:not(.actions),
.woocommerce table.shop_table.shop_table_responsive.cart tr.cart_item,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce form.checkout_coupon,
.woocommerce .checkout-login-coupon-wrapper form.login,
.woocommerce>form.checkout #customer_details,
.woocommerce>form.checkout #order_review_heading,
.woocommerce>form.checkout #order_review,
.woocommerce-cart article .woocommerce .cart-collaterals,
.woocommerce-checkout #order_review,
.woocommerce #customer_login,
.woocommerce form.track_order,
body .wishlist_table.mobile li,
.woocommerce.ts-search-result-container ul.product_list_widget li,
.ts-floating-sidebar .woocommerce ul.product_list_widget li,
#ts-search-sidebar .search-table .search-field input[type="text"],
.woocommerce-account .woocommerce-MyAccount-content,
.woocommerce-account .woocommerce-MyAccount-navigation li:not(:hover):not(.is-active) a,
.woocommerce form.woocommerce-ResetPassword.lost_reset_password,
.woocommerce div.product.product-style-3 .product-images-summary,
.woocommerce div.product.product-style-3 .ts-product-brand-info,
.woocommerce div.product.product-style-4 .product-images-summary,
.summary-column-2 .ts-product-attribute>div.option:not(.color)>a,
.woocommerce .summary-column-2 .quantity input.qty,
.summary-column-2 .quantity input.qty,
.ts-product-brand-info .brand-info-wrapper,
.ts-product-brand-info .product_list_widget li,
.ts-product.style-list .shortcode-heading-wrapper {
    background-color: rgba(255, 255, 255, 1)
}

body>#ts-search-result-container,
.woocommerce-page.archive #main,
.woocommerce-cart #main,
.woocommerce-checkout #main,
.woocommerce-account #main,
.woocommerce-wishlist #main,
div.product .summary-column-2,
.woocommerce .cross-sells:before,
.woocommerce .up-sells:before,
.woocommerce .related:before,
.woocommerce div.product>.ts-product-in-category-tab-wrapper:before,
#ts-search-sidebar .ts-sidebar-content,
article.single-post .single-related-wrapper {
    background-color: rgba(247, 247, 247, 1)
}

@media only screen and (max-width: 1279px) {
    #ts-filter-widget-area .ts-sidebar-content {
        background-color: rgba(247, 247, 247, 1)
    }
}

.woocommerce div.product div.summary * {
    border-color: rgba(240, 242, 245, 1)
}

.woocommerce.yith-wfbt-section .yith-wfbt-images td>a,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-submit-block,
.woocommerce.yith-wfbt-section .yith-wfbt-form .yith-wfbt-items:after,
.woocommerce .widget_price_filter .price_slider_amount .price_label>span:first-child:before,
.woocommerce .widget_price_filter .price_slider_amount .price_label>span:last-child,
.woocommerce div.product.product-style-3 .woocommerce-tabs:before,
.woocommerce div.product.product-style-3 .ts-mailchimp-subscription-shortcode:before,
.woocommerce div.product.product-style-3 .ts-instagram-shortcode:before,
.woocommerce div.product.product-style-4 .woocommerce-tabs:before,
.woocommerce div.product.product-style-4 .ts-mailchimp-subscription-shortcode:before,
.woocommerce div.product.product-style-4 .ts-instagram-shortcode:before,
.ts-active-filters .widget_layered_nav_filters ul li a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span,
.widget_product_categories ul.product-categories li>a,
.widget_product_categories ul.product-categories li>span,
.ts-product-categories-widget-wrapper ul.product-categories li>a,
.ts-product-categories-widget-wrapper ul.product-categories li>span:not(.icon-toggle),
.ts-product-categories-widget-wrapper>ul li.cat-parent>span.icon-toggle {
    background: rgba(255, 255, 255, 1) !important
}

.woocommerce-widget-layered-nav ul li:hover>a,
.woocommerce-widget-layered-nav ul li:hover>span,
.ts-active-filters .widget_layered_nav_filters ul li:hover a,
.ts-active-filters .widget_layered_nav_filters ul li.chosen a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item:hover a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item:hover span,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item.chosen a,
.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item.chosen span,
.widget_product_categories ul.product-categories li:hover>a,
.widget_product_categories ul.product-categories li:hover>span,
.widget_product_categories ul.product-categories li.current-cat>a,
.widget_product_categories ul.product-categories li.current-cat>span,
.ts-product-categories-widget-wrapper ul.product-categories li:hover>a,
.ts-product-categories-widget-wrapper ul.product-categories li:hover>span:not(.icon-toggle),
.ts-product-categories-widget-wrapper ul.product-categories li.current>a,
.ts-product-categories-widget-wrapper ul.product-categories li.current>span:not(.icon-toggle),
.ts-product-categories-widget-wrapper>ul li.cat-parent.current>span.icon-toggle {
    background: rgba(236, 253, 238, 1) !important;
    border-color: rgba(0, 180, 18, 1) !important
}

@media only screen and (min-width: 1279px) {

    .show-filter-top .product-filter-by-brand ul li label,
    .show-filter-top .product-filter-by-availability ul li label,
    .show-filter-top .product-filter-by-color ul li a {
        background: rgba(255, 255, 255, 1) !important
    }

    .show-filter-top .product-filter-by-brand ul li.selected label,
    .show-filter-top .product-filter-by-brand ul li:hover label,
    .show-filter-top .product-filter-by-availability ul li:hover label,
    .show-filter-top .product-filter-by-availability ul li.selected label,
    .show-filter-top .product-filter-by-color ul li:hover a,
    .show-filter-top .product-filter-by-color ul li.chosen a {
        background: rgba(236, 253, 238, 1) !important;
        border-color: rgba(0, 180, 18, 1) !important
    }
}

.product_list_widget .product-label .onsale,
.woocommerce .product .product-label .onsale {
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 180, 18, 1)
}

.product_list_widget .product-label .new,
.woocommerce .product .product-label .new {
    color: rgba(255, 255, 255, 1);
    background: rgba(254, 121, 52, 1)
}

.product_list_widget .product-label .featured,
.woocommerce .product .product-label .featured {
    color: rgba(255, 255, 255, 1);
    background: rgba(203, 24, 0, 1)
}

.product_list_widget .product-label .out-of-stock,
.woocommerce .product .product-label .out-of-stock {
    color: rgba(255, 255, 255, 1);
    background: rgba(154, 154, 154, 1)
}

#ts-mobile-button-bottom {
    background: rgba(255, 255, 255, 1);
    border-color: rgba(217, 217, 217, 1)
}

#ts-mobile-button-bottom>div .icon:before,
#ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist:before,
#ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .ic-cart:before,
#ts-mobile-button-bottom .ts-tiny-account-wrapper .account-control>a:before {
    color: rgba(0, 0, 0, 1)
}

#ts-mobile-button-bottom .my-wishlist-wrapper .tini-wishlist .count-number,
#ts-mobile-button-bottom .shopping-cart-wrapper .cart-control .cart-number {
    background: rgba(0, 180, 18, 1);
    border-color: rgba(0, 180, 18, 1);
    color: rgba(255, 255, 255, 1)
}

#group-icon-header .ts-sidebar-content {
    background-color: rgba(255, 255, 255, 1)
}

#group-icon-header .ts-sidebar-content,
.mobile-menu-wrapper,
.mobile-menu-wrapper .mobile-menu {
    color: rgba(0, 0, 0, 1)
}